import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

const middleware = [thunk];
export default function makeStore() {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    // If it's on server side, create a store
    // @ts-ignore
    return createStore(rootReducer, composeWithDevTools(responsiveStoreEnhancer, applyMiddleware(...middleware)));
  }

  // @ts-ignore
  return createStore(rootReducer, composeWithDevTools(responsiveStoreEnhancer, applyMiddleware(...middleware)));
}
