import { useSelector } from 'react-redux';
import { NextRouter, useRouter } from 'next/router';
import { compile } from 'path-to-regexp';
import { cultureToken, cultureTokenMap, cultureTokens, Language, routes, RouteType } from '~/src/utils/routing';
import { LocalizedLinkProps } from 'features/routing/types';
import LocalizedLink from 'features/routing/LocalizedLink';
import { useCodebase } from 'features/hooks/useCodebase';
import freewayRoutesTyped from 'features/routing/routes/freewayRoutesTyped';

export type { LocalizedLinkProps };

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

interface PushOption {
  query?: any;
  hash?: string;
  hrefParams?: any;
  options?: any;
}

type LocalizedRouter = {
  localizedPush: (href: RouteType, options?: PushOption) => Promise<boolean>;
  localizedReplace: (href: RouteType, options?: PushOption) => Promise<boolean>;
  changeLanguage: (language: Language, tokenProp?: string) => void;
  changeCultureToken: (token: string, t?: any, removeRedirectToken?: boolean) => void;
  generateUrl: (href: RouteType, options?: { hrefParams?: any }) => string;
  historyPush: (url: string) => void;
};

export type CombinedRouter = LocalizedRouter & NextRouter;

export const generateUrl = (
  url: RouteType,
  language: Language,
  culture_Token?: keyof typeof cultureTokens,
  params?: any
) => {
  const toPath = compile(url.urls[language], { encode: encodeURIComponent });
  return `${culture_Token ? `/${culture_Token as string}` : ''}${toPath(params)}`;
};

export function getLanguageFromUrl(url: string) {
  if (url.length === 5) return Language[url.split('-')[0]]; // if the parameter is just xx-yy
  return Language[url.slice(1, 6).split('-')[0]] || Language.cs;
}

export const getLanguageFromToken = (cultureToken: string) => Language[cultureToken.split('-')[0]] || Language.cs;

export const getCultureTokenFromUrl = (url: string) => cultureTokens[url.slice(1, 6)] || cultureToken;

export const useLocalizedRouter = () => {
  const router: Partial<CombinedRouter> = useRouter();

  const settings = useSelector((state: any) => state.settings);
  const isFWC = useCodebase('fwc');

  router.historyPush = async (url) => {
    window.history.pushState(undefined, undefined, url);
  };

  router.localizedPush = async (url, { hash, hrefParams = {}, options = {}, query }) => {
    const token = `/${settings.language}-${settings.country}`;
    const toPath = compile(url.urls[settings.language], { encode: encodeURIComponent });

    return router.push(
      {
        pathname: `${token}${toPath(hrefParams)}`,
        hash,
        query,
      },
      undefined,
      options
    );
  };

  router.generateUrl = (url, { hrefParams = {} }) => {
    const token = `/${settings.language}-${settings.country}`;
    const toPath = compile(url.urls[settings.language], { encode: encodeURIComponent });

    return `${token}${toPath(hrefParams)}`;
  };

  router.localizedReplace = async (url, { hash, hrefParams = {}, options = {}, query }) => {
    const token = `/${settings.language}-${settings.country}`;
    const toPath = compile(url.urls[settings.language], { encode: encodeURIComponent });

    return router.replace(
      {
        pathname: `${token}${toPath(hrefParams)}`,
        hash,
        query,
      },
      undefined,
      options
    );
  };

  router.changeLanguage = (language, tokenProp = null) => {
    const token = tokenProp ? `/${tokenProp}` : `/${cultureTokenMap[language]}`;

    // @ts-ignore
    const result = Object.entries({
      ...routes,
      ...(isFWC ? freewayRoutesTyped : {}),
    }).filter(([value, data]) => router.pathname === `${(data as any).template}`);

    if (result && result[0]) {
      if (
        // @ts-ignore
        result[0][1].template === '/blogPage/[articleId]'
      ) {
        router.push(`${token}/blog`);
        return;
      }
      // @ts-ignore
      if (result[0][1].urls[language] === undefined) {
        router.push(`${token}`);
        return;
      }
      // @ts-ignore
      const toPath = compile(result[0][1].urls[language], { encode: encodeURIComponent });

      if (router.pathname === '/blogPage') {
        router.push(`${token}${toPath(router.query)}${window.location.search}`);
      } else {
        router.historyPush(`${token}${toPath(router.query)}${window.location.search}`);
      }
    }
  };

  router.changeCultureToken = (token: string, removeRedirectToken = false, refresh = false) => {
    let currentCultureToken = router.asPath.substr(0, 6);
    let newCultureToken = token;
    if (!settings.country) {
      currentCultureToken = '/';
      newCultureToken += '/';
    }

    const newPath = router.asPath.replace(currentCultureToken, newCultureToken);
    const finalNewPath = removeRedirectToken ? newPath.replace('?redirect=true', '') : newPath;

    return router.push(finalNewPath);
  };

  return router;
};

export default LocalizedLink;
