import { ReactNode } from 'react';
import { Country } from 'features/utils/routing';
import { campiriBaseIcon, cityIcon, placesIcon, searchResultIcon, stationIcon } from './icons';

export const getIconFromLocationSearchType = (type: SearchLocationType): ReactNode => {
  switch (type) {
    case SearchLocationType.CampiriBase:
      return campiriBaseIcon;
    case SearchLocationType.City:
      return cityIcon;
    case SearchLocationType.Place:
      return placesIcon;
    case SearchLocationType.SearchResult:
      return searchResultIcon;
    case SearchLocationType.Station:
      return stationIcon;
    default:
      return null;
  }
};

export const getSearchResultType = (
  title: string,
  popularSearches: PlaceType,
  popularPlaces: PlaceType
): PlaceType[Country.cz][number] | null => {
  for (const i of Object.keys(popularPlaces)) {
    if (!popularSearches?.[i as Country] || !popularPlaces?.[i as Country]) return null;
    const locationsArray = [...popularSearches[i as Country], ...popularPlaces[i as Country]];
    if (locationsArray && Array.isArray(locationsArray)) {
      const locationFound = locationsArray.find((location) => {
        if (Array.isArray(location.name)) {
          return location.name.includes(title.toLowerCase());
        }
        return location.name.toLowerCase() === title.toLowerCase();
      });
      if (locationFound) return locationFound;
    }
  }
  return null;
};

export const proximityCenter = {
  [Country.cz]: '14.418540,50.073658',
  [Country.sk]: '17.107748,48.148598',
  [Country.pl]: '21.178225,52.196217',
  [Country.es]: '-4.420000,36.719444',
  [Country.de]: '52.5200,13.4050',
  [Country.it]: '41.8719,12.5674',
};

export enum SearchLocationType {
  CampiriBase,
  City,
  Place,
  SearchResult,
  Station,
}

export type PlaceType = Record<
  Country,
  Array<{
    title: ReactNode | string;
    description?: ReactNode | string;
    type: SearchLocationType;
    popupText?: ReactNode | string;
    name: string;
  }>
>;
