const freewayRoutes = {
  blog: {
    template: '/blogPage',
    urls: {
      cs: '/blog',
      en: '/blog',
      es: '/blog',
      pl: '/blog',
      sk: '/blog',
      it: '/blog',
      de: '/blog',
    },
    isStatic: true,
  },
  helpCenter: {
    template: '/helpCenter',
    urls: {
      cs: '/help-center',
      en: '/help-center',
      es: '/help-center',
      pl: '/help-center',
      sk: '/help-center',
      it: '/centro-assistenza',
      de: '/help-center',
    },
    isStatic: true,
  },
  helpCenterDetailPreview: {
    template: '/helpCenterPreview/[articleId]',
    routingTemplate: '/helpCenterPreview/articleId?slug=:slug&id=:id',
    urls: {
      cs: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)/preview',
      pl: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)/preview',
      sk: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)/preview',
      en: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)/preview',
      es: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)/preview',
      it: '/:slug([A-Za-z0-9-]+)--centro-assistenza-:id([A-Za-z0-9-]+)/preview',
      de: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)/preview',
    },
    isStatic: false,
  },
  helpCenterDetail: {
    template: '/helpCenter/[articleId]',
    routingTemplate: '/helpCenter/articleId?slug=:slug&id=:id',
    urls: {
      cs: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)',
      sk: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)',
      pl: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)',
      en: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)',
      es: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)',
      it: '/:slug([A-Za-z0-9-]+)--centro-assistenza-:id([A-Za-z0-9-]+)',
      de: '/:slug([A-Za-z0-9-]+)--help-center-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  search: {
    template: '/search',
    urls: {
      cs: '/najdi-karavan',
      en: '/search',
      es: '/buscar',
      pl: '/szukaj-kampera',
      sk: '/najdi-karavan',
      it: '/search',
      de: '/search',
    },
    isStatic: false,
  },
  index: {
    template: '/',
    urls: {
      cs: '',
      en: '',
      es: '',
      pl: '',
      sk: '',
      it: '',
      de: '',
    },
    isStatic: true,
  },
  login: {
    template: '/login',
    urls: {
      cs: '/prihlasit-se',
      en: '/login',
    },
    isStatic: true,
  },
  campervan: {
    template: '/campervan/[id]',
    routingTemplate: '/campervan/:id?name=:name&id=:id',
    urls: {
      cs: '/:name([a-z0-9-]*)--karavan-:id([0-9]+)',
      en: '/:name([a-z0-9-]*)--campervan-:id([0-9]+)',
      es: '/:name([a-z0-9-]*)--autocaravana-:id([0-9]+)',
      pl: '/:name([a-z0-9-]*)--samochod-:id([0-9]+)',
      sk: '/:name([a-z0-9-]*)--karavan-:id([0-9]+)',
      de: '/:name([a-z0-9-]*)--campervan-:id([0-9]+)',
      it: '/:name([a-z0-9-]*)--campervan-:id([0-9]+)',
    },
    isStatic: false,
  },

  operatorVehiclesDashboard: {
    template: '/operator/vehicles',
    urls: {
      cs: '/operator/vehicles',
      en: '/operator/vehicles',
      es: '/operator/vehicles',
      pl: '/operator/vehicles',
      sk: '/operator/vehicles',
      de: '/operator/vehicles',
      it: '/operator/vehicles',
    },
    isStatic: true,
  },
  operatorVehicleCreate: {
    template: '/operator/vehicleCreate',
    urls: {
      cs: '/operator/vehicle-create',
      en: '/operator/vehicle-create',
      es: '/operator/vehicle-create',
      pl: '/operator/vehicle-create',
      sk: '/operator/vehicle-create',
      de: '/operator/vehicle-create',
      it: '/operator/vehicle-create',
    },
    isStatic: true,
  },
  operatorVehicleEdit: {
    template: '/operator/vehicles/[vehicleGuid]/edit',
    routingTemplate: '/operator/vehicles/:vehicleGuid/edit?vehicleGuid=:vehicleGuid',
    urls: {
      cs: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/edit',
      en: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/edit',
      es: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/edit',
      pl: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/edit',
      sk: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/edit',
      de: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/edit',
      it: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/edit',
    },
    isStatic: false,
  },
  operatorVehicleView: {
    template: '/operator/vehicles/[vehicleGuid]/view',
    routingTemplate: '/operator/vehicles/:vehicleGuid/view?vehicleGuid=:vehicleGuid',
    urls: {
      cs: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/view',
      en: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/view',
      es: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/view',
      pl: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/view',
      sk: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/view',
      de: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/view',
      it: '/operator/vehicles/:vehicleGuid([a-z0-9-]*)/view',
    },
    isStatic: false,
  },

  operatorStationsDashboard: {
    template: '/operator/stations',
    urls: {
      cs: '/operator/stations',
      en: '/operator/stations',
      es: '/operator/stations',
      pl: '/operator/stations',
      sk: '/operator/stations',
      de: '/operator/stations',
      it: '/operator/stations',
    },
    isStatic: true,
  },
  oldBookingInfo: {
    template: '/fwc-redirect/booking-info',
    urls: {
      cs: '/old-booking',
      en: '/old-booking',
      es: '/old-booking',
      pl: '/old-booking',
      sk: '/old-booking',
      it: '/old-booking',
      de: '/old-booking',
    },
    isStatic: false,
  },
  operatorStationCreate: {
    template: '/operator/stations/create',
    urls: {
      cs: '/operator/station-create',
      en: '/operator/station-create',
      es: '/operator/station-create',
      pl: '/operator/station-create',
      sk: '/operator/station-create',
      de: '/operator/station-create',
      it: '/operator/station-create',
    },
    isStatic: true,
  },
  operatorStationEdit: {
    template: '/operator/stations/[stationId]/edit',
    routingTemplate: '/operator/stations/:stationId/edit?stationId=:stationId',
    urls: {
      cs: '/operator/stations/:stationId([a-z0-9-]*)/edit',
      en: '/operator/stations/:stationId([a-z0-9-]*)/edit',
      es: '/operator/stations/:stationId([a-z0-9-]*)/edit',
      pl: '/operator/stations/:stationId([a-z0-9-]*)/edit',
      sk: '/operator/stations/:stationId([a-z0-9-]*)/edit',
      de: '/operator/stations/:stationId([a-z0-9-]*)/edit',
      it: '/operator/stations/:stationId([a-z0-9-]*)/edit',
    },
    isStatic: false,
  },
  operatorStationView: {
    template: '/operator/stations/[stationId]/view',
    routingTemplate: '/operator/stations/:stationId/view?stationId=:stationId',
    urls: {
      cs: '/operator/stations/:stationId([a-z0-9-]*)/view',
      en: '/operator/stations/:stationId([a-z0-9-]*)/view',
      es: '/operator/stations/:stationId([a-z0-9-]*)/view',
      pl: '/operator/stations/:stationId([a-z0-9-]*)/view',
      sk: '/operator/stations/:stationId([a-z0-9-]*)/view',
      de: '/operator/stations/:stationId([a-z0-9-]*)/view',
      it: '/operator/stations/:stationId([a-z0-9-]*)/view',
    },
    isStatic: false,
  },

  operatorReportsPickups: {
    template: '/operator/reports/pickups',
    urls: {
      cs: '/operator/reports/pickups',
      en: '/operator/reports/pickups',
      es: '/operator/reports/pickups',
      pl: '/operator/reports/pickups',
      sk: '/operator/reports/pickups',
      de: '/operator/reports/pickups',
      it: '/operator/reports/pickups',
    },
  },

  operatorReportsDropoffs: {
    template: '/operator/reports/dropoffs',
    urls: {
      cs: '/operator/reports/dropoffs',
      en: '/operator/reports/dropoffs',
      es: '/operator/reports/dropoffs',
      pl: '/operator/reports/dropoffs',
      sk: '/operator/reports/dropoffs',
      de: '/operator/reports/dropoffs',
      it: '/operator/reports/dropoffs',
    },
  },

  legalNotice: {
    template: '/fwc-static/legalNotice',
    routingTemplate: '/fwc-static/legalNotice',
    urls: {
      cs: '/imprint',
      en: '/imprint',
      es: '/imprint',
      pl: '/imprint',
      sk: '/imprint',
      de: '/impressum',
      it: '/avviso-legale',
    },
    isStatic: true,
  },
  buyCamper: {
    template: '/fwc-static/buyCamper',
    routingTemplate: '/fwc-static/buyCamper',
    urls: {
      cs: '/buy-camper',
      en: '/buy-camper',
      es: '/buy-camper',
      pl: '/buy-camper',
      sk: '/buy-camper',
      de: '/kaufen-camper',
      it: '/vendita-camper',
    },
    isStatic: true,
  },
  newsletter: {
    template: '/fwc-static/newsletter',
    routingTemplate: '/fwc-static/newsletter',
    urls: {
      cs: '/newsletter',
      en: '/newsletter',
      es: '/newsletter',
      pl: '/newsletter',
      sk: '/newsletter',
      de: '/newsletter',
      it: '/newsletter',
    },
    isStatic: true,
  },
  aboutUs: {
    template: '/fwc-static/aboutUs',
    routingTemplate: '/fwc-static/aboutUs',
    urls: {
      cs: '/about-us',
      en: '/about-us',
      es: '/about-us',
      pl: '/about-us',
      sk: '/about-us',
      de: '/ueber-uns',
      it: '/su-noi',
    },
    isStatic: true,
  },
  operatorAvailabilityCalendar: {
    template: '/operator/calendar',
    routingTemplate: '/operator/calendar',
    urls: {
      cs: '/operator/availability-calendar',
      en: '/operator/availability-calendar',
      es: '/operator/availability-calendar',
      pl: '/operator/availability-calendar',
      sk: '/operator/availability-calendar',
      de: '/operator/availability-calendar',
      it: '/operator/availability-calendar',
    },
    isStatic: true,
  },
};

exports.freewayRoutes = freewayRoutes;
