/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomerChangePasswordCommand } from '../models';
// @ts-ignore
import { CustomerEmailLoginCommand } from '../models';
// @ts-ignore
import { CustomerEmailLoginCommandResult } from '../models';
// @ts-ignore
import { CustomerFacebookSignupCommand } from '../models';
// @ts-ignore
import { CustomerFacebookSignupCommandResult } from '../models';
// @ts-ignore
import { CustomerForgotPasswordCommand } from '../models';
// @ts-ignore
import { CustomerGoogleSignupCommand } from '../models';
// @ts-ignore
import { CustomerGoogleSignupCommandResult } from '../models';
// @ts-ignore
import { CustomerLoginCommand } from '../models';
// @ts-ignore
import { CustomerLoginCommandResult } from '../models';
// @ts-ignore
import { CustomerSignupCommand } from '../models';
// @ts-ignore
import { GuidResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RefreshAccessTokenCommandResult } from '../models';
/**
 * AuthenticationMarketplaceApi - axios parameter creator
 * @export
 */
export const AuthenticationMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes the password of currently logged user.
         * @param {CustomerChangePasswordCommand} [customerChangePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationChangePasswordPost: async (customerChangePasswordCommand?: CustomerChangePasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/authentication/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerChangePasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attempts logging in the user using facebook token.
         * @param {CustomerFacebookSignupCommand} [customerFacebookSignupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationFacebookSignupPost: async (customerFacebookSignupCommand?: CustomerFacebookSignupCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/authentication/facebook-signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerFacebookSignupCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resends the sign up token mailer for resetting the user password.
         * @param {CustomerForgotPasswordCommand} [customerForgotPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationForgotPasswordPost: async (customerForgotPasswordCommand?: CustomerForgotPasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/authentication/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerForgotPasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attempts logging in the user using google token.
         * @param {CustomerGoogleSignupCommand} [customerGoogleSignupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationGoogleSignupPost: async (customerGoogleSignupCommand?: CustomerGoogleSignupCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/authentication/google-signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerGoogleSignupCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attempts logging in the user using email and password.
         * @param {CustomerLoginCommand} [customerLoginCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationLoginPost: async (customerLoginCommand?: CustomerLoginCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerLoginCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resends the email verification mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationResendEmailVerificationPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/authentication/resend-email-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Signs up a new customer account.
         * @param {CustomerSignupCommand} [customerSignupCommand] User signup information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationSignupPost: async (customerSignupCommand?: CustomerSignupCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/authentication/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSignupCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attempts logging in the user using sign up token.
         * @param {CustomerEmailLoginCommand} [customerEmailLoginCommand] User login information, containing the signup token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationVerifyEmailPost: async (customerEmailLoginCommand?: CustomerEmailLoginCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/authentication/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerEmailLoginCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refreshes the access token.
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeAuthenticationRefreshtokensPost: async (refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/authentication/refreshtokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationMarketplaceApi - functional programming interface
 * @export
 */
export const AuthenticationMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Changes the password of currently logged user.
         * @param {CustomerChangePasswordCommand} [customerChangePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceAuthenticationChangePasswordPost(customerChangePasswordCommand?: CustomerChangePasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceAuthenticationChangePasswordPost(customerChangePasswordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Attempts logging in the user using facebook token.
         * @param {CustomerFacebookSignupCommand} [customerFacebookSignupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceAuthenticationFacebookSignupPost(customerFacebookSignupCommand?: CustomerFacebookSignupCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerFacebookSignupCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceAuthenticationFacebookSignupPost(customerFacebookSignupCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resends the sign up token mailer for resetting the user password.
         * @param {CustomerForgotPasswordCommand} [customerForgotPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceAuthenticationForgotPasswordPost(customerForgotPasswordCommand?: CustomerForgotPasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceAuthenticationForgotPasswordPost(customerForgotPasswordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Attempts logging in the user using google token.
         * @param {CustomerGoogleSignupCommand} [customerGoogleSignupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceAuthenticationGoogleSignupPost(customerGoogleSignupCommand?: CustomerGoogleSignupCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGoogleSignupCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceAuthenticationGoogleSignupPost(customerGoogleSignupCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Attempts logging in the user using email and password.
         * @param {CustomerLoginCommand} [customerLoginCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceAuthenticationLoginPost(customerLoginCommand?: CustomerLoginCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerLoginCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceAuthenticationLoginPost(customerLoginCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resends the email verification mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceAuthenticationResendEmailVerificationPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceAuthenticationResendEmailVerificationPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Signs up a new customer account.
         * @param {CustomerSignupCommand} [customerSignupCommand] User signup information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceAuthenticationSignupPost(customerSignupCommand?: CustomerSignupCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceAuthenticationSignupPost(customerSignupCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Attempts logging in the user using sign up token.
         * @param {CustomerEmailLoginCommand} [customerEmailLoginCommand] User login information, containing the signup token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceAuthenticationVerifyEmailPost(customerEmailLoginCommand?: CustomerEmailLoginCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerEmailLoginCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceAuthenticationVerifyEmailPost(customerEmailLoginCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refreshes the access token.
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeAuthenticationRefreshtokensPost(refreshToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshAccessTokenCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeAuthenticationRefreshtokensPost(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationMarketplaceApi - factory interface
 * @export
 */
export const AuthenticationMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Changes the password of currently logged user.
         * @param {CustomerChangePasswordCommand} [customerChangePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationChangePasswordPost(customerChangePasswordCommand?: CustomerChangePasswordCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceAuthenticationChangePasswordPost(customerChangePasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attempts logging in the user using facebook token.
         * @param {CustomerFacebookSignupCommand} [customerFacebookSignupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationFacebookSignupPost(customerFacebookSignupCommand?: CustomerFacebookSignupCommand, options?: any): AxiosPromise<CustomerFacebookSignupCommandResult> {
            return localVarFp.apiV1MarketplaceAuthenticationFacebookSignupPost(customerFacebookSignupCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resends the sign up token mailer for resetting the user password.
         * @param {CustomerForgotPasswordCommand} [customerForgotPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationForgotPasswordPost(customerForgotPasswordCommand?: CustomerForgotPasswordCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceAuthenticationForgotPasswordPost(customerForgotPasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attempts logging in the user using google token.
         * @param {CustomerGoogleSignupCommand} [customerGoogleSignupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationGoogleSignupPost(customerGoogleSignupCommand?: CustomerGoogleSignupCommand, options?: any): AxiosPromise<CustomerGoogleSignupCommandResult> {
            return localVarFp.apiV1MarketplaceAuthenticationGoogleSignupPost(customerGoogleSignupCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attempts logging in the user using email and password.
         * @param {CustomerLoginCommand} [customerLoginCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationLoginPost(customerLoginCommand?: CustomerLoginCommand, options?: any): AxiosPromise<CustomerLoginCommandResult> {
            return localVarFp.apiV1MarketplaceAuthenticationLoginPost(customerLoginCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resends the email verification mailer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationResendEmailVerificationPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceAuthenticationResendEmailVerificationPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Signs up a new customer account.
         * @param {CustomerSignupCommand} [customerSignupCommand] User signup information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationSignupPost(customerSignupCommand?: CustomerSignupCommand, options?: any): AxiosPromise<GuidResult> {
            return localVarFp.apiV1MarketplaceAuthenticationSignupPost(customerSignupCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attempts logging in the user using sign up token.
         * @param {CustomerEmailLoginCommand} [customerEmailLoginCommand] User login information, containing the signup token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceAuthenticationVerifyEmailPost(customerEmailLoginCommand?: CustomerEmailLoginCommand, options?: any): AxiosPromise<CustomerEmailLoginCommandResult> {
            return localVarFp.apiV1MarketplaceAuthenticationVerifyEmailPost(customerEmailLoginCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refreshes the access token.
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeAuthenticationRefreshtokensPost(refreshToken?: string, options?: any): AxiosPromise<RefreshAccessTokenCommandResult> {
            return localVarFp.apiV1MarketplaceMeAuthenticationRefreshtokensPost(refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationMarketplaceApi - interface
 * @export
 * @interface AuthenticationMarketplaceApi
 */
export interface AuthenticationMarketplaceApiInterface {
    /**
     * 
     * @summary Changes the password of currently logged user.
     * @param {CustomerChangePasswordCommand} [customerChangePasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceAuthenticationChangePasswordPost(customerChangePasswordCommand?: CustomerChangePasswordCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Attempts logging in the user using facebook token.
     * @param {CustomerFacebookSignupCommand} [customerFacebookSignupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceAuthenticationFacebookSignupPost(customerFacebookSignupCommand?: CustomerFacebookSignupCommand, options?: AxiosRequestConfig): AxiosPromise<CustomerFacebookSignupCommandResult>;

    /**
     * 
     * @summary Resends the sign up token mailer for resetting the user password.
     * @param {CustomerForgotPasswordCommand} [customerForgotPasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceAuthenticationForgotPasswordPost(customerForgotPasswordCommand?: CustomerForgotPasswordCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Attempts logging in the user using google token.
     * @param {CustomerGoogleSignupCommand} [customerGoogleSignupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceAuthenticationGoogleSignupPost(customerGoogleSignupCommand?: CustomerGoogleSignupCommand, options?: AxiosRequestConfig): AxiosPromise<CustomerGoogleSignupCommandResult>;

    /**
     * 
     * @summary Attempts logging in the user using email and password.
     * @param {CustomerLoginCommand} [customerLoginCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceAuthenticationLoginPost(customerLoginCommand?: CustomerLoginCommand, options?: AxiosRequestConfig): AxiosPromise<CustomerLoginCommandResult>;

    /**
     * 
     * @summary Resends the email verification mailer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceAuthenticationResendEmailVerificationPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Signs up a new customer account.
     * @param {CustomerSignupCommand} [customerSignupCommand] User signup information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceAuthenticationSignupPost(customerSignupCommand?: CustomerSignupCommand, options?: AxiosRequestConfig): AxiosPromise<GuidResult>;

    /**
     * 
     * @summary Attempts logging in the user using sign up token.
     * @param {CustomerEmailLoginCommand} [customerEmailLoginCommand] User login information, containing the signup token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceAuthenticationVerifyEmailPost(customerEmailLoginCommand?: CustomerEmailLoginCommand, options?: AxiosRequestConfig): AxiosPromise<CustomerEmailLoginCommandResult>;

    /**
     * 
     * @summary Refreshes the access token.
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApiInterface
     */
    apiV1MarketplaceMeAuthenticationRefreshtokensPost(refreshToken?: string, options?: AxiosRequestConfig): AxiosPromise<RefreshAccessTokenCommandResult>;

}

/**
 * AuthenticationMarketplaceApi - object-oriented interface
 * @export
 * @class AuthenticationMarketplaceApi
 * @extends {BaseAPI}
 */
export class AuthenticationMarketplaceApi extends BaseAPI implements AuthenticationMarketplaceApiInterface {
    /**
     * 
     * @summary Changes the password of currently logged user.
     * @param {CustomerChangePasswordCommand} [customerChangePasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceAuthenticationChangePasswordPost(customerChangePasswordCommand?: CustomerChangePasswordCommand, options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceAuthenticationChangePasswordPost(customerChangePasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attempts logging in the user using facebook token.
     * @param {CustomerFacebookSignupCommand} [customerFacebookSignupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceAuthenticationFacebookSignupPost(customerFacebookSignupCommand?: CustomerFacebookSignupCommand, options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceAuthenticationFacebookSignupPost(customerFacebookSignupCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resends the sign up token mailer for resetting the user password.
     * @param {CustomerForgotPasswordCommand} [customerForgotPasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceAuthenticationForgotPasswordPost(customerForgotPasswordCommand?: CustomerForgotPasswordCommand, options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceAuthenticationForgotPasswordPost(customerForgotPasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attempts logging in the user using google token.
     * @param {CustomerGoogleSignupCommand} [customerGoogleSignupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceAuthenticationGoogleSignupPost(customerGoogleSignupCommand?: CustomerGoogleSignupCommand, options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceAuthenticationGoogleSignupPost(customerGoogleSignupCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attempts logging in the user using email and password.
     * @param {CustomerLoginCommand} [customerLoginCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceAuthenticationLoginPost(customerLoginCommand?: CustomerLoginCommand, options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceAuthenticationLoginPost(customerLoginCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resends the email verification mailer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceAuthenticationResendEmailVerificationPost(options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceAuthenticationResendEmailVerificationPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Signs up a new customer account.
     * @param {CustomerSignupCommand} [customerSignupCommand] User signup information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceAuthenticationSignupPost(customerSignupCommand?: CustomerSignupCommand, options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceAuthenticationSignupPost(customerSignupCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attempts logging in the user using sign up token.
     * @param {CustomerEmailLoginCommand} [customerEmailLoginCommand] User login information, containing the signup token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceAuthenticationVerifyEmailPost(customerEmailLoginCommand?: CustomerEmailLoginCommand, options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceAuthenticationVerifyEmailPost(customerEmailLoginCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refreshes the access token.
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationMarketplaceApi
     */
    public apiV1MarketplaceMeAuthenticationRefreshtokensPost(refreshToken?: string, options?: AxiosRequestConfig) {
        return AuthenticationMarketplaceApiFp(this.configuration).apiV1MarketplaceMeAuthenticationRefreshtokensPost(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}

