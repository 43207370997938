/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const LanguageCode = {
    NotSet: 'NotSet',
    Chinese: 'Chinese',
    English: 'English',
    Spanish: 'Spanish',
    Bulgarian: 'Bulgarian',
    Catalan: 'Catalan',
    Czech: 'Czech',
    Danish: 'Danish',
    German: 'German',
    Greek: 'Greek',
    Finnish: 'Finnish',
    French: 'French',
    Hebrew: 'Hebrew',
    Hungarian: 'Hungarian',
    Icelandic: 'Icelandic',
    Italian: 'Italian',
    Japanese: 'Japanese',
    Korean: 'Korean',
    Dutch: 'Dutch',
    Norwegian: 'Norwegian',
    Polish: 'Polish',
    Raeto: 'Raeto',
    Romanian: 'Romanian',
    Russian: 'Russian',
    Croatian: 'Croatian',
    Slovak: 'Slovak',
    Albanian: 'Albanian',
    Swedish: 'Swedish',
    Thai: 'Thai',
    Turkish: 'Turkish',
    Urdu: 'Urdu',
    Indonesian: 'Indonesian',
    Ukranian: 'Ukranian',
    Belarusian: 'Belarusian',
    Slovenian: 'Slovenian',
    Estonian: 'Estonian',
    Latvian: 'Latvian',
    Lithuanian: 'Lithuanian',
    Farsi: 'Farsi',
    Vietnamese: 'Vietnamese',
    Basque: 'Basque',
    Sorbian: 'Sorbian',
    Macedonian: 'Macedonian',
    Sutu: 'Sutu',
    Tsonga: 'Tsonga',
    Setsuana: 'Setsuana',
    Xhosa: 'Xhosa',
    Zulu: 'Zulu',
    Afrikaans: 'Afrikaans',
    Faeroese: 'Faeroese',
    Hindi: 'Hindi',
    Maltese: 'Maltese',
    Gaelic: 'Gaelic',
    Yiddish: 'Yiddish',
    Malay: 'Malay',
    Arabic: 'Arabic',
    Portuguese: 'Portuguese'
} as const;

export type LanguageCode = typeof LanguageCode[keyof typeof LanguageCode];



