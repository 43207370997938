import { createSelector } from 'reselect';
import { settingsSelector } from 'features/redux/selectors/settings';
import { createDeepEqualSelector } from '~/src/redux/createDeepSelector';

// deep selector for reducer with nested objects -> you want memoize this not with reference
export const modalSelector = createDeepEqualSelector(settingsSelector, (setting) => setting.modal);
// normal createSelector for simple primitives returned by deeply compared object
export const languageSelector = createSelector(settingsSelector, (setting) => setting.language);
export const countrySelector = createSelector(settingsSelector, (setting) => setting.country);
export const cultureTokenSelector = createSelector(
  settingsSelector,
  (setting) => `${setting.language}-${setting.country}`
);
export { settingsSelector };
