import * as t from '../types';

export interface UserReduxType {
  error: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  customerData: any | null;
  operatorData: any | null;
  allDataRequired: boolean;
}

const userState = (
  // eslint-disable-next-line default-param-last
  state: UserReduxType = {
    isAuthenticated: false,
    loading: true,
    error: null,
    customerData: null,
    operatorData: null,
    allDataRequired: false,
  },
  action: any
) => {
  switch (action.type) {
    case t.LOGIN_USER:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        authToken: action.payload.authToken,
        refreshToken: action.payload.refreshToken,
      };
    case t.AUTH_USER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case t.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        error: null,
      };

    case t.USER_LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
      };

    case t.REGISTER_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case t.SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        error: null,
        customerData: null,
      };

    case t.SET_USER_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          ...action.payload,
        },
      };

    case t.SET_OPERATOR_DATA:
      return {
        ...state,
        operatorData: {
          ...state.operatorData,
          ...action.payload,
        },
      };

    case t.SET_USER_PROFILE_PICTURE:
      if (!action.payload) {
        return {
          ...state,
          customerData: {
            ...state.customerData,
            profilePicture: null,
          },
        };
      }

      return {
        ...state,
        customerData: {
          ...state.customerData,
          profilePicture: { ...action.payload },
        },
      };

    case t.SET_ALL_DATA_REQUIRED:
      return {
        ...state,
        allDataRequired: action.payload,
      };
    default:
      return { ...state };
  }
};

export default userState;
