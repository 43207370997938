import * as t from '../types';

const alterations = (
  // eslint-disable-next-line default-param-last
  state = {
    selectedDays: null,
    shownDateCalendar: null,
    bookingAlterations: [],
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_SEARCH_DATES_ALTERATIONS:
      return {
        ...state,
        selectedDays: action.payload,
      };
    case t.SET_SHOWN_DATE_CALENDAR_ALTERATIONS:
      return {
        ...state,
        shownDateCalendar: action.payload,
      };

    case t.SET_BOOKING_ALTERATIONS:
      return {
        ...state,
        bookingAlterations: action.payload,
      };

    default:
      return { ...state };
  }
};

export default alterations;
