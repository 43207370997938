import axios from 'axios';
import React, { ReactNode, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { i18n } from '@lingui/core';
import { handleAuthenticationHook } from './components/auth/utils';
import { useLocalizedRouter } from './components/LocalizedLink';
import { SettingsProps } from './redux/reducers/settings';
import { setCookie } from './utils/cookies';
import { setCountry, setLanguage } from './redux/actions/settings';
import { Country, cultureTokens, Language } from '~/src/utils/routing';

interface Props {
  children: ReactNode;
  settings: SettingsProps;
  dispatch: any;
}

export const AuthWrapper = ({ children, dispatch, settings }: PropsFromRedux & Props) => {
  const router = useLocalizedRouter();

  useEffect(() => {
    handleAuthenticationHook(dispatch);
  }, [dispatch]);

  useEffect(() => {
    axios.defaults.headers['accept-language'] = `${settings.language}-${settings.country}`;
    document?.getElementsByTagName('html')[0]?.setAttribute('lang', `${settings.language}-${settings.country}`);
    setCookie('Accept-Language', `${settings.language}-${settings.country}`, 100);
  }, [settings.country, settings.language]);

  useEffect(() => {
    const handleHistoryBackBtn = () => {
      const supportedTokens = Object.values(cultureTokens);

      const cultureToken = window?.history?.state?.as?.split('/')[1];
      if (supportedTokens.includes(cultureToken)) {
        const lang = cultureToken?.split('-')[0];
        const country = cultureToken?.split('-')[1];

        dispatch(setLanguage(Language[lang]));
        dispatch(setCountry(Country[country]));
        i18n.activate(lang);
      }
    };
    window?.addEventListener('popstate', handleHistoryBackBtn);

    return () => window.removeEventListener('popstate', handleHistoryBackBtn);
  }, []);

  return <>{children}</>;
};

const mapStateToProps = state => ({
  settings: state.settings,
});

const connector = connect(mapStateToProps, dispatch => ({ dispatch }));
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AuthWrapper);
