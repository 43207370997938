import { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import { AccountOperatorApi } from '~/libs/api/apis/account-operator-api';
import { Configuration } from '~/libs/api/configuration';
import { UpdateOperatorPayoutInformationCommand } from '~/libs/api/models/update-operator-payout-information-command';
import { UpdateOperatorIbanCommand } from '~/libs/api';

const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    const accessToken = window?.localStorage?.getItem('accessToken');
    return accessToken;
  }
  return null;
};

const { publicRuntimeConfig } = getConfig();

const getApiConfig = () =>
  new Configuration({
    basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ''),
    accessToken: getAccessToken,
  });

const apiConfiguration = getApiConfig();

const OPERATOR_ACCOUNT_API = new AccountOperatorApi(apiConfiguration, apiConfiguration.basePath);

export const getLoggedOperatorInformation = async (options?: AxiosRequestConfig) =>
  OPERATOR_ACCOUNT_API.apiV1OperatorMeAccountsGet();

export const initializeStripConnection = async (options?: AxiosRequestConfig) =>
  OPERATOR_ACCOUNT_API.apiV1OperatorMeAccountsInitializeStripeConnectAccountPost();

export const createConnectAccountLink = async (returnUrl?: string, refreshUrl?: string, options?: AxiosRequestConfig) =>
  OPERATOR_ACCOUNT_API.apiV1OperatorMeAccountsCreateConnectAccountLinkPost(returnUrl, refreshUrl);

export const postOperatorPayoutInformation = async (
  updateOperatorPayoutInformationCommand?: UpdateOperatorPayoutInformationCommand,
  options?: AxiosRequestConfig
) => OPERATOR_ACCOUNT_API.apiV1OperatorMeAccountsPayoutInformationPost(updateOperatorPayoutInformationCommand, options);

export const updateOperatorIban = async (payload: UpdateOperatorIbanCommand) =>
  OPERATOR_ACCOUNT_API.apiV1OperatorMeIbanPut(payload);
