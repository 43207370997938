import { BookingStep } from '~/src/components/booking/steps';
import * as t from '../types';
import { OnlinePamentInt, PaymentsInt } from '~/src/redux/reducers/booking';
import { BookingViews } from '~/src/redux/reducers/rootReducer';
import { BookingState } from '~/libs/api/models/booking-state';

export const setNewBookingData = (bookingData: BookingViews) => dispatch => {
  dispatch({
    type: t.SET_NEW_BOOKING_DATA,
    payload: bookingData,
  });
};
export const addBookingData = (bookingData: BookingViews) => dispatch => {
  dispatch({
    type: t.ADD_BOOKING_DATA,
    payload: bookingData,
  });
};

export const setFetchedBookingData = (bookingData: BookingViews) => dispatch => {
  dispatch({
    type: t.SET_FETCHED_BOOKING_DATA,
    payload: bookingData,
  });
};
export const setIsOpenCheckout = (isOpenCheckout: boolean) => dispatch => {
  dispatch({
    type: t.SET_IS_OPEN_CHECKOUT,
    payload: isOpenCheckout,
  });
};

export const setInternalBookingStep = (bookingStep: BookingStep) => dispatch => {
  dispatch({
    type: t.SET_BOOKING_STEP,
    payload: bookingStep,
  });
};

export const setBookingDataLoading = (isLoading: boolean) => dispatch => {
  dispatch({
    type: t.SET_BOOKING_DATA_LOADING,
    payload: isLoading,
  });
};

export const setBookingDataLoadingMessage = (message: string | null) => dispatch => {
  dispatch({
    type: t.SET_LOADING_MESSAGE,
    payload: message,
  });
};

export const setBookingState = (state: BookingState) => dispatch => {
  dispatch({
    type: t.SET_BOOKING_STATE,
    payload: state,
  });
};

export const setBookingManagerNote = (note?: string) => dispatch => {
  dispatch({
    type: t.SET_BOOKING_MANAGER_NOTE,
    payload: note,
  });
};

export const setBlinkPaymentReady = (isReady?: boolean) => dispatch => {
  dispatch({
    type: t.SET_BLINK_READY,
    payload: isReady,
  });
};

export const setOnlinePaymentData = (objData?: Partial<OnlinePamentInt>) => dispatch => {
  dispatch({
    type: t.SET_ONLINE_PAYMENT_DATA,
    payload: objData,
  });
};

export const setBookingInsuranceFilled = (insuranceFilled?: boolean) => dispatch => {
  dispatch({
    type: t.SET_BOOKING_INSURANCE_FILLED,
    payload: insuranceFilled,
  });
};

export const setBookingCancelationProtection = (price?: any) => dispatch => {
  dispatch({
    type: t.SET_BOOKING_CANCELATION_PROTECTION,
    payload: price,
  });
};
