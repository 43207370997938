export const SET_CAR_DETAIL_DATA = 'SET_CAR_DETAIL_DATA';
export const GET_CAR_DATA = 'GET_CAR_DATA';
export const SET_CAR_UNAVAILABILITY = 'SET_CAR_UNAVAILABILITY';
export const SET_CAR_UNAVAILABILITY_PICKUP_ONLY = 'SET_CAR_UNAVAILABILITY_PICKUP_ONLY';
export const SET_CAR_UNAVAILABILITY_DROP_OFF_ONLY = 'SET_CAR_UNAVAILABILITY_DROP_OFF_ONLY';
export const SET_CAR_UNAVAILABILITY_ONE_DAY = 'SET_CAR_UNAVAILABILITY_ONE_DAY';
export const SET_CAR_CALENDAR_DAYS = 'SET_CAR_CALENDAR_DAYS';
export const SET_CAR_UNAVAILABILITY_LOADING = 'SET_CAR_UNAVAILABILITY_LOADING';
export const SET_CAR_UNAVAILABILITY_READY = 'SET_CAR_UNAVAILABILITY_READY';
export const SET_CAR_FIRST_AVAILABALE_DAY = 'SET_CAR_FIRST_AVAILABALE_DAY';
