import { combineReducers } from 'redux';
import { createResponsiveStateReducer, IBrowser } from 'redux-responsive';
import user from './user';
import search from './search';
import settings from './settings';
import booking from './booking';
import carDetail from './carDetail';
import calculation from './calculation';
import offer from './offer';
import checkin from './checkin';
import checkout from './checkout';
import inbox from './inbox';
import alterations from './alterations';

export type TypedIBrowser = IBrowser<{ xs: number; small: number; medium: number; large: number; extraLarge: number }>;

const rootReducer = combineReducers({
  user,
  search,
  settings,
  booking,
  carDetail,
  calculation,
  offer,
  checkin,
  checkout,
  inbox,
  alterations,

  screen: createResponsiveStateReducer({
    xs: 600,
    small: 860,
    medium: 1023,
    large: 1280,
    extraLarge: 1440,
  }),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
