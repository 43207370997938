const size = {
  xs: '600px',
  phone: '860px',
  tablet: '1023px',
  inboxDesktop: '1290px',
  desktop: '1440px',
};

/** @deprecated */
const deviceMaxWidth = {
  xs: `only screen and (max-width: ${size.xs})`,
  phone: `only screen and (max-width: ${size.phone})`,
  tablet: `only screen and (max-width: ${size.tablet})`,
  inboxDesktop: `only screen and (max-width: ${size.inboxDesktop})`,
  desktop: `only screen and (max-width: ${size.desktop})`,
};

/** @deprecated */
const deviceMinWidth = {
  xs: `only screen and (min-width: ${size.xs})`,
  phone: `only screen and (min-width: ${size.phone})`,
  tablet: `only screen and (min-width: ${size.tablet})`,
  inboxDesktop: `only screen and (min-width: ${size.inboxDesktop})`,
  desktop: `only screen and (min-width: ${size.desktop})`,
};

export { size, deviceMaxWidth, deviceMinWidth };
