import { ModalType } from '~/src/components/modal/modalTypes';
import * as t from '../types';
import { Country, Currency, Language } from '~/src/utils/routing';
import { CookieBarVariantType } from '../reducers/settings';
import { hideModal } from 'features/redux/actions/settings';

export const setLoading = (isLoading: boolean) => (dispatch) =>
  dispatch({
    type: t.SET_LOADING,
    payload: isLoading,
  });

export const setSpecialOfferBarVisible = (isVisible: boolean) => (dispatch) =>
  dispatch({
    type: t.SET_SPECIAL_OFFER_BAR_VISIBLE,
    payload: isVisible,
  });

export const setSpecialOfferBarRef = (ref: any) => (dispatch) =>
  dispatch({
    type: t.SET_SPECIAL_OFFER_BAR_REF,
    payload: ref,
  });

export const setGoogleReviews = (googleReviews: any) => (dispatch) =>
  dispatch({
    type: t.SET_GOOGLE_REVIEWS,
    payload: googleReviews,
  });

export const setCountry = (country: Country) => (dispatch) =>
  dispatch({
    type: t.SET_COUNTRY,
    payload: country,
  });

export const setLanguage = (language: Language) => (dispatch) =>
  dispatch({
    type: t.SET_LANGUAGE,
    payload: language,
  });

export const setCurrency = (currency: Currency) => (dispatch) =>
  dispatch({
    type: t.SET_CURRENCY,
    payload: currency,
  });

export const setCookieBarVariant = (variant: CookieBarVariantType) => (dispatch) =>
  dispatch({
    type: t.SET_COOKIE_BAR_VARIANT,
    payload: variant,
  });

export const setCookieBarVisible = (isVisible: boolean) => (dispatch) =>
  dispatch({
    type: t.SET_COOKIE_BAR_VISIBLE,
    payload: isVisible,
  });

export const setMarketingCookies = (hasCookie: boolean) => (dispatch) =>
  dispatch({
    type: t.SET_MARKETING_COOKIES,
    payload: hasCookie,
  });

export const setAnayticCookies = (hasCookie: boolean) => (dispatch) =>
  dispatch({
    type: t.SET_ANALYTIC_COOKIES,
    payload: hasCookie,
  });

export const showModal = (type: ModalType, modalProps?: any) => (dispatch) =>
  dispatch({
    type: t.SHOW_MODAL,
    payload: { isVisible: true, type, modalProps },
  });

export const showDesktopModal =
  (type: ModalType | null, modalProps = null) =>
  (dispatch) => {
    dispatch({
      type: t.SET_DESKTOP_MODAL,
      payload: { type, modalProps },
    });
  };

export { hideModal };

export const setCarDetailDidMount = (mount: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_CAR_DETAIL_DID_MOUNT,
    payload: mount,
  });
};
