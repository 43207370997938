import { z } from 'zod';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { isValidPhoneNumber } from 'react-phone-number-input';
import dayjs from 'dayjs';
import { Trans } from '@lingui/react';
import React from 'react';
import { loginSuccess, signOut, userAutheticationError, userAutheticationLoading } from '~/src/redux/actions/user';
import { setBookingDataLoading } from '~/src/redux/actions/booking';
import { authRefreshToken } from '~/src/api/auth';
import { checkBirthDate } from '~/src/utils/date';
import { CheckIconPassword, CorrectMessagePassword } from '~/src/components/auth/styles';
import { CombinedRouter } from '~/src/components/LocalizedLink';
import { routes } from '~/src/utils/routing';
import { CustomerProfileQueryResult } from '~/libs/api/models/customer-profile-query-result';
import { getAccessToken, getTokenExpiration, setSession } from 'features/auth/utils';

const getRefreshToken = () => {
  const accessToken = window?.localStorage?.getItem('refreshToken');

  return accessToken;
};

const isValidToken = (accessToken?: string) => {
  if (!accessToken) {
    return false;
  }
  const expiresIn = getTokenExpiration(accessToken);
  if (!expiresIn) return false;

  const currentTime = Date.now() / 1000;

  return expiresIn > currentTime;
};

const handleAuthenticationHook = async (dispatch) => {
  dispatch(userAutheticationLoading(true));

  try {
    const accessToken = window?.localStorage?.getItem('accessToken');
    const refreshToken = window?.localStorage?.getItem('refreshToken');
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken, refreshToken, authRefreshToken);
      dispatch(loginSuccess());
      return;
    }

    if (refreshToken) {
      const refreshTokenResponse = await authRefreshToken(refreshToken);
      const dto = refreshTokenResponse.data;
      setSession(dto.accessToken, dto.refreshToken, authRefreshToken);
      dispatch(loginSuccess());
    } else {
      dispatch(userAutheticationError('User could not be logged in'));
    }
  } catch (error) {
    console.error(error);
    dispatch(userAutheticationError('User could not be logged in ...'));
    dispatch(setBookingDataLoading(false));
    dispatch(signOut());
  }

  dispatch(userAutheticationLoading(false));
};

export const getAccountCompletionFormSchema = (
  customerData: CustomerProfileQueryResult | null,
  shouldCreatePassword: boolean
) => {
  let birthDate: string;
  return z.object({
    email: z
      .string()
      .email({ message: i18n._(t({ id: 'inputText.labels.email.required' })) })
      .default(customerData?.email),
    name: z
      .string()
      .min(1, { message: i18n._(t({ id: 'inputText.labels.name.required' })) })
      .default(customerData?.firstName),
    phone: z
      .string()
      .refine((val) => isValidPhoneNumber(val), i18n._(t({ id: 'phoneInput.labels.wrongFormat' })))
      .default(customerData?.phone),
    dateOfBirth: z
      .string()
      .refine(
        (val) => {
          birthDate = checkBirthDate(val);
          return birthDate === null;
        },
        birthDate ?? i18n._(t({ id: 'labels.eror.invalidDate' }))
      )
      .default(customerData?.birthDate && dayjs(customerData.birthDate).format('DD/MM/YYYY')),
    subscribeNewsletter: z.boolean().optional().default(false),
    ...(shouldCreatePassword
      ? {
          password: z.string().min(8, { message: i18n._(t({ id: 'inputText.labels.passwors.lessThan8Chars.error' })) }),
        }
      : {}),
  });
};

export type AccountCompletionFormSchema = z.infer<ReturnType<typeof getAccountCompletionFormSchema>>;

const getPasswordMessage = (password: string, hasPasswordErrors: boolean) => {
  if (password?.length >= 8) {
    return (
      <CorrectMessagePassword>
        <CheckIconPassword className="icon-icon-action-check" />
        <Trans id="inputText.labels.passwors.lessThan8Chars.info.error" message="At least 8 characters" />
      </CorrectMessagePassword>
    );
  }
  if ((!password || password?.length < 8) && !hasPasswordErrors) {
    return (
      <CorrectMessagePassword className="info">
        <CheckIconPassword className="icon icon-info" />
        <Trans id="inputText.labels.passwors.lessThan8Chars.info.error" message="At least 8 characters" />
      </CorrectMessagePassword>
    );
  }
  return null;
};

const accountCompletionSuccessRedirect = async (router: Partial<CombinedRouter>) => {
  if (router.query.booking) {
    return router.localizedReplace(routes.bookingDetail, { hrefParams: { id: router.query.booking } });
  }
  if (router.query.redirect) {
    const url = new URL(String(router.query.redirect), window.location.href);
    if (url.hostname === window.location.hostname) {
      return router.replace(String(router.query.redirect));
    }
  }
  return router.localizedReplace(routes.index, { hash: '' });
};

export {
  setSession,
  accountCompletionSuccessRedirect,
  isValidToken,
  getRefreshToken,
  getAccessToken,
  handleAuthenticationHook,
  getPasswordMessage,
};
