import * as t from '../types';

export interface CalculationReduxType {
  data: any | null;
  loading: boolean;
  error: boolean | string;
  promoCode: null | string;
}

const calculationState = (
  // eslint-disable-next-line default-param-last
  state: CalculationReduxType = {
    data: null,
    loading: false,
    error: false,
    promoCode: null,
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_CALCULATION_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case t.SET_CALCULATION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case t.SET_CALCULATION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case t.SET_CALCULATION_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    default:
      return { ...state };
  }
};

export default calculationState;
