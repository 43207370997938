/* eslint-disable default-param-last */
import * as t from '../types';

export interface SearchInfoReduxData {
  place: any;
  adults: number;
  children: number;
  selectedDays: any;
  flexibleSearchOffset: number;
  shownDateCalendar: any;
  instantBookable: boolean;
  insuranceIncludedInPrice: boolean;
  minimumPrice: number;
  maximumPrice: number;
  includedKmFrom: number;
  includedKmTo: number;
  unlimitedIncludedKilometers: boolean;
  offRoad: boolean;
  festival: boolean;
  preferences: {
    licenseCategory: any;
    sleepCountFrom: number;
    sleepCountTo: number;
    seatCapacityTo: number;
    passengersCapacity: number;
    berthCountFrom: number;
    berthCountTo: number;
    bedCountFrom: number;
    bedCountTo: number;
    transmissionType: Array<any>;
    shower: Array<any>;
    toilet: Array<any>;
    winterOperation: any;
    vehicleType: Array<any>;
    kitchenSink: boolean;
    parkingSensors: boolean;
    television: boolean;
    fridge: boolean;
    isofix: boolean;
    heating: boolean;
    kitchenWear: boolean;
    gpsNavigation: boolean;
    awning: boolean;
    airConditioning: boolean;
    bicycleCarrier: Array<any>;
    solarPanels: boolean;
    towingDevice: boolean;

    heatedWasteTank: boolean;
    winterTires: boolean;
    snowChains: boolean;
  };
  vehicle: {
    yearBuildFrom: number;
    yearBuildTo: number;
    weightKgFrom: number;
    weightKgTo: any;
    manufacturer: null;
    payloadLimitKgFrom: number;
    payloadLimitKgTo: number;
    fuel: Array<any>;
  };
  policy: {
    pets: Array<any>;
    smoking: Array<any>;
  };
}

interface SearchReduxMain {
  searchInfo: SearchInfoReduxData;
}

const search = (
  state: SearchReduxMain = {
    searchInfo: {
      place: null,
      adults: 0,
      children: 0,
      selectedDays: null,
      flexibleSearchOffset: 0,
      shownDateCalendar: null,
      instantBookable: false,
      insuranceIncludedInPrice: false,
      minimumPrice: 0,
      maximumPrice: 10000,
      includedKmFrom: 0,
      includedKmTo: 5000,
      unlimitedIncludedKilometers: false,
      offRoad: false,
      festival: false,
      preferences: {
        licenseCategory: 'NotSet',
        sleepCountFrom: 0,
        sleepCountTo: 9999999,
        seatCapacityTo: 9999999,
        passengersCapacity: 0,
        berthCountFrom: 0,
        berthCountTo: 9999999,
        bedCountFrom: 0,
        bedCountTo: 9999999,
        transmissionType: ['NotSet'],
        shower: ['NotSet'],
        toilet: ['NotSet'],
        winterOperation: 'NotSet',
        vehicleType: ['NotSet'],
        kitchenSink: false,
        parkingSensors: false,
        television: false,
        fridge: false,
        isofix: false,
        heating: false,
        kitchenWear: false,
        gpsNavigation: false,
        awning: false,
        airConditioning: false,
        bicycleCarrier: ['NotSet'],
        solarPanels: false,
        towingDevice: false,
        heatedWasteTank: false,
        winterTires: false,
        snowChains: false,
      },
      vehicle: {
        yearBuildFrom: 0,
        yearBuildTo: 9999999,
        weightKgFrom: 0,
        weightKgTo: 'NotSet',
        manufacturer: null,
        payloadLimitKgFrom: 0,
        payloadLimitKgTo: 9999999,
        fuel: ['NotSet'],
      },
      policy: {
        pets: ['NotSet'],
        smoking: ['NotSet'],
      },
    },
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_SEARCH_INFO:
      return {
        ...state,
        searchInfo: action.payload,
      };
    case t.SET_SEARCH_PLACE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          place: action.payload,
        },
      };
    case t.SET_SEARCH_DATES:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          selectedDays: action.payload,
        },
      };
    case t.SET_FLEXIBLE_SEARCH_OFFSET:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          flexibleSearchOffset: action.payload,
        },
      };
    case t.SET_SHOWN_DATE_CALENDAR:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          shownDateCalendar: action.payload,
        },
      };
    case t.SET_SEARCH_ADULTS_COUNT:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          adults: action.payload,
        },
      };
    case t.SET_SEARCH_CHILDREN_COUNT:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          children: action.payload,
        },
      };

    case t.SET_SEARCH_MIN_PRICE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          minimumPrice: action.payload,
        },
      };
    case t.SET_SEARCH_MAX_PRICE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          maximumPrice: action.payload,
        },
      };
    case t.SET_SEARCH_INCLUDED_KM_FROM:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          includedKmFrom: action.payload,
        },
      };
    case t.SET_SEARCH_INCLUDED_KM_TO:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          includedKmTo: action.payload,
        },
      };
    case t.SET_SEARCH_INSTANT_BOOKABLE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          instantBookable: action.payload,
        },
      };

    case t.SET_INSURANCE_INCLUDED_IN_PRICE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          insuranceIncludedInPrice: action.payload,
        },
      };

    case t.SET_SEARCH_LICENSE_CATEGORY:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, licenseCategory: action.payload },
        },
      };
    case t.SET_SEARCH_SLEEP_COUNT_FROM:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, sleepCountFrom: action.payload },
        },
      };
    case t.SET_SEARCH_SLEEP_COUNT_TO:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, sleepCountTo: action.payload },
        },
      };
    case t.SET_PASSENGERS_CAPACITY:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, passengersCapacity: action.payload },
        },
      };
    case t.SET_SEARCH_SEAT_CAPACITY_TO:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, seatCapacityTo: action.payload },
        },
      };
    case t.SET_SEARCH_BERTH_COUNT_FROM:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, berthCountFrom: action.payload },
        },
      };
    case t.SET_SEARCH_BERTH_COUNT_TO:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, berthCountTo: action.payload },
        },
      };
    case t.SET_SEARCH_BED_COUNT_FROM:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, bedCountFrom: action.payload },
        },
      };
    case t.SET_SEARCH_BED_COUNT_TO:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, bedCountTo: action.payload },
        },
      };

    case t.SET_SEARCH_TRANSMISSION_TYPE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, transmissionType: action.payload },
        },
      };

    case t.SET_SEARCH_SHOWER:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, shower: action.payload },
        },
      };
    case t.SET_SEARCH_TOILET:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, toilet: action.payload },
        },
      };
    case t.SET_SEARCH_WINTER_OPERATION:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, winterOperation: action.payload },
        },
      };
    case t.SET_SEARCH_VEHICLE_TYPE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, vehicleType: action.payload },
        },
      };

    case t.SET_SEARCH_FRIDGE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, fridge: action.payload },
        },
      };

    case t.SET_SEARCH_ISOFIX:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, isofix: action.payload },
        },
      };

    case t.SET_SEARCH_HEATING:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, heating: action.payload },
        },
      };
    case t.SET_SEARCH_TELEVISION:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, television: action.payload },
        },
      };
    case t.SET_SEARCH_KITCHEN_SINK:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, kitchenSink: action.payload },
        },
      };
    case t.SET_SEARCH_PARKING_SENSORS:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, parkingSensors: action.payload },
        },
      };
    case t.SET_SEARCH_YEAR_BUILD_FROM:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          vehicle: { ...state.searchInfo.vehicle, yearBuildFrom: action.payload },
        },
      };
    case t.SET_SEARCH_YEAR_BUILD_TO:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          vehicle: { ...state.searchInfo.vehicle, yearBuildTo: action.payload },
        },
      };

    case t.SET_SEARCH_WEIGHT_KG_FROM:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          vehicle: { ...state.searchInfo.vehicle, weightKgFrom: action.payload },
        },
      };

    case t.SET_SEARCH_WEIGHT_KG_TO:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          vehicle: { ...state.searchInfo.vehicle, weightKgTo: action.payload },
        },
      };

    case t.SET_SEARCH_PAYLOAD_LIMIT_KG_FROM:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          vehicle: { ...state.searchInfo.vehicle, payloadLimitKgFrom: action.payload },
        },
      };
    case t.SET_SEARCH_PAYLOAD_LIMIT_KG_TO:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          vehicle: { ...state.searchInfo.vehicle, payloadLimitKgTo: action.payload },
        },
      };
    case t.SET_SEARCH_FUEL:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          vehicle: { ...state.searchInfo.vehicle, fuel: action.payload },
        },
      };
    case t.SET_SEARCH_PETS:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          policy: { ...state.searchInfo.policy, pets: action.payload },
        },
      };
    case t.SET_SEARCH_SMOKING:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          policy: { ...state.searchInfo.policy, smoking: action.payload },
        },
      };
    case t.SET_SEARCH_UNLIMITED_INCLUDED_KILOMETERS:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          unlimitedIncludedKilometers: action.payload,
        },
      };
    case t.SET_SEARCH_OFF_ROAD:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          offRoad: action.payload,
        },
      };
    case t.SET_SEARCH_FESTIVAL:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          festival: action.payload,
        },
      };
    case t.SET_SEARCH_KITCHEN_WEAR:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, kitchenWear: action.payload },
        },
      };
    case t.SET_SEARCH_GPS_NAVIGATION:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, gpsNavigation: action.payload },
        },
      };
    case t.SET_SEARCH_AWNING:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, awning: action.payload },
        },
      };
    case t.SET_SEARCH_AIR_CONDITIONING:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, airConditioning: action.payload },
        },
      };
    case t.SET_SEARCH_BICYCLE_CARRIER:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, bicycleCarrier: action.payload },
        },
      };
    case t.SET_SEARCH_SOLAR_PANELS:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, solarPanels: action.payload },
        },
      };
    case t.SET_SEARCH_TOWING_DEVICE:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, towingDevice: action.payload },
        },
      };
    case t.SET_SEARCH_MANUFACTURER:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          vehicle: { ...state.searchInfo.vehicle, manufacturer: action.payload },
        },
      };

    case t.SET_SEARCH_HEATED_WASTE_TANK:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, heatedWasteTank: action.payload },
        },
      };

    case t.SET_SEARCH_SNOW_CHAINS:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, snowChains: action.payload },
        },
      };

    case t.SET_SEARCH_WINTER_TIRES:
      return {
        ...state,
        searchInfo: {
          ...state.searchInfo,
          preferences: { ...state.searchInfo.preferences, winterTires: action.payload },
        },
      };

    default:
      return { ...state };
  }
};

export default search;
