import dayjs from 'dayjs';
import { Trans } from '@lingui/react';
import styled from 'styled-components';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React from 'react';
import { getFormattedDate, getFormattedDateAndTime, getFormattedDateWithoutYear } from 'features/utils/date';

const getFormattedDateString = (date, lang) => {
  const dateFormatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return dateFormatter.format(date);
};

const getFormattedDateUTC = (date, lang) => {
  const dateFormatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  });

  return dateFormatter.format(date);
};

const getFormattedDateWithTime = (date, lang) => {
  const dateFormatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return dateFormatter.format(date);
};

const getFormattedDates = (dates, lang) => {
  const datesArr = [];

  dates.slice(0, 3).forEach((date) => {
    datesArr.push(getFormattedDate(date, lang));
  });

  const dots = dates.length > 3 ? '...' : '';

  return datesArr.join() + dots;
};

const timeDiffCalcString = (dateFuture, dateNow) => {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = '';
  // if (days > 0) {
  //   difference += (days === 1) ? `${days} day, ` : `${days} days, `;
  // }

  if (hours === 0) {
    difference += `${hours} hodin`;
  }
  if (hours === 1) {
    difference += `${hours} hodinu`;
  }
  if (hours > 1 && hours < 5) {
    difference += `${hours} hodiny`;
  }
  if (hours > 4) {
    difference += `${hours} hodin`;
  }

  difference += ' ';
  if (minutes === 0) {
    difference += `${minutes} minut`;
  }
  if (minutes === 1) {
    difference += `${minutes} minutu`;
  }
  if (minutes > 1 && hours < 5) {
    difference += `${minutes} minuty`;
  }
  if (minutes > 4) {
    difference += `${minutes} minut`;
  }

  return difference;
};

const getTimeLeft = (start) => {
  const date = dayjs(start);
  const hours = date.diff(dayjs(), 'hour');
  const minutes = date.diff(dayjs(), 'minute') % 60;
  const days = date.diff(dayjs(), 'day');

  if (days >= 1) {
    return (
      <Trans
        id="labels.daysLeft"
        components={{
          1: days > 120 ? <span /> : <BoldRed />,
          0: <>{days}</>,
        }}
        message="<1><0></0></1> days"
      />
    );
  }

  if (hours > 1 && hours < 24) {
    return (
      <Trans
        id="labels.hoursLeft"
        components={{
          1: <BoldRed />,
          0: <>{hours}</>,
        }}
        message="<1><0></0> hours</1>"
      />
    );
  }

  return (
    <Trans
      id="labels.minutesLeft"
      components={{
        0: <b />,
        1: <>{minutes}</>,
      }}
      message="<1><0></0> minutes</1>"
    />
  );
};
const BoldRed = styled.b`
  color: var(--color-error-red);
`;

const checkBirthDate = (date) => {
  const convertedDate = dayjs(date, 'DD/MM/YYYY', true);
  const earliestPossibleDate = dayjs('01/01/1900', 'DD/MM/YYYY', true);

  const today = dayjs();

  if (today.isBefore(convertedDate)) {
    return i18n._(t({ id: 'labels.eror.dateInFuture' }));
  }
  if (convertedDate.isAfter(dayjs().subtract(18, 'year'))) {
    return i18n._(t({ id: 'labels.eror.younger18' }));
  }
  if (convertedDate.isBefore(earliestPossibleDate)) {
    return i18n._(t({ id: 'labels.eror.birthDateAfter1900' }));
  }
  if (convertedDate.isValid() === false) {
    return i18n._(t({ id: 'labels.eror.invalidDate' }));
  }
  return null;
};

const getDateRange = (start: string, end: string) => {
  let rangeStart = '';
  if (dayjs(start).month() === dayjs(end).month() && dayjs(start).year() === dayjs(end).year()) {
    rangeStart = dayjs(start).format('D.');
  } else if (dayjs(start).year() === dayjs(end).year()) {
    rangeStart = dayjs(start).format('D. M.');
  } else {
    rangeStart = dayjs(start).format('D. M. YY');
  }
  return `${rangeStart} - ${dayjs(end).format('DD. M. YYYY')}`;
};

export {
  getTimeLeft,
  checkBirthDate,
  getDateRange,
  getFormattedDateUTC,
  getFormattedDateWithTime,
  getFormattedDate,
  getFormattedDateWithoutYear,
  getFormattedDates,
  getFormattedDateString,
  getFormattedDateAndTime,
  timeDiffCalcString,
};
