/**
 * @description
 * alternative function to isEqual from lodash library.
 * Function is early exiting for objects that are with same reference, thus it is safe to use in scenarios:
 * props comparison in React, reselect deepCompareFn - in selector that returns objects
 * */
export function deepCompare<T>(value1: T, value2: T) {
  // reference check - primitive check - non initialized variables check null === null and undefined === undefined
  if (value1 === value2) return true;

  // early exit for performance reasons
  const value1Type = typeof value1;
  const value2Type = typeof value2;
  if (value1Type !== 'object' || value2Type !== 'object') return false;
  if (value1 == null || value2 == null) return false;

  const keysA = Object.keys(value1);
  const keysB = Object.keys(value2);

  // early exit for performance reason -> don't iterate if the object is different
  if (keysA.length !== keysB.length) return false;

  // iterate through key to compare them

  for (const keyInA of keysA) {
    // note: includes in array type is more performant than obj[val] in this approach, and faster than set.has(val) if the array is small
    // consider fact that arr.indexOf(obj) not working in JS but arr.includes(obj) works, hence there is includes instead more performant indexOf
    if (!keysB.includes(keyInA)) return false;

    // this is the last check before recursion is called
    const currentKey1Value = value1[keyInA as keyof T];
    const currentKey2Value = value2[keyInA as keyof T];
    if (typeof currentKey1Value === 'function' || typeof currentKey2Value === 'function') {
      // do not use strict !== operator in parsing fn body -> it will never compare properly if you wil do that
      // eslint-disable-next-line eqeqeq
      if ((currentKey1Value as any).toString() != (currentKey2Value as any).toString()) return false;
    } else if (!deepCompare(currentKey1Value, currentKey2Value)) return false;
  }

  return true;
}
