import { INBOX_TABS } from '~/src/components/messages/utils';
import { ScreenNames } from '../reducers/inbox';
import * as t from '../types';

export const setInboxMessagesCount = (count: number) => dispatch => {
  dispatch({
    type: t.SET_INBOX_MESSAGES_COUNT,
    payload: count,
  });
};

export const setVisibleScreenOnMobile = (screen: ScreenNames) => dispatch => {
  dispatch({
    type: t.SET_INBOX_VISIBLE_SCREEN,
    payload: screen,
  });
};

export const setMessagingEnabled = (enabled: boolean) => dispatch => {
  dispatch({
    type: t.SET_INBOX_MESSAGING_ENABLED,
    payload: enabled,
  });
};

export const setActiveInboxTab = (inboxTab: INBOX_TABS) => dispatch => {
  dispatch({
    type: t.SET_ACTIVE_INBOX_TAB,
    payload: inboxTab,
  });
};
