export const SET_SEARCH_INFO = 'SET_SEARCH_INFO';
export const SET_SEARCH_PLACE = 'SET_SEARCH_PLACE';
export const SET_SEARCH_DATES = 'SET_SEARCH_DATES';
export const SET_FLEXIBLE_SEARCH_OFFSET = 'SET_FLEXIBLE_SEARCH_OFFSET';
export const SET_SHOWN_DATE_CALENDAR = 'SET_SHOWN_DATE_CALENDAR';
export const SET_SEARCH_ADULTS_COUNT = 'SET_SEARCH_ADULTS_COUNT';
export const SET_SEARCH_CHILDREN_COUNT = 'SET_SEARCH_CHILDREN_COUNT';
export const SET_SEARCH_MIN_PRICE = 'SET_SEARCH_MIN_PRICE';
export const SET_SEARCH_MAX_PRICE = 'SET_SEARCH_MAX_PRICE';
export const SET_SEARCH_INCLUDED_KM_FROM = 'SET_SEARCH_INCLUDED_KM_FROM';
export const SET_SEARCH_INCLUDED_KM_TO = 'SET_SEARCH_INCLUDED_KM_TO';
export const SET_SEARCH_INSTANT_BOOKABLE = 'SET_SEARCH_INSTANT_BOOKABLE';
export const SET_INSURANCE_INCLUDED_IN_PRICE = 'SET_INSURANCE_INCLUDED_IN_PRICE';
export const SET_SEARCH_LICENSE_CATEGORY = 'SET_SEARCH_LICENSE_CATEGORY';
export const SET_SEARCH_SLEEP_COUNT_FROM = 'SET_SEARCH_SLEEP_COUNT_FROM';
export const SET_SEARCH_SLEEP_COUNT_TO = 'SET_SEARCH_SLEEP_COUNT_TO';
export const SET_PASSENGERS_CAPACITY = 'SET_PASSENGERS_CAPACITY';
export const SET_SEARCH_SEAT_CAPACITY_TO = 'SET_SEARCH_SEAT_CAPACITY_TO';
export const SET_SEARCH_BED_COUNT_FROM = 'SET_SEARCH_BED_COUNT_FROM';
export const SET_SEARCH_BED_COUNT_TO = 'SET_SEARCH_BED_COUNT_TO';
export const SET_SEARCH_BERTH_COUNT_FROM = 'SET_SEARCH_BERTH_COUNT_FROM';
export const SET_SEARCH_BERTH_COUNT_TO = 'SET_SEARCH_BERTH_COUNT_TO';

export const SET_SEARCH_TRANSMISSION_TYPE = 'SET_SEARCH_TRANSMISSION_TYPE';
export const SET_SEARCH_SHOWER = 'SET_SEARCH_SHOWER';
export const SET_SEARCH_TOILET = 'SET_SEARCH_TOILET';
export const SET_SEARCH_WINTER_OPERATION = 'SET_SEARCH_WINTER_OPERATION';
export const SET_SEARCH_VEHICLE_TYPE = 'SET_SEARCH_VEHICLE_TYPE';

export const SET_SEARCH_FRIDGE = 'SET_SEARCH_FRIDGE';
export const SET_SEARCH_ISOFIX = 'SET_SEARCH_ISOFIX';
export const SET_SEARCH_HEATING = 'SET_SEARCH_HEATING';

export const SET_SEARCH_TELEVISION = 'SET_SEARCH_TELEVISION';
export const SET_SEARCH_KITCHEN_SINK = 'SET_SEARCH_KITCHEN_SINK';
export const SET_SEARCH_PARKING_SENSORS = 'SET_SEARCH_PARKING_SENSORS';

export const SET_SEARCH_YEAR_BUILD_FROM = 'SET_SEARCH_YEAR_BUILD_FROM';
export const SET_SEARCH_YEAR_BUILD_TO = 'SET_SEARCH_YEAR_BUILD_TO';
export const SET_SEARCH_WEIGHT_KG_FROM = 'SET_SEARCH_WEIGHT_KG_FROM';
export const SET_SEARCH_WEIGHT_KG_TO = 'SET_SEARCH_WEIGHT_KG_TO';

export const SET_SEARCH_PAYLOAD_LIMIT_KG_FROM = 'SET_SEARCH_PAYLOAD_LIMIT_KG_FROM';
export const SET_SEARCH_PAYLOAD_LIMIT_KG_TO = 'SET_SEARCH_PAYLOAD_LIMIT_KG_TO';
export const SET_SEARCH_FUEL = 'SET_SEARCH_FUEL';
export const SET_SEARCH_PETS = 'SET_SEARCH_PETS';
export const SET_SEARCH_SMOKING = 'SET_SEARCH_SMOKING';

export const SET_SEARCH_UNLIMITED_INCLUDED_KILOMETERS = 'SET_SEARCH_UNLIMITED_INCLUDED_KILOMETERS';
export const SET_SEARCH_OFF_ROAD = 'SET_SEARCH_OFF_ROAD';
export const SET_SEARCH_FESTIVAL = 'SET_SEARCH_FESTIVAL';
export const SET_SEARCH_KITCHEN_WEAR = 'SET_SEARCH_KITCHEN_WEAR';
export const SET_SEARCH_GPS_NAVIGATION = 'SET_SEARCH_GPS_NAVIGATION';
export const SET_SEARCH_AWNING = 'SET_SEARCH_AWNING';
export const SET_SEARCH_AIR_CONDITIONING = 'SET_SEARCH_AIR_CONDITIONING';
export const SET_SEARCH_BICYCLE_CARRIER = 'SET_SEARCH_BICYCLE_CARRIER';
export const SET_SEARCH_SOLAR_PANELS = 'SET_SEARCH_SOLAR_PANELS';
export const SET_SEARCH_TOWING_DEVICE = 'SET_SEARCH_TOWING_DEVICE';
export const SET_SEARCH_MANUFACTURER = 'SET_SEARCH_MANUFACTURER';

export const SET_SEARCH_HEATED_WASTE_TANK = 'SET_SEARCH_HEATED_WASTE_TANK';
export const SET_SEARCH_WINTER_TIRES = 'SET_SEARCH_WINTER_TIRES';
export const SET_SEARCH_SNOW_CHAINS = 'SET_SEARCH_SNOW_CHAINS';
