import { LinkProps } from 'next/dist/client/link';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { compile } from 'path-to-regexp';
import Link from 'next/link';
import { stringify } from 'query-string';
import isObject from 'lodash/isObject';
import { useCodebase } from '../hooks/useCodebase';
import { LocalizedLinkProps } from './types';
import { Language } from '../utils/routing';

export default function LocalizedLink({
  children,
  href,
  linkParams,
  hash,
  newWindow = false,
  isLegacy = false,
  forceLegacyFlag = false,
  query,
  disabled,
  ...props
}: Omit<LinkProps, 'href'> & LocalizedLinkProps) {
  const settings = useSelector((state: any) => state.settings);

  const isFWC = useCodebase('fwc');

  const token = `/${settings.language}-${settings.country}`;
  const pathToComileFirst = href.urls[settings.language as Language];
  const toPath =
    pathToComileFirst !== undefined ? compile(pathToComileFirst, { encode: encodeURIComponent }) : () => '404';

  const [link, setLink] = useState(`${token}${toPath(linkParams)}`);

  useEffect(() => {
    const pathToCompile = href.urls[settings.language as Language];
    const toPath2 =
      pathToCompile !== undefined ? compile(pathToCompile, { encode: encodeURIComponent }) : () => '__404';
    setLink(`${token}${toPath2(linkParams)}`);
  }, [settings, linkParams]);

  const queryParams = useMemo(() => {
    if (isObject(query)) {
      const keys = Object.keys(query).length;
      return keys > 0 ? `?${stringify(query)}` : '';
    }

    return query ? `?${query}` : '';
  }, [query]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (disabled) return <>{children}</>;

  if (link.includes('__404')) return <Link href={`${token}/`}>{children}</Link>;

  let finalChild = children;
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  if (newWindow && !isFWC) finalChild = <a target={newWindow ? '_blank' : ''}>{children}</a>;

  if (newWindow && isFWC)
    return (
      <a href={`${link}${queryParams}`} onClick={props.onClick} target="_blank" rel="noreferrer">
        {children}
      </a>
    );

  return (
    <Link
      legacyBehavior={forceLegacyFlag ? isLegacy : !isFWC || isLegacy}
      href={{
        pathname: link,
        hash: hash?.replace('#', ''),
        query,
      }}
      {...props}
    >
      {finalChild}
    </Link>
  );
}
