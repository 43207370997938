export const SET_FETCHED_CHECKOUT_DATA = 'SET_FETCHED_CHECKOUT_DATA';
export const SET_FETCHED_RESERVATION_DATA_CHECKOUT = 'SET_FETCHED_RESERVATION_DATA_CHECKOUT';

export const SET_CHECKOUT_LOADING_DATA = 'SET_CHECKOUT_LOADING_DATA';
export const SET_CHECKOUT_STEP = 'SET_CHECKOUT_STEP';
export const SET_CHECKOUT_GUID = 'SET_CHECKOUT_GUID';
export const SET_CHECKOUT_ERROR = 'SET_CHECKOUT_ERROR';

export const UPDATE_UPLOADED_IMAGES_CHECKOUT = 'UPDATE_UPLOADED_IMAGES_CHECKOUT';
export const DELETE_UPLOADED_IMAGES_CHECKOUT = 'DELETE_UPLOADED_IMAGES_CHECKOUT';

export const UPDATE_UPLOADED_VEHICLE_DAMAGES_CHECKOUT = 'UPDATE_UPLOADED_VEHICLE_DAMAGES_CHECKOUT';
export const DELETE_UPLOADED_VEHICLE_DAMAGES_CHECKOUT = 'DELETE_UPLOADED_VEHICLE_DAMAGES_CHECKOUT';
export const UPDATE_UPLOADED_VEHICLE_DAMAGE_PICTURES_CHECKOUT = 'UPDATE_UPLOADED_VEHICLE_DAMAGE_PICTURES_CHECKOUT';
export const DELETE_UPLOADED_VEHICLE_DAMAGE_PICTURE_CHECKOUT = 'DELETE_UPLOADED_VEHICLE_DAMAGE_PICTURE_CHECKOUT';

export const SET_VALIDATION_CAMPERVAN_CHECKOUT = 'SET_VALIDATION_CAMPERVAN_CHECKOUT';
export const SET_VALIDATION_DAMAGES_CHECKOUT = 'SET_VALIDATION_DAMAGES_CHECKOUT';
export const SET_VALIDATION_HANDOVER_CHECKOUT = 'SET_VALIDATION_HANDOVER_CHECKOUT';

export const UPDATE_FEE_CHECKOUT = 'UPDATE_FEE_CHECKOUT';
export const DELETE_FEE_CHECKOUT = 'DELETE_FEE_CHECKOUT';
