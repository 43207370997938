import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import { mediaSmMax } from '../../utils/breakpoints';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';

/** https://stackoverflow.com/a/30280636 */
function isDaylightSavingsTime(d: Date) {
  const offsetJan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const offsetJul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(offsetJan, offsetJul) !== d.getTimezoneOffset();
}

export function getBookingMinStartDate(isFWC: boolean) {
  // get today's date at 0:00
  const date = new Date(new Date().toDateString());

  // campiri case
  if (!isFWC) return date;

  // check if daylight savings time applies
  const offsetDST = isDaylightSavingsTime(date) ? 60 : 0;
  // convert to CET by adding one hour to UTC
  const offsetCET = date.getTimezoneOffset() + 60 + offsetDST;
  date.setHours(17 + offsetCET);
  const isBefore5CET = dayjs().add(offsetCET, 'minute').isBefore(dayjs(date));

  return dayjs()
    .startOf('day')
    .add(isBefore5CET ? 1 : 2, 'day')
    .toDate();
}

export const SearchPlaceholder = styled.div`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: var(--color-dark-blue);

  ${mediaSmMax} {
    font-size: 12px;
  }

  ${resolveInCodebase(
    'fwc',
    css`
      font-size: 14px;
      color: ${resolveThemeValue(tokens.color.text.textPrimary)};
    `
  )}
`;

export const ValueCleaner = styled.div`
  position: absolute;
  right: 16px;
  top: 24px;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-error-red);
  text-decoration: underline;
`;

export const SearchInputValue = styled.div`
  margin-top: 8px;
  font-family: var(--font-poppins);
  font-size: 20px;
  line-height: 1;
  color: var(--color-dark-grey);

  ${mediaSmMax} {
    font-size: 16px;
  }

  ${resolveInCodebase(
    'fwc',
    css`
      font-size: 16px;
      color: ${resolveThemeValue(tokens.color.text.textSecondary)};
    `
  )}

  &.hasValue {
    color: ${resolveThemeValue(tokens.color.text.textPrimary, 'var(--color-dark-blue)')};
  }
`;
