import {
  cultureRedirects,
  cultureTokens,
  routerDefinitions,
  supportedTokens,
} from 'features/routing/routes/routerDefinitions';
import { Country, Currency, Language } from 'features/utils/routing';
import { RouteType } from 'features/routing/types';

export const getCultureTokensToLangCode = (fullToken: string) => {
  if (fullToken.startsWith('cs-')) return 'cs';
  if (fullToken.startsWith('en-')) return 'en';
  if (fullToken.startsWith('sk-')) return 'sk';
  if (fullToken.startsWith('pl-')) return 'pl';
  if (fullToken.startsWith('es-')) return 'es';
  if (fullToken.startsWith('de-')) return 'de';
  if (fullToken.startsWith('it-')) return 'it';

  return undefined;
};

const isFreeWay = process.env.NEXT_PUBLIC_APP_TYPE === 'fwc';

export const cultureToken = isFreeWay ? cultureTokens['de-de'] : cultureTokens['cs-cz'];

const routes = routerDefinitions;

export { cultureRedirects, cultureTokens, routes, supportedTokens };

export const cultureTokenMap = {
  cs: cultureTokens['cs-cz'],
  sk: cultureTokens['sk-sk'],
  pl: cultureTokens['pl-pl'],
  es: cultureTokens['es-es'],
  de: cultureTokens['de-de'],
  it: cultureTokens['it-it'],
};

export { Currency, Country, Language };

export type { RouteType };

export const pages = {
  add_caravan: '/add-caravan',
  search: '/search',
  mission: '/mission',
  test: '/test',
  faq: '/faq',
  tos: '/tos',
  privacy: '/privacy',
  landingSeptember: '/landing/september',
  protiproudu: '/landing/protiproudu',
  password_recovery: '/password-recovery',
  create_offer: '/offers',
};

export const getAsPathForCampervan = (id: any, slug: any, lang: any) => {
  if (lang === Language.cs) {
    return `/${slug}--karavan-${id}`;
  }
  if (lang === Language.pl) {
    return `/${slug}--samochod-${id}`;
  }
  if (lang === Language.en) {
    return `/${slug}--campervan-${id}`;
  }
  if (lang === Language.sk) {
    return `/${slug}--karavan-${id}`;
  }
  if (lang === Language.es) {
    return `/${slug}--autocaravana-${id}`;
  }
  if (lang === Language.de) {
    return `/${slug}--campervan-${id}`;
  }
  if (lang === Language.it) {
    return `/${slug}--campervan-${id}`;
  }
  return '';
};

export const getAsPathForBlogArticle = (id: any, slug: any, lang: any) => {
  if (lang === Language.cs) {
    return `/${slug}--clanek-${id}`;
  }
  if (lang === Language.pl) {
    return `/${slug}--artykul-${id}`;
  }
  if (lang === Language.en) {
    return `/${slug}--article-${id}`;
  }
  if (lang === Language.sk) {
    return `/${slug}--clanok-${id}`;
  }
  if (lang === Language.es) {
    return `/${slug}--articulo-${id}`;
  }
  if (lang === Language.de) {
    return `/${slug}--artikel-${id}`;
  }
  if (lang === Language.it) {
    return `/${slug}--articolo-${id}`;
  }
  return '';
};
