/* eslint-disable */
import * as t from '../types';

export interface UploadedImageInterface {
  id: any;
  description?: string;
  file: any;
  preview?: any;
}

export interface DamagePictureModel {
  id: any;
  preview: any;
  file: any;
}

export interface ValidationI {
  customer: boolean;
  campervan: boolean;
  handoff: boolean;
}

export interface UploadedVehicleDamage {
  id: any;
  name?: string;
  description?: string;
  location?: string;
  isTerminal?: boolean;
  pictures: Array<DamagePictureModel>;
}

export interface CheckInReduxInterface {
  checkInGuid?: string | null;
  checkInError?: string | null;
  step: any | null;
  fetchedCheckInData: any;
  fetchedReservation: any;
  checkInLoading: boolean;
  selectedCampervan: any | null;
  uploadedImage?: Array<UploadedImageInterface>;
  uploadedVehicleDamages: Array<UploadedVehicleDamage>;
  validation: ValidationI;
}

const checkin = (
  // eslint-disable-next-line default-param-last
  state: CheckInReduxInterface = {
    checkInError: null,
    checkInGuid: null,
    fetchedCheckInData: null,
    fetchedReservation: null,
    checkInLoading: true,
    step: null,
    selectedCampervan: null,
    uploadedImage: [],
    uploadedVehicleDamages: [],
    validation: {
      customer: false,
      campervan: false,
      handoff: false,
    },
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_FETCHED_RESERVATION_DATA:
      return {
        ...state,
        fetchedReservation: action.payload,
      };

    case t.SET_FETCHED_CHECKIN_DATA:
      return {
        ...state,
        fetchedCheckInData: action.payload,
      };

    case t.SET_CHECKIN_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case t.SET_CHECKIN_ERROR:
      return {
        ...state,
        checkInError: action.payload,
      };

    case t.SET_CHECKIN_GUID:
      return {
        ...state,
        checkInGuid: action.payload,
      };

    case t.SET_CHECKIN_LOADING_DATA:
      return {
        ...state,
        checkInLoading: action.payload,
      };

    case t.SET_SELECTED_CAMPERVAN:
      return {
        ...state,
        selectedCampervan: action.payload,
      };

    case t.SET_VALIDATION_CAMPERVAN:
      return {
        ...state,
        validation: { ...state.validation, campervan: action.payload },
      };

    case t.SET_VALIDATION_CUSTOMER:
      return {
        ...state,
        validation: { ...state.validation, campervan: action.payload },
      };

    case t.SET_VALIDATION_HANDOVER:
      return {
        ...state,
        validation: { ...state.validation, handoff: action.payload },
      };

    case t.UPDATE_UPLOADED_IMAGES_CHECKIN:
      const uploadedImagesWithoutChanged = state.uploadedImage!.filter((item) => action.payload.id !== item.id);
      const res = [...uploadedImagesWithoutChanged, action.payload];
      return {
        ...state,
        uploadedImage: res,
      };

    case t.DELETE_UPLOADED_IMAGES_CHECKING:
      const uploadedImagesWithoutDeleted = state.uploadedImage!.filter((item) => action.payload !== item.id);
      return {
        ...state,
        uploadedImage: uploadedImagesWithoutDeleted,
      };

    case t.UPDATE_UPLOADED_VEHICLE_DAMAGES:
      const uploadedDamagesWithoutChanged = state.uploadedVehicleDamages.filter(
        (item) => action.payload.id !== item.id
      );
      const uploadedVehicleDamagesRes = [...uploadedDamagesWithoutChanged, action.payload];
      return {
        ...state,
        uploadedVehicleDamages: uploadedVehicleDamagesRes,
      };

    case t.UPDATE_UPLOADED_VEHICLE_DAMAGE_PICTURES:
      const currentDamage = state.uploadedVehicleDamages.filter((item) => action.payload.damageID === item.id);
      return {
        ...state,
        uploadedVehicleDamages: { ...currentDamage, pictures: action.payload },
      };

    case t.DELETE_UPLOADED_VEHICLE_DAMAGES:
      const uploadedDamagesWithoutDeleted = state.uploadedVehicleDamages.filter(
        (item) => action.payload.id !== item.id
      );
      return {
        ...state,
        uploadedVehicleDamages: uploadedDamagesWithoutDeleted,
      };

    case t.DELETE_UPLOADED_VEHICLE_DAMAGE_PICTURE:
      const uploadedDamagesWithoutChangedDamage = state.uploadedVehicleDamages.filter(
        (item) => action.payload !== item.id
      );
      const changedDamage = state.uploadedVehicleDamages.find((item) => action.payload === item.id);
      const picturesWithoutDeleted = changedDamage!.pictures.filter((pic) => pic.id !== action.payload.pictureId);

      return {
        ...state,
        uploadedVehicleDamages: [
          ...uploadedDamagesWithoutChangedDamage,
          { ...changedDamage, pictures: picturesWithoutDeleted },
        ],
      };

    default:
      return { ...state };
  }
};

export default checkin;
