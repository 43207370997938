import { HYDRATE } from 'next-redux-wrapper';
import * as t from '../types';

const carDetail = (
  // eslint-disable-next-line default-param-last
  state = {
    car: null,
    calendarDays: {
      disabledDates: [],
      daysWithPrices: [],
      loading: false,
      ready: false,
      firstAvailableDay: null,
      dropOffOnlyDays: [],
      pickupOnlyDays: [],
      oneDayAvailableDays: [],
    },
  },
  action: any
) => {
  switch (action.type) {
    case HYDRATE:
      return {
        car: action.payload.carDetail.car,
        calendarDays: {
          disabledDates: [],
          daysWithPrices: [],
          dropOffOnlyDays: [],
          pickupOnlyDays: [],
          oneDayAvailableDays: [],
          loading: false,
          ready: false,
          firstAvailableDay: null,
        },
      };
    case t.SET_CAR_DETAIL_DATA:
      return {
        ...state,
        car: action.payload,
      };
    case t.SET_CAR_UNAVAILABILITY:
      return {
        ...state,
        calendarDays: {
          ...state.calendarDays,
          disabledDates: action.payload,
        },
      };
    case t.SET_CAR_FIRST_AVAILABALE_DAY:
      return {
        ...state,
        calendarDays: {
          ...state.calendarDays,
          firstAvailableDay: action.payload,
        },
      };

    case t.SET_CAR_CALENDAR_DAYS:
      return {
        ...state,
        calendarDays: {
          ...state.calendarDays,
          daysWithPrices: action.payload,
        },
      };
    case t.SET_CAR_UNAVAILABILITY_ONE_DAY:
      return {
        ...state,
        calendarDays: {
          ...state.calendarDays,
          oneDayAvailableDays: action.payload,
        },
      };
    case t.SET_CAR_UNAVAILABILITY_PICKUP_ONLY:
      return {
        ...state,
        calendarDays: {
          ...state.calendarDays,
          pickupOnlyDays: action.payload,
        },
      };
    case t.SET_CAR_UNAVAILABILITY_DROP_OFF_ONLY:
      return {
        ...state,
        calendarDays: {
          ...state.calendarDays,
          dropOffOnlyDays: action.payload,
        },
      };
    case t.SET_CAR_UNAVAILABILITY_LOADING:
      return {
        ...state,
        calendarDays: {
          ...state.calendarDays,
          loading: action.payload,
        },
      };
    case t.SET_CAR_UNAVAILABILITY_READY:
      return {
        ...state,
        calendarDays: {
          ...state.calendarDays,
          ready: action.payload,
        },
      };
    case t.GET_CAR_DATA:
      return {
        ...state,
        car: action.payload,
      };
    default:
      return { ...state };
  }
};

export default carDetail;
