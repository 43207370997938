import dayjs from 'dayjs';
import getConfig from 'next/config';
import { IS_CLIENT } from '~/src/utils/app';
import { getCookie } from '~/src/utils/cookies';
import { cultureToken, cultureTokens } from '~/src/utils/routing';
import { CurrencyCode } from '~/libs/api/models/currency-code';
import {
  getIconFromLocationSearchType,
  getSearchResultType,
  proximityCenter,
} from 'ui/components/searchBar/utils/utils';
import Router, { useRouter } from 'next/router';
import { goToSearchPageWithResetFilters } from '~/src/utils/query';
import { makeQueryForSearchFromHP } from 'features/utils/search/utils';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { getBookingMinStartDate } from 'ui/components/searchBar/shared';
import { isFWC } from '~/src/utils/appType';

const defaultRegionGeolocations = {
  cz: { latitude: '50.0598054', longitude: '14.3251987' },
  sk: { latitude: '48.135924', longitude: '16.9754916' },
  pl: { latitude: '52.2330649', longitude: '20.9207691' },
  gr: { latitude: '37.9908996', longitude: '23.7032341' },
  de: { latitude: '52.50697', longitude: '13.2843068' },
  at: { latitude: '48.2207779', longitude: '16.3098488' },
  es: { latitude: '40.416729', longitude: '-3.703339' },
  it: { latitude: '45.3994742', longitude: '9.2681537' },
};

const DEFAULT_LOCATION_NAME = {
  cz: 'Prague',
  it: 'Milan',
  de: 'Berlin',
} as const;

export enum Filters {
  'PRICE_RANGE' = 'PriceRange',
  'PASSENGERS' = 'Passengers',
  'CAR_TYPE' = 'Type',
  'CAR_EQUIPMENT' = 'Equipment',
  'CAR_INFORMATION' = 'Information',
  'RULES' = 'Rules',
  'CAR_CONDITION' = 'Condition',
  'INSTANT_BOOKABLE' = 'InstantBookable',
  'PLACE' = 'Place',
  'DATE' = 'Date',
}

export const customClearSearchSideEffect = () => {
  if (Router.route === '/search') {
    const currentQuery = Router.query;
    delete currentQuery.latitude;
    delete currentQuery.longitude;
    goToSearchPageWithResetFilters(currentQuery);
  }
  if (Router.route.includes('landing')) {
    const currentQuery = Router.query;
    delete currentQuery.latitude;
    delete currentQuery.longitude;
    goToSearchPageWithResetFilters(currentQuery);
  }
};

const getDefaultGeolocations = (region: string) => {
  if (defaultRegionGeolocations[region] !== undefined) {
    return defaultRegionGeolocations[region];
  }
  return defaultRegionGeolocations.cz;
};

const makeQueryForCalendarUpdate = (selectedDays) => ({
  ...(selectedDays &&
    selectedDays.startDate && {
      dateFrom: dayjs(selectedDays.startDate).format('YYYY-MM-DD'),
    }),
  ...(selectedDays && selectedDays.endDate && { dateTo: dayjs(selectedDays.endDate).format('YYYY-MM-DD') }),
});

function useDefaultLocationName() {
  const { country } = useTypedSelector(settingsSelector);

  if (typeof country !== 'string') return DEFAULT_LOCATION_NAME.de;

  return DEFAULT_LOCATION_NAME[country.toLowerCase()] ?? DEFAULT_LOCATION_NAME.de;
}

function isValidBookingDate(dateFrom: Date, dateTo: Date) {
  const minDate = dayjs(getBookingMinStartDate(isFWC));
  const dateFromObj = dayjs(dateFrom);
  return (minDate.isBefore(dateFromObj) || minDate.isSame(dateFromObj)) && dateFromObj.isBefore(dayjs(dateTo));
}

const parseParamsForSearchQuery = (
  query,
  pageNumber: number,
  pageSize: number,
  isFirstFetch = false,
  isLandingPage = false,
  isWinterLP = false,
  dynamicSegment = false
) => {
  const {
    dateFrom,
    dateTo,
    flexibleDate,
    licenseCategory,
    transmissionType,
    vehicleType,
    includedKmTo,
    includedKmFrom,
    fuel,
    weightKgTo,
    minimumPrice,
    maximumPrice,
    pets,
    smoking,
    kitchenSink,
    parkingSensors,
    television,
    fridge,
    isofix,
    heating,
    shower,
    instantBookable,
    yearBuildFrom,
    yearBuildTo,
    airConditioning,
    towingDevice,
    solarPanels,
    electricityGenerator,
    gpsNavigation,
    toilet,
    bicycleCarrier,
    festival,
    offRoad,
    unlimitedIncludedKilometers,
    manufacturer,
    manufacturers,
    sleepCountFrom,
    passengersCapacity,
    searchQuery,
    fleetCode,
    kitchenWear,
    insuranceIncludedInPrice,
    snowChains,
    heatedWasteTank,
    winterTires,
    campiriFleet,
    placeName,
    latitude,
    longitude,
    selectedListings,
    maxDistance,
    ignoreMaxDistance,
  } = query;

  const finalWinterTires = isWinterLP ? true : winterTires;

  // Page size  logic
  let currentPageSize = pageSize;
  if (isFirstFetch) {
    currentPageSize = pageSize - 1;
  }
  //

  // Terms length logic
  const fixedTermsLengthForLP = 4;
  let termLengthFromQuery = 0;

  const hasDateInQuery = query.dateFrom && query.dateTo;

  if (hasDateInQuery) {
    const start = dayjs(query.dateFrom);
    const end = dayjs(query.dateTo);
    const diff = end.diff(start, 'days') + 1;
    termLengthFromQuery = diff;
  }
  //

  const { publicRuntimeConfig } = getConfig();
  let pageOffset = 0;

  if (pageNumber === 2) {
    pageOffset = 11;
  } else if (pageNumber > 2) {
    pageOffset = (pageNumber - 2) * 12 + 11;
  }

  if (dynamicSegment) {
    if (pageNumber === 1) {
      pageOffset = 0;
    } else {
      pageOffset = (pageNumber - 1) * 9;
    }
  }

  let defaultGeolocations = null;

  if (latitude === undefined && longitude === undefined) {
    let CULTURE_TOKEN = '';
    if (query.cultureToken) {
      CULTURE_TOKEN = query.cultureToken;
    } else if (typeof window !== 'undefined') {
      CULTURE_TOKEN = cultureTokens[window.location.pathname.slice(1, 6)] || cultureToken;
    }
    const region = CULTURE_TOKEN.substring(CULTURE_TOKEN.indexOf('-') + 1);
    defaultGeolocations = getDefaultGeolocations(region);
  }

  const analyticCookies = IS_CLIENT ? getCookie('analyticCookies') : 'false';
  const clientId =
    analyticCookies === 'true' && window?.ga?.getAll !== undefined && typeof window?.ga?.getAll === 'function'
      ? window?.ga?.getAll()[0]?.get('clientId') || null
      : null;

  return {
    pageSize: currentPageSize,
    // pageNumber,
    dateOffset: Number(flexibleDate ?? 0),
    pageOffset,
    latitude: defaultGeolocations ? defaultGeolocations.latitude : latitude,
    longitude: defaultGeolocations ? defaultGeolocations.longitude : longitude,
    maxDistance: ignoreMaxDistance ? null : maxDistance ?? 1000,
    ...(placeName && { locationName: placeName }),
    cId: clientId,
    ...(dateFrom && { from: dayjs(query.dateFrom).format('YYYY-MM-DD') }),
    ...(dateTo && { until: dayjs(query.dateTo).format('YYYY-MM-DD') }),
    ...(instantBookable && { instantBookable: instantBookable === 'true' }),

    ...((hasDateInQuery || !isLandingPage) && termLengthFromQuery > 0 && { termLength: termLengthFromQuery }),
    ...(isLandingPage && { termLength: Math.max(termLengthFromQuery, fixedTermsLengthForLP) }),

    // Listing
    ...((minimumPrice ||
      maximumPrice ||
      includedKmFrom ||
      includedKmTo ||
      yearBuildFrom ||
      yearBuildTo ||
      unlimitedIncludedKilometers ||
      insuranceIncludedInPrice ||
      searchQuery ||
      fleetCode ||
      selectedListings ||
      campiriFleet) && {
      listing: {
        ...(minimumPrice && {
          minimumPrice: {
            amount: parseInt(minimumPrice),
            currency: CurrencyCode.NotSet,
          },
        }),
        ...(maximumPrice && {
          maximumPrice: {
            amount: parseInt(maximumPrice),
            currency: CurrencyCode.NotSet,
          },
        }),

        // BoomCampers filter
        ...(String(campiriFleet) === 'true' && { operatorGuid: '01788e91-d1dc-4cf6-b77e-a72e1302c65c' }),

        ...((includedKmFrom || includedKmTo) && {
          includedKm: {
            from: parseInt(String(includedKmFrom)),
            to: parseInt(String(includedKmTo)),
            fromInclusive: true,
            toInclusive: true,
          },
        }),

        ...((yearBuildFrom || yearBuildTo) && {
          yearBuilt: {
            from: parseInt(String(yearBuildFrom)),
            to: parseInt(String(yearBuildTo)),
            fromInclusive: true,
            toInclusive: true,
          },
        }),

        ...(String(unlimitedIncludedKilometers) === 'true' && { unlimitedIncludedKm: true }),
        ...(String(insuranceIncludedInPrice) === 'true' && { insuranceIncluded: true }),

        ...(searchQuery && { search: searchQuery }),
        // ...(fleetCode && { fleetCode }),

        ...(selectedListings && {
          guids: JSON.parse(selectedListings),
        }),
      },
    }),

    // Preferences
    ...((licenseCategory ||
      transmissionType ||
      sleepCountFrom ||
      passengersCapacity ||
      kitchenSink ||
      parkingSensors ||
      television ||
      fridge ||
      isofix ||
      heating ||
      shower ||
      toilet ||
      airConditioning ||
      towingDevice ||
      solarPanels ||
      electricityGenerator ||
      gpsNavigation ||
      bicycleCarrier ||
      vehicleType ||
      kitchenWear ||
      snowChains ||
      heatedWasteTank ||
      finalWinterTires) && {
      preferences: {
        ...(vehicleType && {
          vehicleType: JSON.parse(vehicleType),
        }),

        ...(licenseCategory && { licenseCategory }),
        ...(transmissionType && { transmissionType: JSON.parse(transmissionType) }),

        ...(passengersCapacity && {
          passengersCount: {
            from: parseInt(String(passengersCapacity)),
            to: 9999,
            fromInclusive: true,
            toInclusive: true,
          },
        }),

        ...(sleepCountFrom && {
          sleepCount: {
            from: parseInt(String(sleepCountFrom)),
            to: 9999,
            fromInclusive: true,
            toInclusive: true,
          },
        }),

        ...(String(kitchenSink) === 'true' && { kitchenSink: true }),
        ...(String(parkingSensors) === 'true' && { parkingSensors: true }),
        ...(String(television) === 'true' && { television: true }),
        ...(String(fridge) === 'true' && { fridge: true }),
        ...(String(isofix) === 'true' && { isofix: true }),
        ...(String(heating) === 'true' && { heating: true }),

        ...(shower && { shower: JSON.parse(shower) }),

        ...(bicycleCarrier && { bicycleCarrier: JSON.parse(bicycleCarrier) }),
        ...(toilet && { toilet: JSON.parse(toilet) }),
        ...(String(airConditioning) === 'true' && { airConditioning: true }),
        ...(String(towingDevice) === 'true' && { towingDevice: true }),
        ...(String(solarPanels) === 'true' && { solarPanels: true }),
        ...(String(electricityGenerator) === 'true' && { electricityGenerator: true }),
        ...(String(gpsNavigation) === 'true' && { gpsNavigation: true }),
        ...(String(kitchenWear) === 'true' && { kitchenware: true }),
        ...(String(snowChains) === 'true' && { snowChains: true }),
        ...(String(finalWinterTires) === 'true' && { winterTires: true }),
        ...(String(heatedWasteTank) === 'true' && { heatedWasteTank: true }),
      },
    }),

    // Vehicle
    ...((weightKgTo || fuel || manufacturer || manufacturers) && {
      vehicle: {
        ...(weightKgTo && {
          vehicleWeightOption: weightKgTo,
        }),

        ...(manufacturer && {
          manufacturer,
        }),
        ...(manufacturers && {
          manufacturers,
        }),

        ...(fuel && {
          fuel: JSON.parse(fuel),
        }),
      },
    }),

    calculateTotalPrice: process.env.NEXT_PUBLIC_APP_TYPE === 'fwc',
    // Policy
    ...((pets || smoking || festival || offRoad) && {
      policy: {
        ...(pets && {
          pets: JSON.parse(pets),
        }),

        ...(smoking && {
          smoking: JSON.parse(smoking),
        }),
        ...(String(festival) === 'true' && { festivalPolicy: true }),
        ...(String(offRoad) === 'true' && { offRoadPolicy: true }),
      },
    }),
  };
};

const objectToQueryString = (initialObj) => {
  const reducer =
    (obj, parentPrefix = null) =>
    (prev, key) => {
      const val = obj[key];
      key = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

      if (val == null || typeof val === 'function') {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (['number', 'boolean', 'string'].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`);
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
      return prev;
    };

  return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&');
};

export {
  getSearchResultType,
  getIconFromLocationSearchType,
  parseParamsForSearchQuery,
  makeQueryForSearchFromHP,
  makeQueryForCalendarUpdate,
  proximityCenter,
  useDefaultLocationName,
  isValidBookingDate,
};
