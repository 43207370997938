import { createSelectorCreator, defaultMemoize } from 'reselect';
import { deepCompare } from '~/src/utils/deepCompare';

/**
 * @description this selector creator is meant to be used on selectors that are nested objects or HAVE objects,
 * reselect default equality is reference with cashing 1, that means it effectively cashes primitives without customization of equality fn.
 * However, configuring default reselect equality fn can cause zombie children or unpredictable cashing, for that reason is there solution to simplify things with deep equal check of our own
 * which should be cashing 1 last instance and check equality in performant manner
 * */
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, deepCompare);
