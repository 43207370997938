enum AppType {
  campiri = 'campiri',
  fwc = 'fwc',
}

const getIsValidAppType = (appType: string): appType is AppType => Object.values(AppType).includes(appType as AppType);

const isValidAppType = getIsValidAppType(process.env.NEXT_PUBLIC_APP_TYPE);

if (!isValidAppType) {
  console.warn(
    `Invalid app type: ${process.env.NEXT_PUBLIC_APP_TYPE}. Valid app types are: ${Object.values(AppType).join(
      ', '
    )}. Falling back to default app type: ${AppType.campiri}`
  );
}

export const appType: AppType = isValidAppType ? (process.env.NEXT_PUBLIC_APP_TYPE as AppType) : AppType.campiri;

export const isFWC = appType === AppType.fwc;
export const isCampiri = appType === AppType.campiri;
