import { z } from 'zod';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { toTitleCase } from '~/src/utils/strings';

export const zodErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    return {
      message: i18n._(
        /* i18n */ {
          id: 'input.errors.invalidType',
          values: { expected: issue.expected, received: issue.received },
          message: 'Expected { expected }, received { received }',
        }
      ),
    };
  }
  if (issue.code === z.ZodIssueCode.too_small) {
    return {
      message: i18n._(
        /* i18n */ {
          id: 'input.errors.tooSmall',
          values: { minimum: issue.minimum, input: toTitleCase(issue.path.at(-1) as string) },
          message: '{ input } must be greater than or equal to { minimum }',
        }
      ),
    };
  }
  if (issue.code === z.ZodIssueCode.too_big) {
    return {
      message: i18n._(
        /* i18n */ {
          id: 'input.errors.tooBig',
          values: { maximum: issue.maximum, input: toTitleCase(issue.path.at(-1) as string) },
          message: '{ input } must be less than or equal to { maximum }',
        }
      ),
    };
  }

  return { message: ctx.defaultError };
};
