import { ListingSearchQueryResult } from 'api/src';
import * as t from '../types';

export interface OfferReduxType {
  offerStep: number;
  customerData: any | null;
  chosenVehicleData: ListingSearchQueryResult | null;
  customOfferPriceConfirmed: boolean;
  customOfferCalculation: any | null;
}

const offerState = (
  // eslint-disable-next-line default-param-last
  state: OfferReduxType = {
    offerStep: 0,
    customerData: null,
    chosenVehicleData: null,
    customOfferPriceConfirmed: false,
    customOfferCalculation: null,
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_USER_DATA_OFFER:
      return {
        ...state,
        customerData: action.payload,
      };

    case t.SET_VEHICLE_DATA_OFFER:
      return {
        ...state,
        chosenVehicleData: action.payload as ListingSearchQueryResult,
      };

    case t.SET_OFFER_STEP:
      return {
        ...state,
        offerStep: action.payload,
      };

    case t.SET_CUSTOM_OFFER_PRICE_CONFIRMED:
      return {
        ...state,
        customOfferPriceConfirmed: action.payload,
      };
    case t.SET_CUSTOM_OFFER_CALCULATION:
      return {
        ...state,
        customOfferCalculation: action.payload,
      };

    default:
      return { ...state };
  }
};

export default offerState;
