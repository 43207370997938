import { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import getConfig from 'next/config';
import { makeQueryForCalendarUpdate, makeQueryForSearchFromHP } from '~/src/components/search/utils';
import {
  setIncludedKmTo,
  setIncudedKmFrom,
  setInsuranceIncludedInPrice,
  setPassengersCapacity,
  setSearchAdultsCount,
  setSearchAirConditioning,
  setSearchAwning,
  setSearchBicycleCarier,
  setSearchChildrenCount,
  setSearchDates,
  setSearchFestival,
  setSearchFridge,
  setSearchFuel,
  setSearchGPSNavigation,
  setSearchHeatedWasteTank,
  setSearchHeating,
  setSearchInstantBookable,
  setSearchIsofix,
  setSearchKitchenSink,
  setSearchKitchenWear,
  setSearchLicenseCategory,
  setSearchManufacturer,
  setSearchMaxPrice,
  setSearchMinPrice,
  setSearchOffRoad,
  setSearchParkingSensors,
  setSearchPets,
  setSearchShowChains,
  setSearchShower,
  setSearchSleepCountFrom,
  setSearchSmoking,
  setSearchSolarPanels,
  setSearchTelevision,
  setSearchToilet,
  setSearchTowingDevice,
  setSearchTransmissionType,
  setSearchUnlimitedIncludedKilometers,
  setSearchVehicleType,
  setSearchWeightTo,
  setSearchWinterTires,
  setSearchYearBuildFrom,
  setSearchYearBuildTo,
} from '~/src/redux/actions/search';
import { cultureToken, pages, routes } from '~/src/utils/routing';
import { generateUrl, getCultureTokenFromUrl, getLanguageFromUrl } from '~/src/components/LocalizedLink';
import { SettingsProps } from '../redux/reducers/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useTypedSelector } from '../redux/store';
import { setFlexibleSearchOffset } from 'features/redux/actions/search';
import { searchInfoSelector } from 'features/redux/selectors/search';

const queryString = require('query-string');

const { publicRuntimeConfig } = getConfig();

const removeDateFromQuery = () => {
  const currentQuery = { ...Router.query };
  delete currentQuery.dateFrom;
  delete currentQuery.dateTo;

  const newAsPath = queryString.stringifyUrl({
    url: Router.asPath?.split('?')[0],
    query: { ...currentQuery },
  });

  Router.replace(
    {
      pathname: Router.pathname,
      query: { ...currentQuery },
    },
    newAsPath.split('?')[0],
    { shallow: true, scroll: false, locale: cultureToken }
  );
};

const removeEmailVerification = () => {
  const currentQuery = { ...Router.query };
  delete currentQuery.code;
  delete currentQuery.source;

  const newAsPath = queryString.stringifyUrl({
    url: Router.asPath?.split('?')[0],
    query: { ...currentQuery },
  });

  Router.replace(
    {
      pathname: Router.pathname,
      query: { ...currentQuery },
    },
    newAsPath.split('?')[0],
    { shallow: true, scroll: false, locale: cultureToken }
  );
};

/**
 * A react hook which returns an object with get and set method
 */
export function useSearchParams() {
  const router = useRouter();
  return {
    /**
     * @method get will return a key from the router, if no parameters are supplied, it will return router.query
     */
    get: (key?: string) => {
      if (!key) return router.query;
      return router.query[key] ?? null;
    },
    /**
     * @method set will set query keys, if the first parameter is a record, all the keys will be set to their values,
     * if the first parameter is key, the second parameter will be set as a value, if a value is undefined, it will be unset from the query
     */
    set: (keyOrKeys: string | Record<string, string | undefined>, value: string = undefined) => {
      const { cultureToken: _culture, ...query } = router.query;
      const newUrl = queryString.stringifyUrl({
        url: window.location.pathname,
        query: {
          ...query,
          ...(typeof keyOrKeys === 'string' ? { [keyOrKeys]: value } : keyOrKeys),
        },
      });

      const newQuery = Object.fromEntries(
        Object.entries({
          ...query,
          ...(typeof keyOrKeys === 'string' ? { [keyOrKeys]: value } : keyOrKeys),
        }).filter(([_, val]) => val !== undefined)
      );

      router.replace(
        {
          pathname: router.pathname,
          query: newQuery,
        },
        newUrl,
        { shallow: true, scroll: false, locale: cultureToken }
      );
    },
  } as const;
}

/**
 * Function to dynamically update the query string whenever it's updated
 */
export const useFlexibleSearchOffsetQuery = () => {
  const queryParams = useSearchParams();
  const dispatch = useDispatch();
  const { flexibleSearchOffset } = useTypedSelector(searchInfoSelector);

  const update = (newValue: number) => {
    dispatch(setFlexibleSearchOffset(newValue));
    queryParams.set('flexibleDate', newValue !== 0 ? `${newValue}` : undefined);
  };

  useEffect(() => {
    const flexibleDate = queryParams.get('flexibleDate');
    if (flexibleSearchOffset !== Number(queryParams.get('flexibleDate'))) {
      dispatch(setFlexibleSearchOffset(Number(flexibleDate)));
    }
  }, []);

  useEffect(() => {
    if (flexibleSearchOffset !== Number(queryParams.get('flexibleDate'))) {
      queryParams.set('flexibleDate', flexibleSearchOffset !== 0 ? `${flexibleSearchOffset}` : undefined);
    }
  }, [flexibleSearchOffset]);

  return [flexibleSearchOffset, update] as const;
};

const changeDateSelection = (date, dispatch) => {
  dispatch(setSearchDates(date.selection));

  const { cultureToken: _, ...query } = Router.query;

  const newAsPath = queryString.stringifyUrl({
    url: window.location.pathname,
    query: {
      ...query,
      ...makeQueryForCalendarUpdate(date.selection),
    },
  });

  Router.replace(
    {
      pathname: Router.pathname,
      query: {
        ...query,
        ...makeQueryForCalendarUpdate(date.selection),
      },
    },
    newAsPath,
    { shallow: true, scroll: false, locale: cultureToken }
  );
};

const clearDateSelection = (dispatch) => {
  dispatch(setSearchDates(null));

  const { cultureToken: _, ...query } = Router.query;

  const newAsPath = queryString.stringifyUrl({
    url: window.location.pathname,
    query: {
      ...query,
      dateFrom: undefined,
      dateTo: undefined,
    },
  });

  Router.replace(
    {
      pathname: Router.pathname,
      query: { ...query, dateFrom: undefined, dateTo: undefined },
    },
    newAsPath,
    { shallow: true, scroll: false, locale: cultureToken }
  );
};

const setDateToStoreFromQueryHook = (router: ReturnType<typeof useRouter>, dispatch) => {
  useEffect(() => {
    (async () => {
      const { dateFrom, dateTo } = router.query;
      if (dateFrom && dateTo) {
        dispatch(
          setSearchDates({
            startDate: new Date(`${String(dateFrom)}T00:00:00.000`),
            endDate: new Date(`${String(dateTo)}T00:00:00.000`),
            key: 'selection',
          })
        );
      }
    })();
  }, [router.query]);
};

const setPassengersToStoreFromQueryHook = (router, dispatch) => {
  useEffect(() => {
    (async () => {
      if (router.query.adults) {
        dispatch(setSearchAdultsCount(parseInt(String(router.query.adults))));
      }
      if (router.query.children) {
        dispatch(setSearchChildrenCount(parseInt(String(router.query.children))));
      }
    })();
  }, [router.query]);
};

const fetchCalculationWhenDateInQueryChangesHook = (searchInfo, fetchCalculation, unavailabilityReady, promoCode) => {
  useEffect(() => {
    (async () => {
      if (searchInfo.selectedDays?.startDate && searchInfo.selectedDays?.endDate && unavailabilityReady) {
        await fetchCalculation();
      }
    })();
  }, [JSON.stringify(searchInfo.selectedDays), unavailabilityReady, promoCode]);
};

const pushToQuery = (obj) => {
  const newAsPath = queryString.stringifyUrl({
    url: window.location.pathname,
    query: { ...Router.query, ...obj },
  });

  Router.replace(
    {
      pathname: Router.pathname,
      query: { ...Router.query, ...obj },
    },
    newAsPath,
    { shallow: true, scroll: false, locale: cultureToken }
  );
};
const setQueryParams = async (obj, shallow = true) => {
  const newAsPath = queryString.stringifyUrl({
    url: window.location.pathname,
    query: { ...obj },
  });

  Router.replace(
    {
      pathname: Router.pathname,
      query: { ...Router.query, ...obj },
    },
    newAsPath,
    { shallow, scroll: false, locale: cultureToken }
  );
};

export const setQueryParamsWithRouter = async (obj, shallow = true) => {
  const newAsPath = queryString.stringifyUrl({
    url: window.location.pathname,
    query: obj,
  });

  Router.replace(
    {
      pathname: Router.pathname,
      query: obj,
    },
    newAsPath,
    { shallow, scroll: false, locale: cultureToken }
  );
};

const removeUselessBookingQueryParams = (queryParams: any, skipId = false) => {
  delete queryParams.cultureToken;
  delete queryParams.fullPath;
  delete queryParams.redirect_status;
  delete queryParams.payment_intent_client_secret;
  delete queryParams.payment_intent;
  delete queryParams.bookingId;
  delete queryParams.payment;
  if (!skipId) delete queryParams.id;

  return queryParams;
};
const removeUselessQueryParams = (queryParams: any) => {
  delete queryParams.token;
  delete queryParams.cultureToken;
  delete queryParams.fullPath;

  return queryParams;
};
const goToSearchPageWithQuery = (searchInfo, forcedDates = null, fromLandingPage = false, winterVersion = false) => {
  let searchInfoWithForcedDates = searchInfo;

  // let addWinterQuery = winterVersion;

  // if (searchInfo.selectedDays?.startDate) {
  //   const startOfTripMonth = dayjs(searchInfo.selectedDays?.startDate).month();

  //   if (startOfTripMonth >= 3) {
  //     addWinterQuery = false;
  //   }
  // }

  if (forcedDates) {
    searchInfoWithForcedDates = { ...searchInfo, selectedDays: forcedDates };
  }

  const language = getLanguageFromUrl(window.location.pathname);
  const token = getCultureTokenFromUrl(window.location.pathname);

  const url = generateUrl(fromLandingPage ? routes.landingSeptember : routes.search, language, token);

  const newAsPath = queryString.stringifyUrl({
    url,
    query: makeQueryForSearchFromHP(searchInfoWithForcedDates, removeUselessQueryParams(Router.query), false),
  });

  Router.replace(
    {
      pathname: fromLandingPage ? pages.landingSeptember : pages.search,
      query: makeQueryForSearchFromHP(searchInfoWithForcedDates, Router.query, false),
    },
    newAsPath,
    { shallow: true, scroll: false, locale: cultureToken }
  );
};

const goToSearchPageWithResetFilters = (newQueryToPush) => {
  let newPathname = pages.search;

  const language = getLanguageFromUrl(window.location.pathname);
  const token = getCultureTokenFromUrl(window.location.pathname);
  let url = generateUrl(routes.search, language, token);

  if (Router.router.pathname === '/offers') {
    url = generateUrl(routes.create_offer, language, token);
    newPathname = pages.create_offer;
  }

  if (Router.router.pathname === '/landing/september') {
    url = generateUrl(routes.landingSeptember, language, token);
    newPathname = pages.landingSeptember;
  }

  const newAsPath = queryString.stringifyUrl({
    url,
    query: newQueryToPush,
  });

  Router.replace(
    {
      pathname: newPathname,
      query: newQueryToPush,
    },
    newAsPath,
    { shallow: true, scroll: false, locale: cultureToken }
  );
};

const getProtocol = () => (publicRuntimeConfig.ENABLE_HTTPS === 'true' ? 'https' : 'http');

const goToBookingPage = (bookingId, settings: SettingsProps, paymentVersion = false) => {
  const url = getBookingPageUrl(bookingId, settings, paymentVersion);

  window.location.href = url;
};

export const getBookingPageUrl = (bookingId, settings: SettingsProps, paymentVersion = false) => {
  const query = paymentVersion === true ? '?payment=true' : '';
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    return `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}/booking-${bookingId}${query}`;
  }
  return `${protocol}://${window.location.hostname}/${token}/booking-${bookingId}${query}`;
};

const goToReviewPage = (bookingId, settings: SettingsProps) => {
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    window.location.href = `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}/review-${bookingId}`;
  } else {
    window.location.href = `${protocol}://${window.location.hostname}/${token}/review-${bookingId}`;
  }
};

const goToOperatorReviewPage = (bookingId, settings: SettingsProps) => {
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    window.location.href = `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}/operator/review-${bookingId}`;
  } else {
    window.location.href = `${protocol}://${window.location.hostname}/${token}/operator/review-${bookingId}`;
  }
};

const goToCheckinCustomerPage = (bookingId, settings: SettingsProps) => {
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    window.location.href = `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}/check-in-${bookingId}`;
  } else {
    window.location.href = `${protocol}://${window.location.hostname}/${token}/check-in-${bookingId}`;
  }
};

const goToCheckinOperatorPage = (bookingId, settings: SettingsProps) => {
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    window.location.href = `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}/operator/check-in-${bookingId}`;
  } else {
    window.location.href = `${protocol}://${window.location.hostname}/${token}/operator/check-in-${bookingId}`;
  }
};

const goToManagerBookingPage = (bookingId, settings: SettingsProps) => {
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    window.location.href = `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}/manager/booking-${bookingId}`;
  } else {
    window.location.href = `${protocol}://${window.location.hostname}/${token}/manager/booking-${bookingId}`;
  }
};

const goToOperatorBookingPage = (bookingId, settings: SettingsProps) => {
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    window.location.href = `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}/operator/booking-${bookingId}`;
  } else {
    window.location.href = `${protocol}://${window.location.hostname}/${token}/operator/booking-${bookingId}`;
  }
};

const goToBookingKycPage = (bookingId, settings: SettingsProps) => {
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    window.location.href = `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}/booking-${bookingId}/kyc`;
  } else {
    window.location.href = `${protocol}://${window.location.hostname}/${token}/booking-${bookingId}/kyc`;
  }
};

const getArticleDetailPage = (preparedSlug, settings: SettingsProps) => {
  const token = `${settings.language}-${settings.country}`;
  const protocol = getProtocol();

  if (
    publicRuntimeConfig.NODE_ENV === 'development' ||
    window?.location?.hostname.includes('localhost') ||
    window?.location?.hostname.includes('campiri_nextjs')
  ) {
    return `${protocol}://${window.location.hostname}:${
      publicRuntimeConfig.APP_PORT ? publicRuntimeConfig.APP_PORT : '3000'
    }/${token}${preparedSlug}`;
  }
  return `${protocol}://${window.location.hostname}/${token}${preparedSlug}`;
};

const parseQueryToReduxHook = (query, dispatch) => {
  // 1.
  if (query.maximumPrice) {
    dispatch(setSearchMaxPrice(parseInt(query.maximumPrice)));
  }
  if (query.minimumPrice) {
    dispatch(setSearchMinPrice(parseInt(query.minimumPrice)));
  }
  if (query.instantBookable) {
    dispatch(setSearchInstantBookable(String(query.instantBookable) === 'true'));
  }
  if (query.insuranceIncludedInPrice) {
    dispatch(setInsuranceIncludedInPrice(String(query.insuranceIncludedInPrice) === 'true'));
  }

  // 2.
  if (query.vehicleType) {
    dispatch(setSearchVehicleType(JSON.parse(query.vehicleType)));
  }
  if (query.licenseCategory) {
    dispatch(setSearchLicenseCategory(query.licenseCategory));
  }
  if (query.transmissionType) {
    dispatch(setSearchTransmissionType(JSON.parse(query.transmissionType)));
  }
  if (query.sleepCountFrom) {
    dispatch(setSearchSleepCountFrom(parseInt(query.sleepCountFrom)));
  }
  if (query.passengersCapacity) {
    dispatch(setPassengersCapacity(parseInt(query.passengersCapacity)));
  }

  // 3.

  if (query.includedKmFrom) {
    dispatch(setIncudedKmFrom(parseInt(query.includedKmFrom)));
  }
  if (query.includedKmTo) {
    dispatch(setIncludedKmTo(parseInt(query.includedKmTo)));
  }

  // 4.
  // TODO

  if (query.kitchenSink) {
    dispatch(setSearchKitchenSink(String(query.kitchenSink) === 'true'));
  }
  if (query.kitchenWear) {
    dispatch(setSearchKitchenWear(String(query.kitchenWear) === 'true'));
  }
  if (query.parkingSensors) {
    dispatch(setSearchParkingSensors(String(query.parkingSensors) === 'true'));
  }
  if (query.television) {
    dispatch(setSearchTelevision(String(query.television) === 'true'));
  }
  if (query.fridge) {
    dispatch(setSearchFridge(String(query.fridge) === 'true'));
  }
  if (query.heating) {
    dispatch(setSearchHeating(String(query.heating) === 'true'));
  }
  if (query.isofix) {
    dispatch(setSearchIsofix(String(query.isofix) === 'true'));
  }
  if (query.towingDevice) {
    dispatch(setSearchTowingDevice(String(query.towingDevice) === 'true'));
  }
  if (query.solarPanels) {
    dispatch(setSearchSolarPanels(String(query.solarPanels) === 'true'));
  }
  if (query.awning) {
    dispatch(setSearchAwning(String(query.awning) === 'true'));
  }
  if (query.airConditioning) {
    dispatch(setSearchAirConditioning(String(query.airConditioning) === 'true'));
  }
  if (query.gpsNavigation) {
    dispatch(setSearchGPSNavigation(String(query.gpsNavigation) === 'true'));
  }
  if (query.shower) {
    dispatch(setSearchShower(JSON.parse(query.shower)));
  }
  if (query.toilet) {
    dispatch(setSearchToilet(JSON.parse(query.toilet)));
  }
  if (query.bicycleCarrier) {
    dispatch(setSearchBicycleCarier(JSON.parse(query.bicycleCarrier)));
  }

  if (query.snowChains) {
    dispatch(setSearchShowChains(String(query.snowChains) === 'true'));
  }
  if (query.heatedWasteTank) {
    dispatch(setSearchHeatedWasteTank(String(query.heatedWasteTank) === 'true'));
  }
  if (query.winterTires) {
    dispatch(setSearchWinterTires(String(query.winterTires) === 'true'));
  }

  // 5.

  if (query.yearBuildFrom) {
    dispatch(setSearchYearBuildFrom(parseInt(query.yearBuildFrom)));
  }
  if (query.yearBuildTo) {
    dispatch(setSearchYearBuildTo(parseInt(query.yearBuildTo)));
  }

  if (query.weightKgTo) {
    dispatch(setSearchWeightTo(query.weightKgTo));
  }
  if (query.fuel) {
    dispatch(setSearchFuel(JSON.parse(query.fuel)));
  }
  if (query.manufacturer) {
    dispatch(setSearchManufacturer(query.manufacturer));
  }

  // 6.
  if (query.pets) {
    dispatch(setSearchPets(JSON.parse(query.pets)));
  }
  if (query.smoking) {
    dispatch(setSearchSmoking(JSON.parse(query.smoking)));
  }
  if (query.unlimitedIncludedKilometers) {
    dispatch(setSearchUnlimitedIncludedKilometers(String(query.unlimitedIncludedKilometers) === 'true'));
  }
  if (query.festival) {
    dispatch(setSearchFestival(String(query.festival) === 'true'));
  }
  if (query.offRoad) {
    dispatch(setSearchOffRoad(String(query.offRoad) === 'true'));
  }
};

export {
  changeDateSelection,
  setDateToStoreFromQueryHook,
  fetchCalculationWhenDateInQueryChangesHook,
  parseQueryToReduxHook,
  pushToQuery,
  goToSearchPageWithQuery,
  goToSearchPageWithResetFilters,
  goToBookingPage,
  goToReviewPage,
  goToOperatorReviewPage,
  removeDateFromQuery,
  setPassengersToStoreFromQueryHook,
  goToManagerBookingPage,
  goToBookingKycPage,
  goToCheckinCustomerPage,
  goToCheckinOperatorPage,
  goToOperatorBookingPage,
  removeUselessQueryParams,
  getArticleDetailPage,
  clearDateSelection,
  removeEmailVerification,
  setQueryParams,
  removeUselessBookingQueryParams,
};
