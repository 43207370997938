import { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import { Configuration } from '~/libs/api/configuration';
import { UserMarketplaceApi } from '~/libs/api/apis/user-marketplace-api';
import { CustomerBillingInfoUpdateCommand } from '~/libs/api/models/customer-billing-info-update-command';
import { CustomerInfoUpdateCommand } from '~/libs/api/models/customer-info-update-command';
import { IncompleteCustomerInfoUpdateCommand } from '~/libs/api/models/incomplete-customer-info-update-command';
import { IncompleteCustomerInsuranceInfoUpdateCommand } from '~/libs/api';

const getAccessToken = () => {
  const accessToken = window.localStorage?.getItem('accessToken');
  return accessToken;
};

const { publicRuntimeConfig } = getConfig();

const getApiConfig = () =>
  new Configuration({
    basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ''),
    accessToken: getAccessToken,
  });

const apiConfiguration = getApiConfig();

const USER_API = new UserMarketplaceApi(apiConfiguration, apiConfiguration.basePath);

export const updateUserData = async (
  ustomerInfoUpdateCommand?: CustomerInfoUpdateCommand,
  options?: AxiosRequestConfig
) => USER_API.apiV1MarketplaceMeUsersPatch(ustomerInfoUpdateCommand);
export const updateUserPicture = async (file?: any, options?: AxiosRequestConfig) =>
  USER_API.apiV1MarketplaceMeUsersPicturePut(file, options);

export const deleteUserPicture = async (options?: AxiosRequestConfig) =>
  USER_API.apiV1MarketplaceMeUsersPictureDelete(options);

export const getCurrentlyLoggedCustomer = async () => USER_API.apiV1MarketplaceMeUsersGet();

export const updateUserBillingInformation = async (
  customerBillingInfoUpdateCommand?: CustomerBillingInfoUpdateCommand
) => USER_API.apiV1MarketplaceMeUsersBillingInformationPatch(customerBillingInfoUpdateCommand);
export const userAccountComplete = async (incompleteCustomerInfoUpdateCommand?: IncompleteCustomerInfoUpdateCommand) =>
  USER_API.apiV1MarketplaceMeUsersCompletePatch(incompleteCustomerInfoUpdateCommand);

export const userCompleteInsuranceData = async (data: IncompleteCustomerInsuranceInfoUpdateCommand) =>
  USER_API.apiV1MarketplaceMeUsersCompleteInsuranceDataPatch(data);
