/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CurrencyCode = {
    NotSet: 'NotSet',
    ALL: 'ALL',
    DZD: 'DZD',
    ARS: 'ARS',
    AUD: 'AUD',
    BSD: 'BSD',
    BHD: 'BHD',
    BDT: 'BDT',
    AMD: 'AMD',
    BBD: 'BBD',
    BMD: 'BMD',
    BTN: 'BTN',
    BOB: 'BOB',
    BWP: 'BWP',
    BZD: 'BZD',
    SBD: 'SBD',
    BND: 'BND',
    MMK: 'MMK',
    BIF: 'BIF',
    KHR: 'KHR',
    CAD: 'CAD',
    CVE: 'CVE',
    KYD: 'KYD',
    LKR: 'LKR',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    KMF: 'KMF',
    CRC: 'CRC',
    HRK: 'HRK',
    CUP: 'CUP',
    CZK: 'CZK',
    DKK: 'DKK',
    DOP: 'DOP',
    SVC: 'SVC',
    ETB: 'ETB',
    ERN: 'ERN',
    FKP: 'FKP',
    FJD: 'FJD',
    DJF: 'DJF',
    GMD: 'GMD',
    GIP: 'GIP',
    GTQ: 'GTQ',
    GNF: 'GNF',
    GYD: 'GYD',
    HTG: 'HTG',
    HNL: 'HNL',
    HKD: 'HKD',
    HUF: 'HUF',
    ISK: 'ISK',
    INR: 'INR',
    IDR: 'IDR',
    IRR: 'IRR',
    IQD: 'IQD',
    ILS: 'ILS',
    JMD: 'JMD',
    JPY: 'JPY',
    KZT: 'KZT',
    JOD: 'JOD',
    KES: 'KES',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KGS: 'KGS',
    LAK: 'LAK',
    LBP: 'LBP',
    LSL: 'LSL',
    LRD: 'LRD',
    LYD: 'LYD',
    MOP: 'MOP',
    MWK: 'MWK',
    MYR: 'MYR',
    MVR: 'MVR',
    MUR: 'MUR',
    MXN: 'MXN',
    MNT: 'MNT',
    MDL: 'MDL',
    MAD: 'MAD',
    OMR: 'OMR',
    NAD: 'NAD',
    NPR: 'NPR',
    ANG: 'ANG',
    AWG: 'AWG',
    VUV: 'VUV',
    NZD: 'NZD',
    NIO: 'NIO',
    NGN: 'NGN',
    NOK: 'NOK',
    PKR: 'PKR',
    PAB: 'PAB',
    PGK: 'PGK',
    PYG: 'PYG',
    PEN: 'PEN',
    PHP: 'PHP',
    QAR: 'QAR',
    RUB: 'RUB',
    RWF: 'RWF',
    SHP: 'SHP',
    SAR: 'SAR',
    SCR: 'SCR',
    SLL: 'SLL',
    SGD: 'SGD',
    VND: 'VND',
    SOS: 'SOS',
    ZAR: 'ZAR',
    SSP: 'SSP',
    SZL: 'SZL',
    SEK: 'SEK',
    CHF: 'CHF',
    SYP: 'SYP',
    THB: 'THB',
    TOP: 'TOP',
    TTD: 'TTD',
    AED: 'AED',
    TND: 'TND',
    UGX: 'UGX',
    MKD: 'MKD',
    EGP: 'EGP',
    GBP: 'GBP',
    TZS: 'TZS',
    USD: 'USD',
    UYU: 'UYU',
    UZS: 'UZS',
    WST: 'WST',
    YER: 'YER',
    TWD: 'TWD',
    UYW: 'UYW',
    VES: 'VES',
    MRU: 'MRU',
    STN: 'STN',
    CUC: 'CUC',
    ZWL: 'ZWL',
    BYN: 'BYN',
    TMT: 'TMT',
    GHS: 'GHS',
    SDG: 'SDG',
    UYI: 'UYI',
    RSD: 'RSD',
    MZN: 'MZN',
    AZN: 'AZN',
    RON: 'RON',
    CHE: 'CHE',
    CHW: 'CHW',
    TRY: 'TRY',
    XAF: 'XAF',
    XCD: 'XCD',
    XOF: 'XOF',
    XPF: 'XPF',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XAU: 'XAU',
    XDR: 'XDR',
    XAG: 'XAG',
    XPT: 'XPT',
    XTS: 'XTS',
    XPD: 'XPD',
    XUA: 'XUA',
    ZMW: 'ZMW',
    SRD: 'SRD',
    MGA: 'MGA',
    COU: 'COU',
    AFN: 'AFN',
    TJS: 'TJS',
    AOA: 'AOA',
    BGN: 'BGN',
    CDF: 'CDF',
    BAM: 'BAM',
    EUR: 'EUR',
    MXV: 'MXV',
    UAH: 'UAH',
    GEL: 'GEL',
    BOV: 'BOV',
    PLN: 'PLN',
    BRL: 'BRL',
    CLF: 'CLF',
    XSU: 'XSU',
    USN: 'USN',
    XXX: 'XXX'
} as const;

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];



