export const SET_FETCHED_CHECKIN_DATA = 'SET_FETCHED_CHECKIN_DATA';
export const SET_FETCHED_RESERVATION_DATA = 'SET_FETCHED_RESERVATION_DATA';

export const SET_CHECKIN_LOADING_DATA = 'SET_CHECKIN_LOADING_DATA';
export const SET_CHECKIN_ERROR = 'SET_CHECKIN_ERROR';

export const SET_CHECKIN_STEP = 'SET_CHECKIN_STEP';
export const SET_CHECKIN_GUID = 'SET_CHECKIN_GUID';
export const SET_SELECTED_CAMPERVAN = 'SET_SELECTED_CAMPERVAN';

export const UPDATE_UPLOADED_IMAGES_CHECKIN = 'UPDATE_UPLOADED_IMAGES_CHECKIN';
export const DELETE_UPLOADED_IMAGES_CHECKING = 'DELETE_UPLOADED_IMAGES_CHECKING';

export const UPDATE_UPLOADED_VEHICLE_DAMAGES = 'UPDATE_UPLOADED_VEHICLE_DAMAGES';
export const DELETE_UPLOADED_VEHICLE_DAMAGES = 'DELETE_UPLOADED_VEHICLE_DAMAGES';
export const UPDATE_UPLOADED_VEHICLE_DAMAGE_PICTURES = 'UPDATE_UPLOADED_VEHICLE_DAMAGE_PICTURES';
export const DELETE_UPLOADED_VEHICLE_DAMAGE_PICTURE = 'DELETE_UPLOADED_VEHICLE_DAMAGE_PICTURE';

export const SET_VALIDATION_CAMPERVAN = 'SET_VALIDATION_CAMPERVAN';
export const SET_VALIDATION_CUSTOMER = 'SET_VALIDATION_CUSTOMER';
export const SET_VALIDATION_HANDOVER = 'SET_VALIDATION_HANDOVER';
