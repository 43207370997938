/* eslint-disable no-restricted-syntax */
import { useEffect } from 'react';
import getConfig from 'next/config';
import dayjs from 'dayjs';

import { SettingsProps } from '../redux/reducers/settings';
import { CalculationReduxType } from '../redux/reducers/calculation';
import { routes } from './routing';
import { UserReduxType } from '../redux/reducers/user';
import { ListingDetailQueryResult } from '~/libs/api/models/listing-detail-query-result';
import { InvestmentType } from '~/libs/api/models/investment-type';
import { InsuranceType } from '~/libs/api/models/insurance-type';
import { ListingSearchQueryResult } from '~/libs/api/models/listing-search-query-result';
import { CampiriProgramType } from '~/libs/api';
import codebase from 'features/utils/codebase';
import { useTypedSelector } from '../redux/store';
import { settingsSelector } from 'features/redux/selectors/settings';
import { userSelector } from '../redux/selectors/user';
import { useRouter } from 'next/router';

const { publicRuntimeConfig } = getConfig();

declare let ga: any;
declare let gtag: any;
declare let fbq: any;
declare let dataLayer: any;

declare global {
  interface Window {
    _adftrack: any;
    diffAnalytics: any;
    dataLayer: any;
  }
}

export const getProducerData = (isP2P, operatorGuid) => {
  // Lukas Dohodil
  if (operatorGuid === '01788e91-d1dc-4cf6-b77e-a72e1302c65c') {
    return 'campiri';
  }
  if (operatorGuid === '01834d29-63d6-4223-96a4-b1f5eeb52685') {
    return 'mlg';
  }
  if (operatorGuid === '018b0435-766f-4f19-adfc-20fe05ae967c') {
    return 'pai';
  }
  if (isP2P) {
    return 'p2p';
  }
  return 'rental';
};

export const getPogramTypeData = (programType: CampiriProgramType, operatorGuid: string) => {
  if (operatorGuid === '01788e91-d1dc-4cf6-b77e-a72e1302c65c') {
    return 'Campiri';
  }
  return programType;
};

export const tryCallDataLayerEvent = (trackingFunction) => {
  // if (publicRuntimeConfig.NODE_ENV !== 'production') return;

  try {
    if (window?.dataLayer) {
      trackingFunction();
    }
  } catch (err) {
    console.error(err);
  }
};

// TODO ask price
// TODO https://sites.google.com/taste.cz/campiri-dokumentace/implementace/ecommerce/proklik-do-produktu?authuser=2
export const trackProductDetailOpenedDataLayer = (carData: ListingDetailQueryResult, price) => {
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      products: [
        {
          id: carData.guid,
          name: carData.title,
          brand: carData.manufacturer,
          producer: getProducerData(carData?.insurance === InsuranceType.P2P, carData.operator?.guid),
          availability: carData.instantBookable ? 'instant booking' : 'on demand',
          price,
          'base.price': carData.fromPrice,
          quantity: 1,
          categories: [carData.type],
          program: getPogramTypeData(carData.programType, carData.operator?.guid),
        },
      ],
    },
    _clear: true,
  });
};

// TODO ask  producer,list_id,list_name
// TODO https://sites.google.com/taste.cz/campiri-dokumentace/implementace/ecommerce/proklik-do-produktu?authuser=2

export const trackProductClickDataLayer = (carData: ListingSearchQueryResult, listName = 'Search results') => {
  window.dataLayer.push({
    event: 'select_item',

    ecommerce: {
      list_id: '1',
      list_name: listName,
      products: [
        {
          id: carData.id,
          name: carData.title,
          brand: carData.manufacturer,
          producer: getProducerData(carData?.insurance === InsuranceType.P2P, carData.operatorGuid),
          availability: carData.instantBookable ? 'instant booking' : 'on demand',
          price: carData.minPrice.total,
          'base.price': carData.minPrice.total,
          categories: [carData.vehicleType],
          size: carData.passengersCapacity,
          program: getPogramTypeData(carData.programType, carData.operatorGuid),
        },
      ],
    },
    _clear: true,
  });
};

// TODO producer
// TODO tonda - producer

export const trackAddToCartDataLayer = (
  calculation: CalculationReduxType,
  carData: ListingDetailQueryResult,
  referenceCode: string,
  area: string
) => {
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      id: referenceCode,
      coupon: calculation?.data?.rentalPromoCode,
      payment: calculation?.data?.total?.total,
      tax: calculation?.data?.total?.vat,
      days: calculation?.data?.days?.length,
      area,
      products: [
        {
          id: carData.guid,
          name: carData.title,
          brand: carData.manufacturer,
          availability: carData.instantBookable ? 'instant booking' : 'on demand',
          producer: getProducerData(carData?.insurance === InsuranceType.P2P, carData.operator?.guid),
          size: carData.passengerCapacity,
          categories: [carData.type],
          price: calculation.data.total.total,
          virtual_price: calculation.data.total.total * 0.25,
          'base.price': carData.fromPrice,
          discount: calculation.data.rentalTermDiscount.total,
          quantity: 1,
          program: getPogramTypeData(carData.programType, carData.operator?.guid),
        },
      ],
    },

    _clear: true,
  });
};

export const tryCallBookingCreatedDataLayer = (
  calculation: CalculationReduxType,
  carData: ListingDetailQueryResult,
  bookingId,
  eventCallBackToCall,
  customerData = null
) => {
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      id: bookingId,
      value: calculation.data.total.total - calculation.data.total.vat,
      payment: calculation.data.total.total,
      coupon: calculation?.data?.rentalPromoCode,
      tax: calculation.data.total.vat,
      products: [
        {
          id: carData.guid,
          name: carData.title,
          brand: carData.manufacturer,
          producer: getProducerData(carData?.insurance === InsuranceType.P2P, carData.operator?.guid),
          size: carData.passengerCapacity,
          categories: [carData.type],
          availability: carData.instantBookable ? 'instant booking' : 'on demand',
          price: calculation.data.total.total,
          discount: calculation.data.rentalTermDiscount.total,
          quantity: 1,
          program: getPogramTypeData(carData.programType, carData.operator?.guid),
        },
      ],
      ...(customerData && {
        user: [
          {
            name: customerData.name ?? `${customerData.firstName} ${customerData.lastName}`,
            email: customerData.email,
            phone: customerData.phone,
          },
        ],
      }),
    },
    eventCallback() {
      eventCallBackToCall();
    },
    eventTimeout: 200,
    _clear: true,
  });
};

export const trackUserLoginDataLayer = (type, userId, userType = 'Customer') => {
  window.dataLayer.push({
    event: 'login',
    login: {
      type: 'explicit',
      id: userId,
      method: type,
    },
    user: {
      id: userId,
      type: userType,
    },

    _clear: true,
  });
};

export const trackUserRegistrationDataLayer = (isExplicit, type, userType = 'Customer') => {
  window.dataLayer.push({
    event: 'registration',
    registration: {
      type: isExplicit ? 'explicit' : 'implicit',
      method: type,
    },
    user: {
      type: userType,
    },
    _clear: true,
  });
};

export const trackSentP2PForm = () => {
  window.dataLayer.push({
    event: 'formsent',
    formsent: {
      name: 'p2p',
      text: 'Continue',
    },
    _clear: true,
  });
};

export const trackOpenCCCForm = (text) => {
  window.dataLayer.push({
    event: 'formopened',
    formsent: {
      name: 'ccc',
      text,
    },
    _clear: true,
  });
};

export const trackLoginFormOpened = (type = null) => {
  window.dataLayer.push({
    event: 'formopened',
    formsent: {
      name: 'login',
      ...(type !== null && { type }),
    },
    _clear: true,
  });
};

export const trackRegisterFormOpened = () => {
  window.dataLayer.push({
    event: 'formopened',
    formsent: {
      name: 'register',
    },
    _clear: true,
  });
};

type TrackLinkParams = {
  id?: string;
  link_url: string;
  text: string /** Title of the menu link */;
};

type TrackSpecialOfferParams = {
  id?: string;
  headline: string;
  link_url: string;
};
export function trackSpecialOffer(params: TrackSpecialOfferParams) {
  window.dataLayer.push({
    event: 'click',
    click: {
      name: 'hp-promo-topbar',
      type: 'link',
      area: 'header',
      url: window.location.href,
      ...params,
    },
    _clear: true,
  });
}

type TrackMainMenuParams = {
  id?: string;
  text: string /** Title of the menu opened */;
};
export function trackMainMenu(params: TrackMainMenuParams) {
  window.dataLayer.push({
    event: 'click',
    click: {
      name: 'main-menu-open',
      type: 'modal',
      area: 'menu',
      url: window.location.href,
      ...params,
    },
    _clear: true,
  });
}

export function trackMainMenuLink(params: TrackLinkParams) {
  window.dataLayer.push({
    event: 'click',
    click: {
      name: 'main-menu-click',
      type: 'modal',
      area: 'menu',
      url: window.location.href,
      ...params,
    },
    _clear: true,
  });
}

export function trackBannerLink(params: TrackLinkParams) {
  window.dataLayer.push({
    event: 'click',
    click: {
      name: 'hp-banner',
      type: 'banner',
      area: 'body',
      ...params,
    },
    _clear: true,
  });
}

export const trackSentCCCForm = (type: InvestmentType) => {
  let typeToSend;

  if (type === InvestmentType.LongTerm) {
    typeToSend = 'ccc - passive';
  }

  if (type === InvestmentType.ShortTerm) {
    typeToSend = 'ccc - smart';
  }

  window.dataLayer.push({
    event: 'formsent',
    formsent: {
      name: 'ccc',
      type: typeToSend,
      text: 'Odeslat',
    },
    _clear: true,
  });
};

export const trackOpenBookingFormOpened = () => {
  window.dataLayer.push({
    event: 'formopened',
    formsent: {
      name: 'open-inquiry',
      text: 'Request a campervan',
    },
    _clear: true,
  });
};

export const trackOpenBookingFormSent = () => {
  window.dataLayer.push({
    event: 'formsent',
    formsent: {
      name: 'open-inquiry',
      text: 'Send',
    },
    _clear: true,
  });
};

export const trackListingWizardEntryCTA = () => {
  window.dataLayer.push({
    event: 'click',
    click: {
      type: 'button',
      url: window?.location.href,
      text: 'Jak začít',
    },
    _clear: true,
  });
};

interface TrackSearchDataLayerOptionalArgs {
  loadMore?: boolean;
  flexibleDate?: number;
}

export const trackSearchDataLayer = (
  date,
  place,
  { loadMore = false, flexibleDate = 0 }: TrackSearchDataLayerOptionalArgs
) => {
  const dateFrom = date ? dayjs(date.startDate).format('YYYY-MM-DD') : 'not-set';
  const dateTo = date ? dayjs(date.endDate).format('YYYY-MM-DD') : 'not-set';
  const searchDays =
    date && date.startDate && date.endDate ? dayjs(date.endDate).diff(dayjs(date.startDate), 'day') : 'not-set';

  const placeName = place ? place.place_name : 'not-set';

  window.dataLayer.push({
    event: 'search',
    search: {
      name: 'Search Caravan',
      type: 'Search',
      searchdatestart: dateFrom,
      searchdateend: dateTo,
      searchdays: searchDays,
      searchregion: placeName,
      text: loadMore ? 'Load More' : 'Search campervan',
      area: loadMore ? 'Load More' : 'Widget Menu',
      flexibledate: flexibleDate,
    },
    _clear: true,
  });
};

export const trackIntentMovingToReservation = (bookingData, total) => {
  if (bookingData?.instantBookable) return;
  window.dataLayer.push({
    event: 'inquirySent',
    inquirySent: {
      id: bookingData.referenceCode,
      payment: total,
      name: bookingData.listing.title,
      brand: bookingData.listing.manufacturer,
      producer: getProducerData(bookingData?.insurance === InsuranceType.P2P, bookingData.listing?.operatorGuid),
      categories: [bookingData.listing.vehicleType],
      availability: bookingData.listing.instantBookable ? 'instant booking' : 'on demand',
      size: bookingData.listing.passengersCapacity,
      days: getDaysDifference(bookingData.end, bookingData.start),
    },
    _clear: true,
  });
};

export type EcommerceAddonPayload = {
  id: string;
  name: string;
  quantity: string;
  price: string;
};

export const trackSetExtrasStep = (bookingData, addonsPayload?: EcommerceAddonPayload[], eventName = 'add_extras') => {
  window.dataLayer.push({
    event: eventName,
    ecommerce: {
      id: bookingData.referenceCode,
      payment: bookingData?.total?.total,
      coupon: bookingData?.rentalPromoCode,
      tax: bookingData?.total?.vat,
      products: addonsPayload,
    },
    _clear: true,
  });
};

export const trackSuccessfullySentCancelationProtection = (bookingData, total) => {
  if (!bookingData?.instantBookable) return;

  window.dataLayer.push({
    event: 'add_insurance_info',
    ecommerce: {
      id: bookingData.referenceCode,
      payment: total,
      coupon: bookingData?.rentalPromoCode,
      tax: bookingData?.total?.vat,
      days: getDaysDifference(bookingData.end, bookingData.start),
      products: [
        {
          id: bookingData.guid,
          name: bookingData.listing?.title,
          brand: bookingData.listing?.manufacturer,
          producer: getProducerData(bookingData?.insurance === InsuranceType.P2P, bookingData.listing?.operatorGuid),
          size: bookingData.listing.passengersCapacity,
          categories: [bookingData.listing.vehicleType],
          availability: bookingData.listing.instantBookable ? 'instant booking' : 'on demand',
          price: total,
          quantity: 1,
          program: getPogramTypeData(bookingData.programType, bookingData.listing?.operatorGuid),
        },
      ],
    },
    _clear: true,
  });
};

export const trackAddPaymentInfo = (bookingData, total, customerData = null) => {
  if (!bookingData?.instantBookable) return;

  window.dataLayer.push({
    event: 'add_payment_info',
    ecommerce: {
      id: bookingData.referenceCode,
      payment: total,
      days: getDaysDifference(bookingData.end, bookingData.start),
      tax: bookingData?.total?.vat,
      products: [
        {
          id: bookingData.guid,
          name: bookingData.listing?.title,
          brand: bookingData.listing?.manufacturer,
          producer: getProducerData(bookingData?.insurance === InsuranceType.P2P, bookingData.listing?.operatorGuid),
          size: bookingData.listing.passengersCapacity,
          categories: [bookingData.listing.vehicleType],
          availability: bookingData.listing.instantBookable ? 'instant booking' : 'on demand',
          price: total,
          quantity: 1,
          program: getPogramTypeData(bookingData.programType, bookingData.listing?.operatorGuid),
        },
      ],
      ...(customerData && {
        user: [
          {
            name: customerData.name ?? `${customerData.firstName} ${customerData.lastName}`,
            email: customerData.email,
            phone: customerData.phone,
          },
        ],
      }),
    },
    _clear: true,
  });
};

export const trackPurchaseEvent = (bookingData, total, isBankTransfer, customerData = null) => {
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      id: bookingData.referenceCode,
      payment: total,
      coupon: bookingData?.rentalPromoCode,
      days: getDaysDifference(bookingData.end, bookingData.start),
      payment_type: isBankTransfer ? 'BankTransfer' : 'Stripe',
      products: [
        {
          id: bookingData.guid,
          name: bookingData.listing?.title,
          brand: bookingData.listing?.manufacturer,
          producer: getProducerData(bookingData?.insurance === InsuranceType.P2P, bookingData.listing?.operatorGuid),
          size: bookingData.listing.passengersCapacity,
          categories: [bookingData.listing.vehicleType],
          availability: bookingData.listing.instantBookable ? 'instant booking' : 'on demand',
          price: total,
          quantity: 1,
          program: getPogramTypeData(bookingData.programType, bookingData.listing?.operatorGuid),
        },
      ],
      ...(customerData && {
        user: [
          {
            name: customerData.name ?? `${customerData.firstName} ${customerData.lastName}`,
            email: customerData.email,
            phone: customerData.phone,
          },
        ],
      }),
    },
    _clear: true,
  });
};

export const trackPaymentDone = (bookingData, priceToPay, paymentType) => {
  if (bookingData?.instantBookable) return;
  window.dataLayer.push({
    event: 'inquiryPaid',
    inquirySent: {
      id: bookingData.referenceCode,
      payment: priceToPay,
      name: bookingData.listing.title,
      brand: bookingData.listing.manufacturer,
      producer: getProducerData(bookingData?.insurance === InsuranceType.P2P, bookingData.listing?.operatorGuid),
      categories: [bookingData.listing.vehicleType],
      availability: bookingData.listing.instantBookable ? 'instant booking' : 'on demand',
      size: bookingData.listing.passengersCapacity,
      payment_type: paymentType,
    },
    _clear: true,
  });
};

export const trackSpanishLPFormSent = () => {
  window.dataLayer.push({
    event: 'formsent',
    formsent: {
      name: 'Contact Form - Spain',
      type: 'Contact Form',
      text: 'Nezávazně poptat',
    },
    _clear: true,
  });
};

export const getPageDataForDataLayer = (router) => {
  let type = null;

  switch (router.pathname) {
    case routes?.index?.template:
      type = 'homepage';
      break;

    case routes?.listingWizardEntry?.template:
      type = 'p2p';
      break;

    case routes?.search?.template:
      type = 'search';
      break;

    case routes?.mission?.template:
      type = 'about';
      break;

    case routes?.blog?.template:
      type = 'blog';
      break;

    case routes?.blogDetailPreview?.template:
      type = 'article';
      break;

    case routes?.faq?.template:
      type = 'contact';
      break;

    case routes?.landingSeptember?.template:
    case routes?.landingWeekend?.template:
    case routes?.protiproudu?.template:
    case routes?.landingMorava?.template:
    case routes?.landingChristmas?.template:
    case routes?.landingSpring?.template:
    case routes?.landingEastern?.template:
    case routes?.landingLowTatras?.template:
    case routes?.landingFirstMinute?.template:
    case routes?.landingGreece?.template:
    case routes?.landingAustria?.template:
      type = 'landing_page';
      break;

    case routes?.voucher?.template:
      type = 'eshop';
      break;

    case routes?.carDetail?.template:
      type = 'product';
      break;

    case routes?.tos?.template:
    case routes?.reclamationPage?.template:
    case routes?.voucherTos?.template:
    case routes?.privacy?.template:
    case routes?.cookies?.template:
      type = 'legal';
      break;

    case routes?.bookingDetail?.template:
    case routes?.checkinCustomer?.template:
    case routes?.checkoutCustomer?.template:
    case routes?.bookingKyc?.template:
      type = 'booking';
      break;

    case routes?.dynamicSegmentNew?.template:
    case routes?.dynamicSegmentPreviewNew?.template:
      type = 'dynamic';
      break;

    default:
      break;
  }

  if (type !== null) {
    return {
      ...(type !== null && { type }),
      title: document?.title?.replace('| Campiri.com', ''),
      url: document?.URL?.split('?')[0],
      path: router.asPath,
      referrer: document.referrer,
    };
  }
  return null;
};

export const trackLocalizationData = (settings: SettingsProps, user: UserReduxType, router) => {
  const isMobile = 'ontouchstart' in document.documentElement && /mobi/i.test(navigator.userAgent);
  const pageData = getPageDataForDataLayer(router);
  const site = codebase() === 'campiri' ? 'Campiri.com' : 'Freeway-Camper.com';

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'page_view',
      site,
      platform: 'web',
      environment: publicRuntimeConfig.APP_ENV,
      culture: `${settings.language}-${settings.country}`,
      language: settings.language,
      currency: settings.currency?.toUpperCase(),
      device: isMobile ? 'mobile' : 'desktop',
      ...(user.customerData?.guid && {
        user: {
          id: user.customerData.guid,
          type: 'Customer',
        },
      }),
      ...(pageData && {
        page: {
          ...pageData,
        },
      }),
    });
  }
};

export const trackListingWizardLocalizationData = (
  settings: SettingsProps,
  user: UserReduxType,
  router,
  lwStep: string
) => {
  const isMobile = 'ontouchstart' in document.documentElement && /mobi/i.test(navigator.userAgent);
  const site = codebase() === 'campiri' ? 'Campiri.com' : 'Freeway-Camper.com';

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'page_view',
      site,
      platform: 'web',
      environment: publicRuntimeConfig.APP_ENV,
      culture: `${settings.language}-${settings.country}`,
      language: settings.language,
      currency: settings.currency?.toUpperCase(),
      device: isMobile ? 'mobile' : 'desktop',
      ...(user.customerData?.guid && {
        user: {
          id: user.customerData.guid,
          type: 'Customer',
        },
      }),
      page: {
        type: 'p2p-wizard',
        ...(lwStep !== null && { step: lwStep }),
        title: document?.title?.replace('| Campiri.com', ''),
        url: document?.URL?.split('?')[0],
        path: router.asPath,
      },
    });
  }
};

export const trackLocalizationDataBooking = (
  settings: SettingsProps,
  user: UserReduxType,
  router,
  bookingStep: string
) => {
  const isMobile = 'ontouchstart' in document.documentElement && /mobi/i.test(navigator.userAgent);
  const site = codebase() === 'campiri' ? 'Campiri.com' : 'Freeway-Camper.com';

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'page_view',
      site,
      platform: 'web',
      environment: publicRuntimeConfig.APP_ENV,
      culture: `${settings.language}-${settings.country}`,
      language: settings.language,
      currency: settings.currency?.toUpperCase(),
      device: isMobile ? 'mobile' : 'desktop',
      ...(user.customerData?.guid && {
        user: {
          id: user.customerData.guid,
          name: `${user.customerData?.firstName} ${user.customerData?.lastName}`,
          email: user.customerData?.email,
          phone: user.customerData?.phone,
          type: 'Customer',
        },
      }),
      page: {
        type: 'booking',
        ...(bookingStep !== null && { step: bookingStep }),
        title: document?.title?.replace('| Campiri.com', ''),
        url: document?.URL?.split('?')[0],
        path: router.asPath,
      },
    });

    window.dataLayer.push({
      event: 'afterLoad',
      culture: `${settings.language}-${settings.country}`,
    });
  }
};

export const trackLocalizationDataLWEffect = (settings: SettingsProps, user: UserReduxType, router, state: string) => {
  useEffect(() => {
    trackListingWizardLocalizationData(settings, user, router, state);
  }, []);
};

export function useTrackLocalizationDataBooking(bookingStep: string) {
  const settings = useTypedSelector(settingsSelector);
  const user = useTypedSelector(userSelector);
  const router = useRouter();
  useEffect(() => {
    trackLocalizationDataBooking(settings, user, router, bookingStep);
  }, []);
}

export const trackLocalizationDataBookingEffect = (
  settings: SettingsProps,
  user: UserReduxType,
  router,
  bookingStep: string
) => {
  useEffect(() => {
    trackLocalizationDataBooking(settings, user, router, bookingStep);
  }, []);
};

export const trackShopifyProductClick = (title, price) => {
  if (!window?.dataLayer) return;
  if (!title || !price) return;

  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      list_id: '',
      list_name: 'eshop - vouchers',
      products: [
        {
          id: 1000,
          name: title,
          brand: 'Campiri',
          producer: 'Rental',
          categories: ['eshop', 'vouchers'],
          price,
        },
      ],
    },
    _clear: true,
  });
};

export const trackSpecialOfferBarClick = (heading, url, destinationUrl) => {
  window.dataLayer.push({
    event: 'click',
    click: {
      name: 'promo topbar',
      type: 'link',
      area: 'header',
      url,
      destinationUrl,
      text: heading,
    },

    _clear: true,
  });
};

export const trackHomepageBannerClick = (heading, url) => {
  window.dataLayer.push({
    event: 'click',
    click: {
      name: 'hp banner',
      type: 'banner',
      area: 'body',
      destinationUrl: url,
      text: heading,
    },

    _clear: true,
  });
};

export const trackHomepageSegmentsClick = (heading, url) => {
  window.dataLayer.push({
    event: 'click',
    click: {
      name: 'hp segment',
      type: 'segment',
      area: 'body',
      destinationUrl: url,
      text: heading,
    },

    _clear: true,
  });
};

export const getDaysDifference = (date1: string, date2: string) => {
  const parsedDate1 = dayjs(date1);
  const diff = parsedDate1.diff(dayjs(date2), 'days', false);
  return diff + 1;
};

export const trackSearchResultsItems = (carsToShow: Array<any>, segmentId?: number) => {
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      list_id: segmentId ?? 0,
      list_name: segmentId ? 'Segments' : 'Search page',
      products: carsToShow.map((item, idx) => ({
        id: item.guid,
        name: item.title,
        brand: item.manufacturer,
        producer: getProducerData(item?.insurance === InsuranceType.P2P, item.operator?.guid),
        size: item.passengersCapacity,
        categories: [item.vehicleType],
        price: item.minPrice?.total,
        quantity: 1,
        list_position: idx + 1,
        availability: item?.instantBookable ? 'instant booking' : 'on demand',
        program: getPogramTypeData(item.programType, item.operator?.guid),
      })),
    },
    _clear: true,
  });
};

export const trackReviewSent = (nps: number) => {
  window.dataLayer.push({
    event: 'customer_review',
    customer_review: {
      action: 'submitted',
      nps,
    },
    _clear: true,
  });
};
