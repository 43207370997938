import getConfig from 'next/config';
import { AxiosRequestConfig } from 'axios';
import { CustomerSignupCommand } from '~/libs/api/models/customer-signup-command';
import { CustomerLoginCommand } from '~/libs/api/models/customer-login-command';
import { AuthenticationMarketplaceApi } from '~/libs/api/apis/authentication-marketplace-api';
import { Configuration } from '~/libs/api/configuration';
import { CustomerChangePasswordCommand } from '~/libs/api/models/customer-change-password-command';
import { CustomerEmailLoginCommand } from '~/libs/api/models/customer-email-login-command';
import { CustomerFacebookSignupCommand } from '~/libs/api/models/customer-facebook-signup-command';
import { CustomerForgotPasswordCommand } from '~/libs/api/models/customer-forgot-password-command';
import { CustomerGoogleSignupCommand } from '~/libs/api/models/customer-google-signup-command';
import { UserMarketplaceApi } from '~/libs/api/apis/user-marketplace-api';

const { publicRuntimeConfig } = getConfig();

const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    const accessToken = window?.localStorage?.getItem('accessToken');
    return accessToken;
  } return null;
};

const getApiConfig = () => new Configuration({
  basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ''),
  accessToken: getAccessToken,
});

const apiConfiguration = getApiConfig();

const AUT_API = new AuthenticationMarketplaceApi(apiConfiguration, apiConfiguration.basePath);
const MARKETPLACE_API = new UserMarketplaceApi(apiConfiguration, apiConfiguration.basePath);

const authEmailSignup = async (campiriEmailLoginCommand?: CustomerEmailLoginCommand) => AUT_API.apiV1MarketplaceAuthenticationVerifyEmailPost(campiriEmailLoginCommand);
const verifyEmail = async (code?: string, options?: AxiosRequestConfig) => MARKETPLACE_API.apiV1MarketplaceMeUsersVerifyPost(code, options);

const authFacebookSingup = async (loginPayload?: CustomerFacebookSignupCommand) => AUT_API.apiV1MarketplaceAuthenticationFacebookSignupPost(loginPayload);

const authGoogleSingup = async (loginPayload?: CustomerGoogleSignupCommand) => AUT_API.apiV1MarketplaceAuthenticationGoogleSignupPost(loginPayload);

const authCustomerLogin = async (campiriLoginCommand?: CustomerLoginCommand) => AUT_API.apiV1MarketplaceAuthenticationLoginPost(campiriLoginCommand);

const authCustomerSignup = async (customerSignupCommand?: CustomerSignupCommand) => AUT_API.apiV1MarketplaceAuthenticationSignupPost(customerSignupCommand);

const authRefreshToken = async (refreshToken?: string) => AUT_API.apiV1MarketplaceMeAuthenticationRefreshtokensPost(refreshToken);

const authLostPasswordEmail = async (customerForgotPasswordCommand?: CustomerForgotPasswordCommand) => AUT_API.apiV1MarketplaceAuthenticationForgotPasswordPost(customerForgotPasswordCommand);

const authPasswordRecovery = async (customerChangePasswordCommand?: CustomerChangePasswordCommand) => AUT_API.apiV1MarketplaceAuthenticationChangePasswordPost(customerChangePasswordCommand);

const resendEmailVerification = async () => AUT_API.apiV1MarketplaceAuthenticationResendEmailVerificationPost();

export {
  authEmailSignup,
  authCustomerLogin,
  authCustomerSignup,
  authRefreshToken,
  authLostPasswordEmail,
  authPasswordRecovery,
  authFacebookSingup,
  authGoogleSingup,
  verifyEmail,
  resendEmailVerification,
};
