import { FC, useEffect } from 'react';
import globalAxios from 'axios';
import { getAccessToken } from '../components/auth/utils';

const AxiosInterceptor: FC = ({ children }) => {
  useEffect(() => {
    // globalAxios.defaults.headers['Accept-Language'] = 'cs-CZ';

    // Add a request interceptor
    globalAxios.interceptors.request.use(
      (config) => {
        const token = getAccessToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    // Add a response interceptor
    globalAxios.interceptors.response.use(
      (res) => res,
      async (error) => {
        // const { response } = error;
        const originalConfig = error.config;
        if (error && error?.response) {
          // Access Token was expired
          if (
            error.response?.config?.url.includes('authentication/login') ||
            error?.response?.config?.url.includes('authentication/signup')
          )
            return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return <>{children}</>;
};

export default AxiosInterceptor;
