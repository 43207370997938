import { RefObject } from 'react';
import * as t from '../types';

export interface IGoogleReviews {
  stars: number;
}

export interface IModalInterface {
  type: any;
  isVisible: boolean;
  params: any;
}

export type CookieBarVariantType = any;

export interface SettingsProps {
  specialOfferBarVisible: boolean;
  specialOfferBarRef: RefObject<HTMLDivElement> | null;
  loading: boolean;
  googleReviews: IGoogleReviews;
  modal: IModalInterface;
  desktopModal: any;
  desktopModalProps: any;
  language: any;
  country: any;
  currency: any;
  cookieBarVariant: CookieBarVariantType;
  cookieBarVisible: boolean;
  marketingCookies: boolean;
  analyticCookies: boolean;
  carDetailDidMount: boolean;
}

const main = (
  // eslint-disable-next-line default-param-last
  state: SettingsProps = {
    specialOfferBarVisible: false,
    specialOfferBarRef: null,
    loading: false,
    googleReviews: { stars: 4.9 },
    modal: {
      type: '',
      isVisible: false,
      params: {},
    },
    desktopModal: null,
    desktopModalProps: null,
    language: null,
    country: null,
    currency: null,
    cookieBarVariant: null,
    cookieBarVisible: false,
    marketingCookies: false,
    analyticCookies: false,
    carDetailDidMount: true,
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case t.SET_SPECIAL_OFFER_BAR_VISIBLE:
      return {
        ...state,
        specialOfferBarVisible: action.payload,
      };

    case t.SET_SPECIAL_OFFER_BAR_REF:
      return {
        ...state,
        specialOfferBarRef: action.payload,
      };

    case t.SET_GOOGLE_REVIEWS:
      return {
        ...state,
        googleReviews: action.payload,
      };

    case t.SHOW_MODAL:
      return {
        ...state,
        modal: action.payload,
      };

    case t.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case t.SET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };

    case t.SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };

    case t.HIDE_MODAL:
      return {
        ...state,
        modal: { params: {}, type: '', isVisible: false },
      };

    case t.SET_COOKIE_BAR_VARIANT:
      return {
        ...state,
        cookieBarVariant: action.payload,
      };

    case t.SET_COOKIE_BAR_VISIBLE:
      return {
        ...state,
        cookieBarVisible: action.payload,
      };

    case t.SET_MARKETING_COOKIES:
      return {
        ...state,
        marketingCookies: action.payload,
      };

    case t.SET_ANALYTIC_COOKIES:
      return {
        ...state,
        analyticCookies: action.payload,
      };

    case t.SET_DESKTOP_MODAL:
      return {
        ...state,
        desktopModal: action.payload.type,
        desktopModalProps: action.payload?.modalProps,
      };

    case t.SET_CAR_DETAIL_DID_MOUNT:
      return {
        ...state,
        carDetailDidMount: action.payload,
      };

    default:
      return { ...state };
  }
};

export default main;
