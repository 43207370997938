import dayjs from 'dayjs';
import { SearchInfoReduxData } from '../../redux/reducers/search';

// eslint-disable-next-line import/prefer-default-export
export const makeQueryForSearchFromHP = (
  searchInfo: SearchInfoReduxData,
  query: Record<any, any>,
  winterVersion = false
) => ({
  ...query,
  ...(searchInfo?.place?.place_name && { placeName: searchInfo.place.place_name }),
  ...(searchInfo?.place?.center?.[0] && { longitude: searchInfo.place.center[0] }),
  ...(searchInfo?.place?.center?.[1] && { latitude: searchInfo.place.center[1] }),
  ...(searchInfo.adults && { adults: searchInfo.adults }),
  ...(searchInfo.children && { children: searchInfo.children }),
  ...(searchInfo.selectedDays &&
    searchInfo.selectedDays.startDate && {
      dateFrom: dayjs(searchInfo.selectedDays.startDate).format('YYYY-MM-DD'),
    }),
  ...(searchInfo.selectedDays &&
    searchInfo.selectedDays.endDate && { dateTo: dayjs(searchInfo.selectedDays.endDate).format('YYYY-MM-DD') }),
  ...(winterVersion && { winterTires: 'true' }),
  ...(searchInfo.flexibleSearchOffset === 0 ? {} : { flexibleDate: searchInfo.flexibleSearchOffset }),
});
