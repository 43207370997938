import * as t from '../types';
import {
  authCustomerLogin,
  authCustomerSignup,
  authEmailSignup,
  authFacebookSingup,
  authGoogleSingup,
  authRefreshToken,
  verifyEmail,
} from '~/src/api/auth';
import { setSession } from '~/src/components/auth/utils';
import { getCurrentlyLoggedCustomer, updateUserData } from '~/src/api/user';
import { trackUserLoginDataLayer, tryCallDataLayerEvent } from '~/src/utils/dataLayer';
import { getLoggedOperatorInformation } from '~/src/api/operatorAccount';
import { setMessagingEnabled } from './inbox';
import { CustomerEmailLoginCommand } from '~/libs/api/models/customer-email-login-command';
import { CustomerLoginCommand } from '~/libs/api/models/customer-login-command';
import { CustomerSignupCommand } from '~/libs/api/models/customer-signup-command';

declare global {
  interface Window {
    gtag: any;
  }
}

const setApiErrorMessage = (error, dispatch, emailErrorHandler?: (x: string) => void) => {
  if (error?.response?.data?.errors) {
    if (error.response.data.errors[0].field === 'email_grant') {
      dispatch(
        userAutheticationError(
          '<span> Pro přihlášení na Campiri potřebujeme Váš email. Prosím povolte jeho zpřístupnění při přihlášení.  <br><br>Zkuste to prosím znovu kliknutím na tlačítko výše. </span>'
        )
      );
    }
    if (error.response.data.errors[0].field === 'Email' && emailErrorHandler) {
      emailErrorHandler(error.response.data.errors[0].message);
      dispatch(userAutheticationError(null));
    } else {
      dispatch(userAutheticationError(error.response.data.errors[0].message));
    }
  }
  dispatch(userAutheticationLoading(false));
};

export const signOut = () => (dispatch) => {
  window?.localStorage?.removeItem('accessToken');
  window?.localStorage?.removeItem('refreshToken');
  dispatch({
    type: t.SIGN_OUT,
  });
};

export const userEmailLogin = (authObj?: CustomerEmailLoginCommand) => async (dispatch) => {
  try {
    dispatch(userAutheticationLoading(true));

    const apiResponse = await authEmailSignup(authObj);

    if (apiResponse.data) {
      await verifyEmail(authObj?.token, { headers: { Authorization: `Bearer ${apiResponse.data.accessToken}` } });
      const { accessToken, refreshToken } = apiResponse.data;
      setSession(accessToken, refreshToken, authRefreshToken);
      dispatch(loginSuccess(true, 'credentials'));
      return true;
    }
    dispatch(userAutheticationError('User could not be logged in xxx'));
  } catch (error) {
    setApiErrorMessage(error, dispatch);
    console.log(error, 'on emailLogin error');
    return false;
  }
  return false;
};

export const userChangeDetails = (userData) => async (dispatch) =>
  updateUserData(userData).then((resp) => {
    if (String(resp.status).startsWith('2')) {
      dispatch(setUserData(userData));
    }
  });

export const refreshUserDataDetails = () => async (dispatch) =>
  getCurrentlyLoggedCustomer().then((resp) => {
    if (String(resp.status).startsWith('2')) {
      dispatch(setUserData(resp.data));
    }
  });

export const userPasswordLogin =
  (userData?: CustomerLoginCommand, fromLW = false) =>
  async (dispatch) => {
    try {
      dispatch(userAutheticationLoading(true));

      const apiResponse = await authCustomerLogin(userData);
      if (apiResponse.data) {
        const { accessToken, refreshToken } = apiResponse.data;
        setSession(accessToken, refreshToken, authRefreshToken);

        return dispatch(loginSuccess(true, 'credentials', fromLW));
      }
    } catch (error) {
      setApiErrorMessage(error, dispatch);
      console.log(error, 'login - error ');
      return false;
    }
    return false;
  };

export const userFacebookLogin =
  (
    token: string,
    setSocialLoginError: (any) => void,
    setShouldReauthorize: (boolean) => void,
    setIsFirstLogin: (boolean) => void
  ) =>
  async (dispatch) => {
    try {
      dispatch(userAutheticationLoading(true));

      const apiResponse = await authFacebookSingup({ token });

      if (String(apiResponse.status).startsWith('2')) {
        if (apiResponse.data) {
          const { accessToken, refreshToken, firstLogin } = apiResponse.data;
          setSession(accessToken, refreshToken, authRefreshToken);
          setIsFirstLogin(firstLogin);

          return dispatch(loginSuccess(true, 'Facebook'));
        }
      }
    } catch (error) {
      // setApiErrorMessage(error, dispatch);

      if (error.response.data.errors) {
        if (error.response.data.errors[0].field === 'email_grant') {
          setSocialLoginError(
            '<span> Pro přihlášení na Campiri potřebujeme Váš email. Prosím povolte jeho zpřístupnění při přihlášení.  <br><br>Zkuste to prosím znovu kliknutím na tlačítko výše. </span>'
          );
          setShouldReauthorize(true);
        } else {
          setSocialLoginError(error.response.data.errors[0].message);
        }
      }
      dispatch(userAutheticationLoading(false));
      return false;
    }
    dispatch(userAutheticationLoading(false));
    return false;
  };

export const userGoogleLogin =
  (token: string, setSocialLoginError: any, setIsFirstLogin: (boolean) => void) => async (dispatch) => {
    try {
      dispatch(userAutheticationLoading(true));

      const apiResponse = await authGoogleSingup({ token });
      if (apiResponse.data) {
        const { accessToken, refreshToken, firstLogin } = apiResponse.data;
        setSession(accessToken, refreshToken, authRefreshToken);
        setIsFirstLogin(firstLogin);
        return dispatch(loginSuccess(true, 'Google'));
      }
    } catch (error) {
      // setApiErrorMessage(error, dispatch);
      if (error?.response?.data?.errors) {
        setSocialLoginError(error.response.data.errors[0].message);
      }
      dispatch(userAutheticationLoading(false));
      return false;
    }
    dispatch(userAutheticationLoading(false));
    return false;
  };

export const userPasswordRegistration =
  (customerObj?: CustomerSignupCommand, emailErrorHandler?: (x: string) => void) => async (dispatch) => {
    try {
      dispatch(userAutheticationLoading(true));

      const apiResponse = await authCustomerSignup(customerObj);
      if (String(apiResponse.status).startsWith('2')) {
        return true;
      }

      dispatch(userAutheticationLoading(false));
    } catch (error) {
      setApiErrorMessage(error, dispatch, emailErrorHandler);
      dispatch(userAutheticationLoading(false));
      return false;
    }
    return false;
  };

export const userAutheticationLoading = (isLoading: boolean) => (dispatch) => {
  dispatch({
    type: t.AUTH_USER_LOADING,
    payload: isLoading,
  });
};

export const userAutheticationError = (error: string | null | any) => (dispatch) => {
  dispatch({
    type: t.USER_LOGIN_ERROR,
    payload: error,
  });
};

export const loginSuccess =
  (isFromForm?: boolean, method?: string, fromLW = false) =>
  async (dispatch) => {
    try {
      const userDataResp = await getCurrentlyLoggedCustomer();

      if (userDataResp?.data) {
        dispatch(setUserData(userDataResp.data));
      }

      if (isFromForm && userDataResp?.data) {
        tryCallDataLayerEvent(() =>
          trackUserLoginDataLayer(method, userDataResp?.data?.guid, fromLW ? 'Partner' : 'Customer')
        );
      }

      dispatch(userAutheticationError(null));
      dispatch({
        type: t.USER_LOGIN_SUCCESS,
      });

      dispatch(userAutheticationLoading(false));

      if (userDataResp?.data?.isOperator) {
        try {
          const operatorInfoResp = await getLoggedOperatorInformation();
          dispatch(setOperatorData(operatorInfoResp.data));

          if (operatorInfoResp?.data.messagingEnabled) {
            dispatch(setMessagingEnabled(true));
          } else {
            dispatch(setMessagingEnabled(false));
          }
        } finally {
          console.log('Operator finally fetched!');
        }
      }
      return userDataResp;
    } catch (e) {
      console.error(e);
    }
  };

export const setUserData = (userData: any) => (dispatch) => {
  if (typeof window?.gtag === 'function') {
    window?.gtag('set', { dimension2: userData.guid });
  }

  if (typeof window?.diffAnalytics !== 'undefined' && userData.guid) {
    window?.diffAnalytics.customerLoggedIn(userData.guid);
  }

  dispatch({
    type: t.SET_USER_DATA,
    payload: userData,
  });
};

export const setOperatorData = (operatorData: any) => (dispatch) => {
  dispatch({
    type: t.SET_OPERATOR_DATA,
    payload: operatorData,
  });
};

export const setAllDataRequired = (isRequired: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_ALL_DATA_REQUIRED,
    payload: isRequired,
  });
};

export const setUserProfilePicture = (pictureModel: any) => (dispatch) => {
  dispatch({
    type: t.SET_USER_PROFILE_PICTURE,
    payload: pictureModel,
  });
};
