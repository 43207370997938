import { CustomerBookingRequestQueryResult } from 'api/src/models/customer-booking-request-query-result';
import { CustomerBookingOfferQueryResult } from 'api/src/models/customer-booking-offer-query-result';
import { CustomerBookingIntentQueryResult } from 'api/src/models/customer-booking-intent-query-result';
import { CustomerBookingReservationQueryResult } from 'api/src/models/customer-booking-reservation-query-result';
import * as t from '../types';

export interface OnlinePamentInt {
  cardComplete: boolean;
  cvcComplete: boolean;
  expiryComplete: boolean;
}
export interface PaymentsInt {
  onlinePayment: OnlinePamentInt;
  blinkReady: boolean;
}
export interface BookingData {
  name: string | null;
  surname: string | null;
  birthDate: string | null;
  email: string | null;
  phone: string | null;
}
export type BookingDataType = (
  | CustomerBookingRequestQueryResult
  | CustomerBookingOfferQueryResult
  | CustomerBookingIntentQueryResult
  | CustomerBookingReservationQueryResult
) & {
  paid: number;
  depositAmount: number;
};

export interface BookingStateProp {
  step: null;
  data: BookingData;
  state: any | null;
  fetchedBookingData: BookingDataType | null;
  loading: boolean;
  loadingMessage: string | null;
  managerNote: string | null;
  payments: PaymentsInt;
  insuranceFilled: boolean;
  isOpenCheckout: boolean;
}

const booking = (
  // eslint-disable-next-line default-param-last
  state: BookingStateProp = {
    fetchedBookingData: null,
    loading: false,
    state: null,
    loadingMessage: null,
    data: {
      name: null,
      surname: null,
      birthDate: null,
      email: null,
      phone: null,
    },
    step: null,
    managerNote: null,
    payments: {
      onlinePayment: {
        cardComplete: false,
        cvcComplete: false,
        expiryComplete: false,
      },
      blinkReady: false,
    },
    insuranceFilled: false,
    isOpenCheckout: false,
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_NEW_BOOKING_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case t.SET_FETCHED_BOOKING_DATA:
      return {
        ...state,
        fetchedBookingData: action.payload,
      };

    case t.SET_IS_OPEN_CHECKOUT:
      return {
        ...state,
        isOpenCheckout: action.payload,
      };

    case t.ADD_BOOKING_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };

    case t.SET_BOOKING_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case t.SET_BOOKING_STATE:
      return {
        ...state,
        state: action.payload,
      };

    case t.SET_BOOKING_DATA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case t.SET_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.payload,
      };
    case t.SET_BOOKING_MANAGER_NOTE:
      return {
        ...state,
        managerNote: action.payload,
      };

    case t.SET_BOOKING_INSURANCE_FILLED:
      return {
        ...state,
        insuranceFilled: action.payload,
      };

    case t.SET_BOOKING_CANCELATION_PROTECTION:
      const localPayload = { total: action.payload };
      return {
        ...state,
        fetchedBookingData: { ...state.fetchedBookingData, cancellationProtectionPrice: localPayload },
      };

    case t.SET_ONLINE_PAYMENT_DATA:
      return {
        ...state,
        payments: {
          ...state.payments,
          onlinePayment: {
            ...state.payments.onlinePayment,
            ...action.payload,
          },
        },
      };

    case t.SET_BLINK_READY:
      return {
        ...state,
        payments: {
          ...state.payments,
          blinkReady: action.payload,
        },
      };

    default:
      return { ...state };
  }
};

export default booking;
