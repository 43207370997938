export const LOGIN_USER = 'LOGIN_USER';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const REGISTER_USER_PASSWORD = 'REGISTER_USER_PASSWORD';
export const REGISTER_USER_PASSWORD_SUCCESS = 'REGISTER_USER_PASSWORD_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const AUTH_USER_LOADING = 'AUTH_USER_LOADING';
export const SET_DESKTOP_MODAL = 'SET_DESKTOP_MODAL';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_OPERATOR_DATA = 'SET_OPERATOR_DATA';
export const SET_USER_PROFILE_PICTURE = 'SET_USER_PROFILE_PICTURE';
export const SIGN_OUT = 'SIGN_OUT';

export const SET_ALL_DATA_REQUIRED = 'SET_ALL_DATA_REQUIRED';
