// @ts-nocheck

import React, { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import '~/src/styles/flickity.css'; // flickity
import '~/src/styles/react-date-range.scss';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { AppProps } from 'next/app';
import { connect } from 'react-redux';

import Script from 'next/script';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { z } from 'zod';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GlobalStyle from '~/src/styles/global';
import { wrapper } from '~/src/redux/store';

import AuthWrapper from '~/src/AuthWrapper';
import { openLanguageModalRedirect } from '~/src/utils/language';
import { screenSelector } from '~/src/redux/selectors/screen';
import { trackUserAfter30SecondsOnPageHook } from '~/src/utils/analytics';
import { trackLocalizationData } from '~/src/utils/dataLayer';
import { handleCurrencyFromQueryHook } from '~/src/utils/currency';
import { Language, routes } from '~/src/utils/routing';

import { userSelector } from '~/src/redux/selectors/user';
import AxiosInterceptor from '~/src/utils/axiosInterceptor';
import { useLanguageAndCountrySetupOnFirstLoad } from '~/src/utils/app-utils/useLanguageAndCountrySetupOnFirstLoad';
import { useActivateLocale } from '~/src/utils/app-utils/useActivateLocale';
import { useLoadGTM } from '~/src/utils/app-utils/useLoadGTM';
import { zodErrorMap } from '~/src/utils/zodErrorMap';
import { useEmailVerification } from '~/src/utils/app-utils/useEmailVerification';
import { openDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { ModalType } from '~/src/components/modal/modalTypes';
import useWindowSize from '~/src/hooks/useWindowSize';
import { CodebaseProvider } from 'features/providers/CodebaseContext';
import { ThemeProvider } from 'styled-components';
import { queries } from 'ui/utils/breakpoints';
import { BreakpointProvider } from 'features/providers/BreakpointProvider';
import codebase from 'features/utils/codebase';
import fwcTheme from 'ui/design/tokens/themeHashedValuesFwc.json';
import campiriTheme from 'ui/design/tokens/themeHashedValuesCampiri.json';

import 'swiper/css';
import 'swiper/css/pagination';
import { SnackbarProvider } from 'notistack';
import Head from 'next/head';

import { messages as catalogCS } from 'campiri-translations/translations/public_web/cs/messages';
import { messages as catalogEN } from 'campiri-translations/translations/public_web/en/messages';
import { messages as catalogPL } from 'campiri-translations/translations/public_web/pl/messages';
import { messages as catalogSK } from 'campiri-translations/translations/public_web/sk/messages';
import { messages as catalogES } from 'campiri-translations/translations/public_web/es/messages';
import { messages as catalogDE } from 'campiri-translations/translations/public_web/de/messages';
import { messages as catalogIT } from 'campiri-translations/translations/public_web/it/messages';

i18n.load(Language.cs, catalogCS);
i18n.load(Language.en, catalogEN);
i18n.load(Language.sk, catalogSK);
i18n.load(Language.pl, catalogPL);
i18n.load(Language.es, catalogES);
i18n.load(Language.de, catalogDE);
i18n.load(Language.it, catalogIT);
i18n.activate('en');

const DesktopModal = dynamic(() => import('~/src/components/modal/DesktopModal'), { ssr: false });
const PhoneModal = dynamic(() => import('~/src/components/modal/PhoneModal'), { ssr: false });

const { publicRuntimeConfig } = getConfig();

const listingWizardRoutes = [
  routes.listingWizardOperatorInfo,
  routes.listingWizardCreatedOperatorInfo,
  routes.listingWizardOperatorInfoManager,
  routes.listingWizardVehicleInfo,
  routes.listingWizardVehicleInfoManager,
  routes.listingWizardPickUpLocation,
  routes.listingWizardPickUpLocationManager,
  routes.listingWizardRulesPolicies,
  routes.listingWizardRulesPoliciesManager,
  routes.listingWizardPricing,
  routes.listingWizardPricingManager,
  routes.listingWizardPhotos,
  routes.listingWizardPhotosManager,
  routes.listingWizardSummary,
  routes.listingWizardSummarManagery,
];

const bookingRoutes = [routes.bookingDetail, routes.managerBooking, routes.operatorBooking];

const MyApp = ({ Component, pageProps, router, settings, screen, user, dispatch, ...rest }: AppProps) => {
  const { height } = useWindowSize();
  const openAuthModalOnlyOnce = useRef(false);
  z.setErrorMap(zodErrorMap);

  if (publicRuntimeConfig.NODE_ENV !== 'development') {
    console.log = () => {};
  }

  openLanguageModalRedirect(router, screen, dispatch);

  useActivateLocale(router, dispatch);

  handleCurrencyFromQueryHook(router, settings, dispatch);

  useLanguageAndCountrySetupOnFirstLoad(router, dispatch);
  const shouldRedirectToLogin = useEmailVerification();
  useLoadGTM(settings);

  trackUserAfter30SecondsOnPageHook();

  useEffect(() => {
    if (settings.language && settings.currency && settings.country && !user.loading) {
      const lwTemplatesArr = listingWizardRoutes.map((r) => r.template);
      const bookingTemplatesArr = bookingRoutes.map((r) => r.template);

      if (lwTemplatesArr.includes(router.pathname)) {
        console.log('skip defaukt localzation data track', router);
      } else if (bookingTemplatesArr.includes(router.pathname)) {
        console.log('skip defaukt localzation data track', router);
      } else {
        trackLocalizationData(settings, user, router);
      }
    }
  }, [router.pathname, settings.language, settings.country, settings.currency, user.isAuthenticated, user.loading]);

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 36000000,
            retry: 2,
          },
        },
      })
  );

  if (shouldRedirectToLogin && !openAuthModalOnlyOnce.current) {
    openDesktopOrPhoneModal(ModalType.LOGIN, screen, dispatch);
    openAuthModalOnlyOnce.current = true;
  }
  const codebaseDomain = codebase() === 'campiri' ? 'www.campiri.com' : 'freeway-camper.com';

  const plausibleAnalyticsPrefix = publicRuntimeConfig.APP_ENV === 'production' ? '' : 'dev.';
  const plausibleCodebaseDomain = codebase() === 'campiri' ? 'campiri.com' : 'freeway-camper.com';
  const plausibleAnalyticsDomain = `${plausibleAnalyticsPrefix}${plausibleCodebaseDomain}`;

  useEffect(() => {
    const vh = height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [height]);

  return (
    <>
      <Head>
        <script
          defer={true}
          data-api={`https://${codebaseDomain}/plsbl/api/event`}
          data-domain={plausibleAnalyticsDomain}
          src={`https://${codebaseDomain}/plsbl/js/script.js`}
        />
      </Head>
      <Script
        async={true}
        id="google-gtm"
        defer={true}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line max-len
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${publicRuntimeConfig.GTM_ID}')`,
        }}
      />
      <Script
        id="google-platform"
        strategy="afterInteractive"
        src="https://apis.google.com/js/platform.js"
        async={true}
        defer={true}
      />
      <Script
        id="facebook-script"
        strategy="lazyOnload"
        async={true}
        defer={true}
        crossOrigin="anonymous"
        src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0&appId=${publicRuntimeConfig.FB_APP_ID}&autoLogAppEvents=1`}
        nonce="nl7VMElL"
      />

      <I18nProvider i18n={i18n}>
        <CodebaseProvider codeBase={codebase()}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider
              theme={{
                codebase: codebase(),
                ...(codebase() === 'fwc' ? fwcTheme : campiriTheme),
              }}
            >
              <GlobalStyle />
              <BreakpointProvider queries={queries}>
                <AuthWrapper>
                  <AxiosInterceptor>
                    <GoogleOAuthProvider clientId={publicRuntimeConfig.GOOGLE_CLIENT_ID}>
                      <SnackbarProvider>
                        <div id="modalPortal" />
                        <Component {...pageProps} />
                        <PhoneModal />
                        <DesktopModal />
                      </SnackbarProvider>
                    </GoogleOAuthProvider>
                  </AxiosInterceptor>
                </AuthWrapper>
              </BreakpointProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </CodebaseProvider>
      </I18nProvider>
    </>
  );
};

MyApp.getInitialProps = wrapper.getInitialAppProps((store) => async ({ Component, ctx }) => ({
  pageProps: {
    ...(Component.getInitialProps ? await Component.getInitialProps({ ...ctx, store }) : {}),
  },
}));

const mapStateToProps = (state) => ({
  settings: state.settings,
  screen: screenSelector(state),
  user: userSelector(state),
});

const connector = connect(mapStateToProps, (dispatch) => ({ dispatch }));

export default wrapper.withRedux(connector(MyApp));
