export const sizes = {
  width: {
    XS: {
      min: 600,
      max: 600,
    },
    SM: {
      min: 860,
      max: 860,
    },
    MD: {
      min: 1023,
      max: 1023,
    },
    LG: {
      min: 1440,
      max: 1440,
    },
    XL: {
      min: 1140,
      max: 1360,
    },
    XXL: {
      min: 1200,
      max: 1400,
    },
    HD: {
      max: 1700,
    },
  },
  height: {
    XS: {
      max: 575,
    },
    SM: {
      max: 680,
    },
  },
};

export const getMinQueryWidth = (minSize: number, unit = 'px') => `(min-width:${minSize + unit})`;
export const getMaxQueryWidth = (maxSize: number, unit = 'px') => `(max-width:${maxSize + unit})`;
export const getMinWidthQuery = (minSize: number, unit = 'px') =>
  `@media only screen and ${getMinQueryWidth(minSize, unit)}`;
export const getMaxWidthQuery = (maxSize: number, unit = 'px') =>
  `@media only screen and ${getMaxQueryWidth(maxSize, unit)}`;

/* media breakpoints */
export const mediaXsMax = getMaxWidthQuery(sizes.width.XS.max);
export const mediaXsMin = getMinWidthQuery(sizes.width.XS.min);
export const mediaSmMax = getMaxWidthQuery(sizes.width.SM.max);
export const mediaSmMin = getMinWidthQuery(sizes.width.SM.min);

export const mediaMdMin = getMinWidthQuery(sizes.width.MD.min);

export const mediaMdMax = getMaxWidthQuery(sizes.width.MD.max);

export const mediaLgMin = getMinWidthQuery(sizes.width.LG.min);
export const mediaLgMax = getMaxWidthQuery(sizes.width.LG.max);

export const mediaXlMin = getMinWidthQuery(sizes.width.XL.min);

export const queries = {
  xs: getMinQueryWidth(490),
  sm: getMinQueryWidth(sizes.width.MD.min),
  md: getMinQueryWidth(sizes.width.LG.min),
  lg: getMinQueryWidth(sizes.width.XL.min),
  xl: getMinQueryWidth(sizes.width.XXL.min),
  xxl: getMinQueryWidth(1440),
  xxxl: getMinQueryWidth(2560),
};

export const queriesKeys = {
  mediaXsMax,
  mediaXsMin,
  mediaSmMax,
  mediaSmMin,
  mediaMdMin,
  mediaMdMax,
  mediaLgMin,
  mediaLgMax,
} as const;
