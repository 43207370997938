const isFreeWay = process.env.NEXT_PUBLIC_APP_TYPE === 'fwc';

const routerDefinitions = {
  // add_caravan: {
  //   template: '/add-caravan',
  //   urls: {
  //     cs: '/pridejte-svuj-karavan',
  //     en: '/add-your-campervan',
  //     pl: '/dodaj-swojego-kampera',
  //     sk: '/pridajte-svoj-karavan',
  //   },
  //   isStatic: true,
  // },

  listingWizardEntry: {
    template: '/listing-wizard-entry',
    urls: {
      cs: '/pridejte-svuj-karavan',
      en: '/add-your-campervan',
      dd: '/anade-tu-autocaravana',
      pl: '/dodaj-swojego-kampera',
      sk: '/pridajte-svoj-karavan',
    },
    isStatic: true,
  },

  listingWizardLanding: {
    template: '/listing-wizard-landing',
    urls: {
      cs: '/proc-pronajimat-na-campiri',
      en: '/why-rent-on-campiri',
      es: '/porque-alquilar-en-campiri',
      pl: '/dlaczego-wynajmowac-na-campiri',
      sk: '/preco-prenajimat-na-campiri',
    },
    isStatic: true,
  },

  campiriRoadtrips: {
    template: '/roadtrips',
    urls: {
      cs: '/roadtripy',
      en: '/roadtrips',
    },
    isStatic: true,
  },

  firstTimeCampervaningLanding: {
    template: '/first-time-campervaning-landing',
    urls: {
      cs: '/poprve-obytnakem',
      pl: '/pierwszy-raz-kamperem-przewodnik',
    },
    isStatic: true,
  },

  goForExperiencesLP: {
    template: '/landing/goForExperiencesLP',
    urls: {
      cs: '/babi-leto-2022',
    },
    isStatic: true,
  },

  search: {
    template: '/search',
    urls: {
      cs: '/najdi-karavan',
      en: '/search',
      es: '/buscar',
      pl: '/szukaj-kampera',
      sk: '/najdi-karavan',
      it: '/search',
      de: '/search',
    },
    isStatic: true,
  },
  index: {
    template: '/',
    urls: {
      cs: '',
      en: '',
      es: '',
      pl: '',
      sk: '',
      it: '',
      de: '',
    },
    isStatic: true,
  },

  mission: {
    template: '/mission',
    urls: {
      cs: '/nase-mise',
      en: '/our-mission',
      es: '/nuestra-mision',
      pl: '/nasza-misja',
      sk: '/nasa-misia',
      it: '/nasa-misia',
      de: '/nasa-misia',
    },
    isStatic: true,
  },

  blog: {
    template: '/blogPage',
    urls: {
      cs: '/blog',
      pl: '/blog',
      sk: '/blog',
      en: '/blog',
      es: '/blog',
      it: '/blog',
      de: '/blog',
    },
    isStatic: true,
  },

  blogCategory: {
    template: '/blogPage/category/[categoryId]',
    routingTemplate: '/blogPage/category/categoryId?id=:id',
    urls: {
      cs: '/blog/:id',
      pl: '/blog/:id',
      sk: '/blog/:id',
      en: '/blog/:id',
      es: '/blog/:id',
      it: '/blog/:id',
      de: '/blog/:id',
    },
    isStatic: false,
  },

  blogDetailPreview: {
    template: '/blogPagePreview/[articleId]',
    routingTemplate: '/blogPagePreview/articleId?slug=:slug&id=:id',
    urls: {
      cs: '/:slug([A-Za-z0-9-]+)--clanek-:id([A-Za-z0-9-]+)/preview',
      pl: '/:slug([A-Za-z0-9-]+)--artykul-:id([A-Za-z0-9-]+)/preview',
      sk: '/:slug([A-Za-z0-9-]+)--clanok-:id([A-Za-z0-9-]+)/preview',
      en: '/:slug([A-Za-z0-9-]+)--article-:id([A-Za-z0-9-]+)/preview',
      es: '/:slug([A-Za-z0-9-]+)--articulo-:id([A-Za-z0-9-]+)/preview',
      it: '/:slug([A-Za-z0-9-]+)--articolo-:id([A-Za-z0-9-]+)/preview',
      de: '/:slug([A-Za-z0-9-]+)--artikel-:id([A-Za-z0-9-]+)/preview',
    },
    isStatic: false,
  },

  blogDetail: {
    template: '/blogPage/[articleId]',
    routingTemplate: '/blogPage/articleId?slug=:slug&id=:id',
    urls: {
      cs: '/:slug([A-Za-z0-9-]+)--clanek-:id([A-Za-z0-9-]+)',
      sk: '/:slug([A-Za-z0-9-]+)--clanok-:id([A-Za-z0-9-]+)',
      pl: '/:slug([A-Za-z0-9-]+)--artykul-:id([A-Za-z0-9-]+)',
      en: '/:slug([A-Za-z0-9-]+)--article-:id([A-Za-z0-9-]+)',
      es: '/:slug([A-Za-z0-9-]+)--articulo-:id([A-Za-z0-9-]+)',
      it: '/:slug([A-Za-z0-9-]+)--articolo-:id([A-Za-z0-9-]+)',
      de: '/:slug([A-Za-z0-9-]+)--artikel-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  faq: {
    template: '/faq',
    urls: {
      cs: '/podpora',
      en: '/support',
      es: '/apoyo',
      pl: '/pomoc',
      sk: '/podpora',
      it: '/domande-frequenti',
      de: '/haeufige-fragen',
    },
    isStatic: true,
  },

  landingSeptember: {
    template: '/landing/september',
    urls: {
      cs: '/akcni-nabidka-podzim-2021',
    },
    isStatic: true,
  },

  landingWeekend: {
    template: '/landing/weekend',
    urls: {
      cs: '/akce-eurovikend',
    },
    isStatic: true,
  },

  protiproudu: {
    template: '/landing/protiproudu',
    urls: {
      cs: '/protiproudu',
    },
    isStatic: true,
  },

  accountCompletion: {
    template: '/accountCompletion',
    urls: {
      cs: '/dokonceni-uctu',
      en: '/account-completion',
      es: '/completar-la-cuenta',
      pl: '/uzupelnienie-konta',
      sk: '/dokoncenie-uctu',
      it: '/completa-il-profilo',
      de: '/account-abschluss',
    },
    isStatic: false,
  },

  landingAustria: {
    template: '/landing/austria',
    urls: {
      cs: '/akcni-nabidka-rakousko',
    },
    isStatic: true,
  },

  landingMorava: {
    template: '/landing/morava',
    urls: {
      cs: '/akcni-nabidka-morava',
    },
    isStatic: true,
  },

  landingChristmas: {
    template: '/landing/christmas',
    urls: {
      cs: '/akcni-nabidka-zima',
    },
    isStatic: true,
  },

  landingSummmer: {
    template: '/landing/summer',
    urls: {
      cs: '/letni-sleva-2022',
    },
    isStatic: true,
  },

  landingB2B: {
    template: '/landing/b2b',
    urls: {
      cs: '/b2b',
      pl: '/b2b',
    },
    isStatic: true,
  },

  landingSummerCompetition: {
    template: '/landing/summerCompetition',
    urls: {
      cs: '/soutez-o-letni-dovolenou',
    },
    isStatic: true,
  },

  campiriSpain: {
    template: '/landing/campiriSpain',
    urls: {
      cs: '/campiri-malaga',
      pl: '/campiri-hiszpania',
      en: '/campiri-malaga',
    },
    isStatic: true,
  },

  campiriPortugal: {
    template: '/landing/campiriPortugal',
    urls: {
      cs: '/portugalsko',
    },
    isStatic: true,
  },

  landingSpring: {
    template: '/landing/spring',
    urls: {
      cs: '/jarni-prazdniny',
    },
    isStatic: true,
  },

  landingEastern: {
    template: '/landing/eastern',
    urls: {
      cs: '/velikonocni-akce',
    },
    isStatic: true,
  },

  landingEasternPL: {
    template: '/landing/easternPL',
    urls: {
      pl: '/promocje',
    },
    isStatic: true,
  },

  landingLowTatras: {
    template: '/landing/low_tatras',
    urls: {
      cs: '/akcni-nabidka-nizke-tatry',
    },
  },

  landingSixPlusOne: {
    template: '/landing/sixPlusOnePL',
    urls: {
      pl: '/letniapromocja',
    },
    isStatic: true,
  },

  landingFirstMinute: {
    template: '/landing/firstMinute',
    urls: {
      cs: '/akcni-nabidka-first-minute',
    },
    isStatic: true,
  },

  landingGreece: {
    template: '/landing/greece',
    urls: {
      cs: '/akcni-nabidka-recko',
      en: '/akcni-nabidka-recko',
    },
    isStatic: true,
  },

  landingCampersClub: {
    template: '/landing/campers_club',
    urls: {
      cs: '/campers-club',
    },
    isStatic: true,
  },

  landingSecondBirthdaySale: {
    template: '/landing/secondBirthdaySale',
    urls: {
      cs: '/druhe-narozeniny',
    },
    isStatic: true,
  },

  discoverLP: {
    template: '/landing/discoverLP',
    urls: {
      cs: '/pro-kazdou-prilezitost',
    },
    isStatic: true,
  },

  everyOcasionLP: {
    template: '/landing/everyOcasionLP',
    urls: {
      pl: '/kamper-na-kazda-okazje',
    },
    isStatic: true,
  },

  superGiveawayJuneLP: {
    template: '/landing/superGiveawayJuneLP',
    urls: {
      cs: '/velka-letni-soutez',
      en: '/super-giveaway-in-june',
      it: '/super-giveaway-di-giugno',
      de: '/mega-gewinnspiel-im-juni',
    },
    isStatic: false,
  },

  landingFromThreeDays: {
    template: '/landing/fromThreeDays',
    urls: {
      cs: '/od-tri-dnu',
    },
    isStatic: true,
  },

  operatorDashboard: {
    template: '/operator/dashboard',
    urls: {
      cs: '/operator/dashboard',
      en: '/operator/dashboard',
      es: '/operator/dashboard',
      pl: '/operator/dashboard',
      sk: '/operator/dashboard',
      it: '/operator/dashboard',
      de: '/operator/dashboard',
    },
  },

  operatorBookingsDashboard: {
    template: '/operator/bookingsDashboard',
    urls: {
      cs: '/operator/bookings-dashboard',
      en: '/operator/bookings-dashboard',
      es: '/operator/bookings-dashboard',
      pl: '/operator/bookings-dashboard',
      sk: '/operator/bookings-dashboard',
      it: '/operator/bookings-dashboard',
      de: '/operator/bookings-dashboard',
    },
  },

  renterDashboard: {
    template: '/renter/dashboard',
    urls: {
      cs: '/renter/dashboard',
      en: '/renter/dashboard',
      es: '/renter/dashboard',
      pl: '/renter/dashboard',
      sk: '/renter/dashboard',
      it: '/renter/dashboard',
      de: '/renter/dashboard',
    },
  },

  profileSettings: {
    template: '/profile/settings',
    urls: {
      cs: '/profile-settings',
      en: '/profile-settings',
      es: '/profile-settings',
      pl: '/profile-settings',
      sk: '/profile-settings',
      it: '/profile-settings',
      de: '/profile-settings',
    },
  },

  personalData: {
    template: '/profile/personaIlnformation',
    urls: {
      cs: '/profile-settings/personal-information',
      en: '/profile-settings/personal-information',
      es: '/profile-settings/personal-information',
      pl: '/profile-settings/personal-information',
      sk: '/profile-settings/personal-information',
      it: '/profile-settings/personal-information',
      de: '/profile-settings/personal-information',
    },
  },

  billingData: {
    template: '/profile/billingInformation',
    urls: {
      cs: '/profile-settings/billing-information',
      en: '/profile-settings/billing-information',
      es: '/profile-settings/billing-information',
      pl: '/profile-settings/billing-information',
      sk: '/profile-settings/billing-information',
      it: '/profile-settings/billing-information',
      de: '/profile-settings/billing-information',
    },
  },

  operatorInformation: {
    template: '/profile/operatorInformation',
    urls: {
      cs: '/profile-settings/operator-information',
      en: '/profile-settings/operator-information',
      es: '/profile-settings/operator-information',
      pl: '/profile-settings/operator-information',
      sk: '/profile-settings/operator-information',
      it: '/profile-settings/operator-information',
      de: '/profile-settings/operator-information',
    },
  },

  tos: {
    template: '/tos',
    urls: {
      cs: '/obchodni-podminky',
      en: '/tos',
      es: '/terminos-y-condiciones',
      pl: '/warunki-handlowe',
      sk: '/obchodne-podmienky',
      it: '/tc',
      de: '/agb',
    },
    isStatic: true,
  },

  reclamationPage: {
    template: '/reclamation',
    urls: {
      cs: '/reklamacni-rad',
      sk: '/reklamacny-poriadok',
    },
    isStatic: true,
  },

  voucherTos: {
    template: '/voucher_terms',
    urls: {
      cs: '/obchodni-podminky-voucher',
      en: '/tos',
      es: '/terminos-y-condiciones-voucher',
      pl: '/warunki-handlowe-voucher',
      sk: '/obchodne-podmienky-voucher',
      it: '/tos',
      de: '/tos',
    },
    isStatic: true,
  },
  privacy: {
    template: '/privacy',
    urls: {
      cs: '/ochrana-osobnich-udaju',
      en: '/privacy-terms',
      es: '/terminos-de-privacidad',
      pl: '/ochrona-danych-osobowych',
      sk: '/ochrana-osobnych-udajov',
      it: '/politica-sulla-privacy',
      de: '/datenschutzbestimmungen',
    },
    isStatic: true,
  },
  marketingAgreement: {
    template: '/marketing-agreement',
    urls: {
      cs: '/marketingovy-souhlas',
    },
    isStatic: true,
  },

  termsOfPromotion: {
    template: '/termsOfPromotion',
    urls: {
      pl: '/warunki-promocji',
    },
    isStatic: true,
  },

  cookies: {
    template: '/cookies',
    urls: {
      cs: '/cookies',
      en: '/cookies',
      es: '/cookies',
      sk: '/cookies',
      it: '/cookies',
      de: '/cookies',
    },
    isStatic: true,
  },

  password_recovery: {
    template: '/password-recovery',
    urls: {
      cs: '/obnova-hesla',
      en: '/reset-password',
      es: '/restablecer-contrasena',
      pl: '/odzyskiwanie-hasla',
      sk: '/obnova-hesla',
      it: '/reimposta-la-password',
      de: '/passwort-zuruecksetzen',
    },
    isStatic: true,
  },
  carDetail: {
    template: '/campervan/[carId]',
    routingTemplate: '/campervan/:id?name=:name&id=:id',
    urls: {
      cs: '/:name([a-z0-9-]*)--karavan-:id([0-9]+)',
      en: '/:name([a-z0-9-]*)--campervan-:id([0-9]+)',
      es: '/:name([a-z0-9-]*)--autocaravana-:id([0-9]+)',
      pl: '/:name([a-z0-9-]*)--samochod-:id([0-9]+)',
      sk: '/:name([a-z0-9-]*)--karavan-:id([0-9]+)',
      it: '/:name([a-z0-9-]*)--campervan-:id([0-9]+)',
      de: '/:name([a-z0-9-]*)--campervan-:id([0-9]+)',
    },
    isStatic: false,
  },

  bookingDetail: {
    template: '/booking/[bookingId]',
    routingTemplate: '/booking/id?id=:id',
    urls: {
      cs: '/booking-:id([A-Za-z0-9-]+)',
      en: '/booking-:id([A-Za-z0-9-]+)',
      es: '/booking-:id([A-Za-z0-9-]+)',
      pl: '/booking-:id([A-Za-z0-9-]+)',
      sk: '/booking-:id([A-Za-z0-9-]+)',
      it: '/booking-:id([A-Za-z0-9-]+)',
      de: '/booking-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  reviewBookingDetail: {
    template: '/review/[bookingId]',
    routingTemplate: '/review/id?id=:id',
    urls: {
      cs: '/review-:id([A-Za-z0-9-]+)',
      en: '/review-:id([A-Za-z0-9-]+)',
      es: '/review-:id([A-Za-z0-9-]+)',
      pl: '/review-:id([A-Za-z0-9-]+)',
      sk: '/review-:id([A-Za-z0-9-]+)',
      it: '/review-:id([A-Za-z0-9-]+)',
      de: '/review-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  reviewBookingDetailOperator: {
    template: '/operator/review/[bookingId]',
    routingTemplate: '/operator/review/id?id=:id',
    urls: {
      cs: '/operator/review-:id([A-Za-z0-9-]+)',
      en: '/operator/review-:id([A-Za-z0-9-]+)',
      es: '/operator/review-:id([A-Za-z0-9-]+)',
      pl: '/operator/review-:id([A-Za-z0-9-]+)',
      sk: '/operator/review-:id([A-Za-z0-9-]+)',
      it: '/operator/review-:id([A-Za-z0-9-]+)',
      de: '/operator/review-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  inbox: {
    template: '/inbox',
    urls: {
      cs: '/inbox',
      en: '/inbox',
      es: '/inbox',
      pl: '/inbox',
      sk: '/inbox',
    },
    isStatic: false,
  },

  operatorCalendar: {
    template: '/operatorCalendar',
    urls: {
      cs: '/operator/calendar',
      en: '/operator/calendar',
      es: '/operator/calendar',
      pl: '/operator/calendar',
      sk: '/operator/calendar',
      it: '/operator/calendar',
      de: '/operator/calendar',
    },
    isStatic: false,
  },

  bookingDetailTest: {
    template: '/booking/[bookingId]',
    routingTemplate: '/booking/id?id=:id',
    urls: {
      cs: '/bookingTest-:id([A-Za-z0-9-]+)',
      en: '/bookingTest-:id([A-Za-z0-9-]+)',
      es: '/bookingTest-:id([A-Za-z0-9-]+)',
      pl: '/bookingTest-:id([A-Za-z0-9-]+)',
      sk: '/bookingTest-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },
  checkin: {
    template: '/checkin/[bookingId]',
    routingTemplate: '/checkin/id?id=:id',
    urls: {
      cs: '/operator/check-in-:id([A-Za-z0-9]+)',
      en: '/operator/check-in-:id([A-Za-z0-9]+)',
      es: '/operator/check-in-:id([A-Za-z0-9]+)',
      pl: '/operator/check-in-:id([A-Za-z0-9]+)',
      sk: '/operator/check-in-:id([A-Za-z0-9]+)',
      it: '/operator/check-in-:id([A-Za-z0-9]+)',
      de: '/operator/check-in-:id([A-Za-z0-9]+)',
    },
  },

  checkinCustomer: {
    template: '/checkin/[bookingId]',
    routingTemplate: '/checkin/id?id=:id',
    urls: {
      cs: '/check-in-:id([A-Za-z0-9]+)',
      en: '/check-in-:id([A-Za-z0-9]+)',
      es: '/check-in-:id([A-Za-z0-9]+)',
      pl: '/check-in-:id([A-Za-z0-9]+)',
      sk: '/check-in-:id([A-Za-z0-9]+)',
      it: '/check-in-:id([A-Za-z0-9]+)',
      de: '/check-in-:id([A-Za-z0-9]+)',
    },
  },
  checkout: {
    template: '/checkout/[bookingId]',
    routingTemplate: '/checkout/id?id=:id',
    urls: {
      cs: '/operator/check-out-:id([A-Za-z0-9]+)',
      en: '/operator/check-out-:id([A-Za-z0-9]+)',
      es: '/operator/check-out-:id([A-Za-z0-9]+)',
      pl: '/operator/check-out-:id([A-Za-z0-9]+)',
      sk: '/operator/check-out-:id([A-Za-z0-9]+)',
      it: '/operator/check-out-:id([A-Za-z0-9]+)',
      de: '/operator/check-out-:id([A-Za-z0-9]+)',
    },
  },
  checkoutCustomer: {
    template: '/checkout/[bookingId]',
    routingTemplate: '/checkout/id?id=:id',
    urls: {
      cs: '/check-out-:id([A-Za-z0-9]+)',
      en: '/check-out-:id([A-Za-z0-9]+)',
      es: '/check-out-:id([A-Za-z0-9]+)',
      pl: '/check-out-:id([A-Za-z0-9]+)',
      sk: '/check-out-:id([A-Za-z0-9]+)',
      it: '/check-out-:id([A-Za-z0-9]+)',
      de: '/check-out-:id([A-Za-z0-9]+)',
    },
  },
  bookingKyc: {
    template: '/kyc/[bookingId]',
    routingTemplate: '/kyc/id?id=:id',
    urls: {
      cs: '/booking-:id([A-Za-z0-9-]+)/kyc',
      en: '/booking-:id([A-Za-z0-9-]+)/kyc',
      es: '/booking-:id([A-Za-z0-9-]+)/kyc',
      pl: '/booking-:id([A-Za-z0-9-]+)/kyc',
      sk: '/booking-:id([A-Za-z0-9-]+)/kyc',
      it: '/booking-:id([A-Za-z0-9-]+)/kyc',
      de: '/booking-:id([A-Za-z0-9-]+)/kyc',
    },
    isStatic: false,
  },

  managerBooking: {
    template: '/manager/booking/[bookingId]',
    routingTemplate: '/manager/booking/id?id=:id',
    urls: {
      cs: '/manager/booking-:id([A-Za-z0-9-]+)',
      en: '/manager/booking-:id([A-Za-z0-9-]+)',
      es: '/manager/booking-:id([A-Za-z0-9-]+)',
      pl: '/manager/booking-:id([A-Za-z0-9-]+)',
      sk: '/manager/booking-:id([A-Za-z0-9-]+)',
      it: '/manager/booking-:id([A-Za-z0-9-]+)',
      de: '/manager/booking-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  operatorBooking: {
    template: '/operator/booking/[bookingId]',
    routingTemplate: '/operator/booking/id?id=:id',
    urls: {
      cs: '/operator/booking-:id([A-Za-z0-9-]+)',
      en: '/operator/booking-:id([A-Za-z0-9-]+)',
      es: '/operator/booking-:id([A-Za-z0-9-]+)',
      pl: '/operator/booking-:id([A-Za-z0-9-]+)',
      sk: '/operator/booking-:id([A-Za-z0-9-]+)',
      it: '/operator/booking-:id([A-Za-z0-9-]+)',
      de: '/operator/booking-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  create_offer: {
    template: '/offers',
    urls: {
      cs: '/vytvorit-nabidku',
      en: '/create-offer',
      es: '/crear-oferta',
      pl: '/stworzyc-oferte',
      sk: '/vytvorit-nabidku',
      it: '/create-offer',
      de: '/create-offer',
    },
    isStatic: true,
  },

  create_offerOperator: {
    template: '/operator/offers',
    urls: {
      cs: '/operator/vytvorit-nabidku',
      en: '/operator/create-offer',
      es: '/operator/crear-oferta',
      pl: '/operator/stworzyc-oferte',
      sk: '/operator/vytvorit-nabidku',
      it: '/operator/create-offer',
      de: '/operator/create-offer',
    },
    isStatic: true,
  },

  voucher: {
    template: '/voucher',
    urls: {
      cs: '/eshop',
      pl: '/eshop',
    },
    isStatic: true,
  },
  vouchers: {
    template: '/voucherCz',
    urls: {
      cs: '/vouchery',
    },
    isStatic: true,
  },
  p2pAllInclusive: {
    template: '/landing/p2pAllInclusive',
    urls: {
      cs: '/p2p-all-inclusive',
    },
    isStatic: true,
  },

  campiriPlus: {
    template: '/landing/campiriPlus',
    urls: {
      cs: '/campiri-plus',
    },
    isStatic: true,
  },

  listingWizardOperatorInfo: {
    template: '/listingWizard/operatorInfo/[idLW]',
    routingTemplate: '/listingWizard/operatorInfo/idLW',
    urls: {
      cs: '/pridejte-svuj-karavan/info-o-provozovateli',
      en: '/listing-wizard/operator-info',
      es: '/anade-tu-autocaravana/operator-info',
      pl: '/wykaz-kreator/operator-info',
      sk: '/pridajte-svoj-karavan/info-o-prevadzkovatelovi',
      it: '/listing-wizard/operator-info',
      de: '/listing-wizard/operator-info',
    },
    isStatic: false,
  },

  listingWizardCreatedOperatorInfo: {
    template: '/listingWizard/operatorInfo/[idLW]',
    routingTemplate: '/listingWizard/operatorInfo/:id?id=:id',
    urls: {
      cs: '/pridejte-svuj-karavan/info-o-provozovateli/:id([A-Za-z0-9-]+)',
      en: '/listing-wizard/operator-info/:id([A-Za-z0-9-]+)',
      es: '/anade-tu-autocaravana/operator-info/:id([A-Za-z0-9-]+)',
      pl: '/wykaz-kreator/operator-info/:id([A-Za-z0-9-]+)',
      sk: '/pridajte-svoj-karavan/info-o-prevadzkovatelovi/:id([A-Za-z0-9-]+)',
      it: '/listing-wizard/operator-info/:id([A-Za-z0-9-]+)',
      de: '/listing-wizard/operator-info/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardOperatorInfoManager: {
    template: '/listingWizard/operatorInfo/[idLW]',
    routingTemplate: '/listingWizard/operatorInfo/:id?id=:id',
    urls: {
      cs: '/manager/pridejte-svuj-karavan/info-o-provozovateli/:id([A-Za-z0-9-]+)',
      en: '/manager/listing-wizard/operator-info/:id([A-Za-z0-9-]+)',
      es: '/manager/anade-tu-autocaravana/operator-info/:id([A-Za-z0-9-]+)',
      pl: '/manager/wykaz-kreator/operator-info/:id([A-Za-z0-9-]+)',
      sk: '/manager/pridajte-svoj-karavan/info-o-prevadzkovatelovi/:id([A-Za-z0-9-]+)',
      it: '/manager/listing-wizard/operator-info/:id([A-Za-z0-9-]+)',
      de: '/manager/listing-wizard/operator-info/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardVehicleInfo: {
    template: '/listingWizard/vehicleInfo/[idLW]',
    routingTemplate: '/listingWizard/vehicleInfo/:id?id=:id',

    urls: {
      cs: '/pridejte-svuj-karavan/info-o-vozu/:id([A-Za-z0-9-]+)',
      en: '/listing-wizard/vehicle-info/:id([A-Za-z0-9-]+)',
      es: '/anade-tu-autocaravana/informacion-del-vehiculo/:id([A-Za-z0-9-]+)',
      pl: '/wykaz-kreator/pojazd-info/:id([A-Za-z0-9-]+)',
      sk: '/pridajte-svoj-karavan/info-o-vozidle/:id([A-Za-z0-9-]+)',
      it: '/listing-wizard/vehicle-info/:id([A-Za-z0-9-]+)',
      de: '/listing-wizard/vehicle-info/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardVehicleInfoManager: {
    template: '/listingWizard/vehicleInfo/[idLW]',
    routingTemplate: '/listingWizard/vehicleInfo/:id?id=:id',
    urls: {
      cs: '/manager/pridejte-svuj-karavan/info-o-vozu/:id([A-Za-z0-9-]+)',
      en: '/manager/listing-wizard/vehicle-info/:id([A-Za-z0-9-]+)',
      es: '/manager/anade-tu-autocaravana/informacion-del-vehiculo/:id([A-Za-z0-9-]+)',
      pl: '/manager/wykaz-kreator/pojazd-info/:id([A-Za-z0-9-]+)',
      sk: '/manager/pridajte-svoj-karavan/info-o-vozidle/:id([A-Za-z0-9-]+)',
      it: '/manager/listing-wizard/vehicle-info/:id([A-Za-z0-9-]+)',
      de: '/manager/listing-wizard/vehicle-info/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardPickUpLocation: {
    template: '/listingWizard/pickUpLocation/[idLW]',
    routingTemplate: '/listingWizard/pickUpLocation/:id?id=:id',
    urls: {
      cs: '/pridejte-svuj-karavan/misto-vyzvednuti/:id([A-Za-z0-9-]+)',
      en: '/listing-wizard/pickup-location/:id([A-Za-z0-9-]+)',
      es: '/anade-tu-autocaravana/lugar-de-recogida/:id([A-Za-z0-9-]+)',
      pl: '/wykaz-kreator/odbior-lokalizacja/:id([A-Za-z0-9-]+)',
      sk: '/pridajte-svoj-karavan/miesto-vyzdvihnutia/:id([A-Za-z0-9-]+)',
      it: '/listing-wizard/pickup-location/:id([A-Za-z0-9-]+)',
      de: '/listing-wizard/pickup-location/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardPickUpLocationManager: {
    template: '/listingWizard/pickUpLocation/[idLW]',
    routingTemplate: '/listingWizard/pickUpLocation/:id?id=:id',

    urls: {
      cs: '/manager/pridejte-svuj-karavan/misto-vyzvednuti/:id([A-Za-z0-9-]+)',
      en: '/manager/listing-wizard/pickup-location/:id([A-Za-z0-9-]+)',
      es: '/manager/anade-tu-autocaravana/lugar-de-recogida/:id([A-Za-z0-9-]+)',
      pl: '/manager/wykaz-kreator/odbior-lokalizacja/:id([A-Za-z0-9-]+)',
      sk: '/manager/pridajte-svoj-karavan/miesto-vyzdvihnutia/:id([A-Za-z0-9-]+)',
      it: '/manager/listing-wizard/pickup-location/:id([A-Za-z0-9-]+)',
      de: '/manager/listing-wizard/pickup-location/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardRulesPolicies: {
    template: '/listingWizard/rulesPolicies/[idLW]',
    routingTemplate: '/listingWizard/rulesPolicies/:id?id=:id',
    urls: {
      cs: '/pridejte-svuj-karavan/pravidla-zasady/:id([A-Za-z0-9-]+)',
      en: '/listing-wizard/rules-and-policies/:id([A-Za-z0-9-]+)',
      es: '/anade-tu-autocaravana/reglas-y-politicas/:id([A-Za-z0-9-]+)',
      pl: '/wykaz-kreator/przepisy-zasady/:id([A-Za-z0-9-]+)',
      sk: '/pridajte-svoj-karavan/pravidla-a-zasady/:id([A-Za-z0-9-]+)',
      it: '/listing-wizard/rules-and-policies/:id([A-Za-z0-9-]+)',
      de: '/listing-wizard/rules-and-policies/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardRulesPoliciesManager: {
    template: '/listingWizard/rulesPolicies/[idLW]',
    routingTemplate: '/listingWizard/rulesPolicies/:id?id=:id',
    urls: {
      cs: '/manager/pridejte-svuj-karavan/pravidla-zasady/:id([A-Za-z0-9-]+)',
      en: '/manager/listing-wizard/rules-and-policies/:id([A-Za-z0-9-]+)',
      es: '/manager/anade-tu-autocaravana/reglas-y-politicas/:id([A-Za-z0-9-]+)',
      pl: '/manager/wykaz-kreator/przepisy-zasady/:id([A-Za-z0-9-]+)',
      sk: '/manager/pridajte-svoj-karavan/pravidla-a-zasady/:id([A-Za-z0-9-]+)',
      it: '/manager/listing-wizard/rules-and-policies/:id([A-Za-z0-9-]+)',
      de: '/manager/listing-wizard/rules-and-policies/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardPricing: {
    template: '/listingWizard/pricing/[idLW]',
    routingTemplate: '/listingWizard/pricing/:id?id=:id',
    urls: {
      cs: '/pridejte-svuj-karavan/cena/:id([A-Za-z0-9-]+)',
      en: '/listing-wizard/pricing/:id([A-Za-z0-9-]+)',
      es: '/anade-tu-autocaravana/precios/:id([A-Za-z0-9-]+)',
      pl: '/wykaz-kreator/wycena/:id([A-Za-z0-9-]+)',
      sk: '/pridajte-svoj-karavan/cena/:id([A-Za-z0-9-]+)',
      it: '/listing-wizard/pricing/:id([A-Za-z0-9-]+)',
      de: '/listing-wizard/pricing/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardPricingManager: {
    template: '/listingWizard/pricing/[idLW]',
    routingTemplate: '/listingWizard/pricing/:id?id=:id',
    urls: {
      cs: '/manager/pridejte-svuj-karavan/cena/:id([A-Za-z0-9-]+)',
      en: '/manager/listing-wizard/pricing/:id([A-Za-z0-9-]+)',
      es: '/manager/anade-tu-autocaravana/precios/:id([A-Za-z0-9-]+)',
      pl: '/manager/wykaz-kreator/wycena/:id([A-Za-z0-9-]+)',
      sk: '/manager/pridajte-svoj-karavan/cena/:id([A-Za-z0-9-]+)',
      it: '/manager/listing-wizard/pricing/:id([A-Za-z0-9-]+)',
      de: '/manager/listing-wizard/pricing/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardPhotos: {
    template: '/listingWizard/photos/[idLW]',
    routingTemplate: '/listingWizard/photos/:id?id=:id',
    urls: {
      cs: '/pridejte-svuj-karavan/fotky/:id([A-Za-z0-9-]+)',
      en: '/listing-wizard/photos/:id([A-Za-z0-9-]+)',
      es: '/anade-tu-autocaravana/fotos/:id([A-Za-z0-9-]+)',
      pl: '/wykaz-kreator/zdjecia/:id([A-Za-z0-9-]+)',
      sk: '/pridajte-svoj-karavan/fotografie/:id([A-Za-z0-9-]+)',
      it: '/listing-wizard/photos/:id([A-Za-z0-9-]+)',
      de: '/listing-wizard/photos/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardPhotosManager: {
    template: '/listingWizard/photos/[idLW]',
    routingTemplate: '/listingWizard/photos/:id?id=:id',
    urls: {
      cs: '/manager/pridejte-svuj-karavan/fotky/:id([A-Za-z0-9-]+)',
      en: '/manager/listing-wizard/photos/:id([A-Za-z0-9-]+)',
      es: '/manager/anade-tu-autocaravana/fotos/:id([A-Za-z0-9-]+)',
      pl: '/manager/wykaz-kreator/zdjecia/:id([A-Za-z0-9-]+)',
      sk: '/manager/pridajte-svoj-karavan/fotografie/:id([A-Za-z0-9-]+)',
      it: '/manager/listing-wizard/photos/:id([A-Za-z0-9-]+)',
      de: '/manager/listing-wizard/photos/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardSummary: {
    template: '/listingWizard/summary/[idLW]',
    routingTemplate: '/listingWizard/summary/:id?id=:id',
    urls: {
      cs: '/pridejte-svuj-karavan/shrnuti/:id([A-Za-z0-9-]+)',
      en: '/listing-wizard/summary/:id([A-Za-z0-9-]+)',
      es: '/anade-tu-autocaravana/resumen/:id([A-Za-z0-9-]+)',
      pl: '/wykaz-kreator/podsumowanie/:id([A-Za-z0-9-]+)',
      sk: '/pridajte-svoj-karavan/zhrnutie/:id([A-Za-z0-9-]+)',
      it: '/listing-wizard/summary/:id([A-Za-z0-9-]+)',
      de: '/listing-wizard/summary/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  listingWizardSummarManagery: {
    template: '/listingWizard/summary/[idLW]',
    routingTemplate: '/listingWizard/summary/:id?id=:id',
    urls: {
      cs: '/manager/pridejte-svuj-karavan/shrnuti/:id([A-Za-z0-9-]+)',
      en: '/manager/listing-wizard/summary/:id([A-Za-z0-9-]+)',
      es: '/manager/anade-tu-autocaravana/resumen/:id([A-Za-z0-9-]+)',
      pl: '/manager/wykaz-kreator/podsumowanie/:id([A-Za-z0-9-]+)',
      sk: '/manager/pridajte-svoj-karavan/zhrnutie/:id([A-Za-z0-9-]+)',
      it: '/manager/listing-wizard/summary/:id([A-Za-z0-9-]+)',
      de: '/manager/listing-wizard/summary/:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  dynamicSegmentNew: {
    template: '/dynamic/[segmentSlug]',
    routingTemplate: '/dynamic/segmentSlug?slug=:slug&id=:id',
    urls: {
      cs: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)',
      sk: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)',
      en: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)',
      pl: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)',
      es: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)',
      it: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)',
      de: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)',
    },
    isStatic: false,
  },

  dynamicSegmentPreviewNew: {
    template: '/dynamicPreview/[segmentSlug]',
    routingTemplate: '/dynamicPreview/segmentSlug?slug=:slug&id=:id',
    urls: {
      cs: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)/preview',
      sk: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)/preview',
      en: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)/preview',
      pl: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)/preview',
      es: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)/preview',
      it: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)/preview',
      de: '/:slug([A-Za-z0-9-]+)--seg-:id([A-Za-z0-9-]+)/preview',
    },
    isStatic: false,
  },
};

const cultureTokens = {
  'cs-cz': 'cs-cz',
  'cs-pl': 'cs-pl',
  'cs-sk': 'cs-sk',
  'cs-es': 'cs-es',
  'cs-de': 'cs-de',
  'cs-it': 'cs-it',

  'pl-pl': 'pl-pl',
  'pl-cz': 'pl-cz',
  'pl-sk': 'pl-sk',
  'pl-es': 'pl-es',
  'pl-de': 'pl-de',
  'pl-it': 'pl-it',

  'en-cz': 'en-cz',
  'en-sk': 'en-sk',
  'en-pl': 'en-pl',
  'en-es': 'en-es',
  'en-de': 'en-de',
  'en-it': 'en-it',

  'sk-cz': 'sk-cz',
  'sk-sk': 'sk-sk',
  'sk-pl': 'sk-pl',
  'sk-es': 'sk-es',
  'sk-de': 'sk-de',
  'sk-it': 'sk-it',

  'es-cz': 'es-cz',
  'es-sk': 'es-sk',
  'es-pl': 'es-pl',
  'es-es': 'es-es',
  'es-de': 'es-de',
  'es-it': 'es-it',

  'it-cz': 'it-cz',
  'it-sk': 'it-sk',
  'it-pl': 'it-pl',
  'it-es': 'it-es',
  'it-de': 'it-de',
  'it-it': 'it-it',

  'de-cz': 'de-cz',
  'de-sk': 'de-sk',
  'de-pl': 'de-pl',
  'de-es': 'de-es',
  'de-de': 'de-de',
  'de-it': 'de-it',
};

const supportedTokens = [
  !isFreeWay && 'cs-cz',
  !isFreeWay && 'en-cz',
  !isFreeWay && 'sk-sk',
  !isFreeWay && 'en-sk',
  !isFreeWay && 'pl-pl',
  !isFreeWay && 'en-pl',
  !isFreeWay && 'es-es',
  !isFreeWay && 'en-es',
  isFreeWay && 'de-de',
  isFreeWay && 'en-de',
  isFreeWay && 'it-it',
  isFreeWay && 'en-it',
].filter(Boolean);

const cultureRedirects = {
  'cz-cs': 'cs-cz',

  'cs-pl': 'en-pl',
  'cs-sk': 'en-sk',
  'es-sk': 'en-sk',

  'pl-cz': 'en-cz',
  'pl-sk': 'en-sk',
  'pl-es': 'en-es',

  'sk-cz': 'en-cz',
  'sk-pl': 'en-pl',
  'sk-es': 'en-es',
};

exports.cultureRedirects = cultureRedirects;
exports.supportedTokens = supportedTokens;
exports.routerDefinitions = routerDefinitions;
exports.cultureTokens = cultureTokens;
