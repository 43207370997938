import { Dispatch } from 'redux';

export const setSearchAdultsCount = (count: number) => (dispatch: Dispatch) => {
  dispatch({
    type: 'SET_SEARCH_ADULTS_COUNT',
    payload: count,
  });
};

export const setSearchChildrenCount = (count: number) => (dispatch: Dispatch) => {
  dispatch({
    type: 'SET_SEARCH_CHILDREN_COUNT',
    payload: count,
  });
};

export const setSearchPlace = (place: any) => (dispatch: Dispatch) => {
  dispatch({
    type: 'SET_SEARCH_PLACE',
    payload: place,
  });
};

export const setSearchDates = (dates: any) => (dispatch: Dispatch) => {
  dispatch({
    type: 'SET_SEARCH_DATES',
    payload: dates,
  });
};

export const setFlexibleSearchOffset = (offset: number) => (dispatch: Dispatch) => {
  dispatch({
    type: 'SET_FLEXIBLE_SEARCH_OFFSET',
    payload: offset,
  });
};
