import { hideModal, showDesktopModal, showModal } from '~/src/redux/actions/settings';

// eslint-disable-next-line import/no-cycle
import { ModalType } from './modalTypes';
import { TypedIBrowser } from '~/src/redux/reducers/rootReducer';

const openDesktopOrPhoneModal = (modalType: ModalType, screen: TypedIBrowser, dispatch, modalProps = null) => {
  if (!screen.lessThan.large) {
    dispatch(showDesktopModal(modalType, modalProps));
  } else {
    dispatch(showModal(modalType, modalProps));
  }
};

const hideDesktopOrPhoneModal = (screen, dispatch) => {
  if (!screen.lessThan.large) {
    dispatch(showDesktopModal(null));
  } else {
    dispatch(hideModal());
  }
};

export { openDesktopOrPhoneModal, hideDesktopOrPhoneModal };
