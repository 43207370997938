const getFormattedPassengers = (count: number) => {
  switch (count) {
    case 1:
      return '1 osoba';
    case 2:
    case 3:
    case 4:
      return `${count} osoby`;
    default:
      return `${count} osob`;
  }
};

const getSpacedNumber = (num: number, replaceValue: string = ' ') =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, replaceValue);
const capitalizeString = (string?: string) =>
  string
    ? string
        .toString()
        .split(' ')
        .map(str => str[0].toUpperCase() + str.slice(1))
        .join(' ')
    : '';

const slugify = (...args: Array<string>): string => {
  const value = args.join(' ');

  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, '-');
};

const toTitleCase = (value = ''): string => {
  const result = value.toString().replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const convertFromEnumToLowerCase = (string: string) =>
  string
    ?.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join(' ');
const deletePartOfStingBefore = (string: string, deleteBefore: Array<string>) => {
  const stringArr = string?.split(' ');
  let latestIndex = 0;
  stringArr?.forEach((str, i) => {
    if (deleteBefore.includes(str)) latestIndex = i + 1;
  });
  return stringArr?.slice(latestIndex).join(' ');
};

export {
  slugify,
  capitalizeString,
  getFormattedPassengers,
  getSpacedNumber,
  toTitleCase,
  convertFromEnumToLowerCase,
  deletePartOfStingBefore,
};
