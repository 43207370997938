export const SET_NEW_BOOKING_DATA = 'SET_NEW_BOOKING_DATA';
export const SET_BOOKING_STEP = 'SET_BOOKING_STEP';
export const ADD_BOOKING_DATA = 'ADD_BOOKING_DATA';
export const SET_FETCHED_BOOKING_DATA = 'SET_FETCHED_BOOKING_DATA';
export const SET_BOOKING_DATA_LOADING = 'SET_BOOKING_DATA_LOADING';
export const SET_IS_OPEN_CHECKOUT = 'SET_IS_OPEN_CHECKOUT';
export const SET_BOOKING_STATE = 'SET_BOOKING_STATE';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const SET_BOOKING_MANAGER_NOTE = 'SET_BOOKING_MANAGER_NOTE';
export const SET_BOOKING_INSURANCE_FILLED = 'SET_BOOKING_INSURANCE_FILLED';
export const SET_BOOKING_CANCELATION_PROTECTION = 'SET_BOOKING_CANCELATION_PROTECTION';
export const SET_BLINK_READY = 'SET_BLINK_READY';
export const SET_ONLINE_PAYMENT_DATA = 'SET_ONLINE_PAYMENT_DATA';
