import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { verifyEmail } from '~/src/api/auth';
import { UserStatus } from '~/libs/api/models/user-status';
import { removeEmailVerification } from '~/src/utils/query';
import { useTypedSelector } from '~/src/redux/store';
import { userSelector } from '~/src/redux/selectors/user';

export const useEmailVerification = () => {
  const router = useRouter();
  const user = useTypedSelector(userSelector);
  const [shouldRedirectToAuth, setShouldRedirectToAuth] = useState<boolean>(false);
  const onlyOnce = useRef(false);

  useEffect(() => {
    if (!onlyOnce.current && router.query?.source === 'email' && !!router.query?.code && !user.loading) {
      if (!user.isAuthenticated) setShouldRedirectToAuth(true);
      else if (user?.customerData?.status !== UserStatus.Verified) {
        onlyOnce.current = true;
        verifyEmail(router.query.code as string)
          .then(() => {
            removeEmailVerification();
          })
          .catch(e => console.error(e))
          .finally(() => setShouldRedirectToAuth(false));
      }
    } else {
      setShouldRedirectToAuth(false);
    }
  }, [user]);

  return shouldRedirectToAuth;
};
