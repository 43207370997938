import { useEffect, useRef } from 'react';
import { SettingsProps } from '~/src/redux/reducers/settings';

export const useLoadGTM = (settings: SettingsProps) => {
  const fired = useRef(false);
  const loadGTMScript = () => {
    if (fired.current || !settings.language) return;

    window.dataLayer.push({
      event: 'afterLoad',
      culture: `${settings.language}-${settings.country}`,
    });
    fired.current = true;
  };
  useEffect(() => {
    window.addEventListener('scroll', loadGTMScript, { once: true, passive: true });
    window.addEventListener('keydown', loadGTMScript, { once: true });
    window.addEventListener('mouseover', loadGTMScript, { once: true });
    console.log('gtm-successfully added to event listnener');

    return () => {
      window.removeEventListener('scroll', loadGTMScript);
      window.removeEventListener('keydown', loadGTMScript);
      window.removeEventListener('mouseover', loadGTMScript);
    };
  }, [fired.current, settings]);
};
