import objectPath from 'object-path';
import { ThemeValueResult } from 'styled-theming';
import { DefaultTheme, FlattenInterpolation } from 'styled-components';
import { CodeBaseType } from 'features/providers/CodebaseContext';

export type ValueNode = {
  type: string;
  value: string | number | Record<string, string | number>;
};

export enum Codebase {
  Campiri = 'campiri',
  FWC = 'fwc',
  Shared = 'shared',
}

export type CSSValue = string | Record<string, string>;

export type AllowedThemeValues = keyof DefaultTheme;

export type NestedThemePaths = AllowedThemeValues;

type VariantSet<
  TypesArray extends string[],
  Index extends number = 0,
  Indexes extends number[] = [1, 2, 3, 4, 5, 6],
> = {
  [key in TypesArray[Index]]: VariantSet<TypesArray, Indexes[Index]>;
};

export const propsBasedVariants =
  <
    ComponentProps,
    TypesArray extends string[],
    PropNames extends Array<string & keyof ComponentProps> = Array<string & keyof ComponentProps>,
  >(
    propNames: PropNames,
    variants: VariantSet<TypesArray>
  ): ((props: ComponentProps) => ThemeValueResult) =>
  (props) => {
    let propValues;
    try {
      // get actual prop values from their names
      propValues = propNames.map((propName) => {
        const propValue = props[propName];
        if (propValue === undefined) {
          // TODO handle optional props
          throw new Error(`Prop "${propName}" was not defined on the component.`);
        }
        return propValue;
      });
    } catch (e) {
      console.error(e);
      // if any prop is not defined return variant as undefined
      return undefined;
    }
    const path = propValues.join('.');
    const variant = objectPath.get(variants, path);

    if (typeof variant === 'function') {
      return variant(props);
    }
    return variant;
  };

/** @deprecated */
export const resolveInCodebase =
  (codebase: CodeBaseType, value: NestedThemePaths | FlattenInterpolation<any>) =>
  ({ theme }: { theme: DefaultTheme }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (theme.codebase !== codebase) return null;
    return value;
  };

/** @deprecated */
export const resolveThemeValue = (
  themeKeyOrValue: number | undefined | NestedThemePaths | string,
  campiriFallback?: string,
  passedTheme?: DefaultTheme
) => {
  const isFWC = process.env.NEXT_PUBLIC_APP_TYPE === 'fwc';
  if (themeKeyOrValue === undefined) {
    return undefined;
  }
  if (typeof themeKeyOrValue === 'number') {
    return themeKeyOrValue;
  }
  if ((themeKeyOrValue as string).match(/[A-F0-9]{32}$/i)) {
    if (passedTheme) return passedTheme[themeKeyOrValue as AllowedThemeValues];
    return ({ theme }: { theme: DefaultTheme }) =>
      isFWC || campiriFallback === undefined ? theme[themeKeyOrValue as AllowedThemeValues] : campiriFallback;
  }
  return themeKeyOrValue;
};
