import jwtDecode from 'jwt-decode';
import { RefreshAccessTokenCommandResult } from 'api/src';

type RefreshFunctionType = (refreshToken: string) => Promise<{ data: RefreshAccessTokenCommandResult }>;
const refreshSession = async (authRefreshToken: RefreshFunctionType) => {
  const refreshToken = window.localStorage?.getItem('refreshToken');

  if (refreshToken) {
    const refreshTokenResponse = await authRefreshToken(refreshToken);
    const dto = refreshTokenResponse.data;
    setSession(dto.accessToken as string, dto.refreshToken as string, authRefreshToken);
  } else {
    console.warn('refresh token is invalid...');
  }
};

export const getAccessToken = () => {
  const accessToken = window?.localStorage?.getItem('accessToken');

  return accessToken;
};

export const getTokenExpiration = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  try {
    const decoded = jwtDecode(accessToken);
    return (decoded as { exp: any }).exp;
  } catch {
    return false;
  }
};

let tokenRefreshScheduler: number | undefined;
const scheduleTokenRefresh = (accessToken: string, authRefreshToken: RefreshFunctionType) => {
  if (accessToken) {
    const expiresIn = getTokenExpiration(accessToken);
    const currentTime = Date.now() / 1000;
    const remainingTime = expiresIn - currentTime;
    const runAt = Math.floor(remainingTime * parseFloat('0.5') * 1000);

    // eslint-disable-next-line no-console
    console.log(`Refresh token in ${runAt / 1000}s /// ${runAt / 60000}m`);

    clearTimeout(tokenRefreshScheduler);
    tokenRefreshScheduler = setTimeout(async () => refreshSession(authRefreshToken), runAt) as unknown as number;
  }
};

export const setSession = (accessToken: string, refreshToken: string, authRefreshToken: RefreshFunctionType) => {
  if (accessToken) {
    localStorage?.setItem('accessToken', accessToken);
    localStorage?.setItem('refreshToken', refreshToken);

    scheduleTokenRefresh(accessToken, authRefreshToken);
  } else {
    window?.localStorage?.removeItem('accessToken');
    window?.localStorage?.removeItem('refreshToken');
  }
};
