import { useEffect } from 'react';
import { setCurrency } from '../redux/actions/settings';
import { Country, Currency } from './routing';
import { getCurrencyCodeString } from 'features/utils/currency';

export const getCurrencyFromCountry = (countryFromRedux) => {
  if (Country.cz === countryFromRedux) {
    return Currency.czk;
  }
  if (Country.pl === countryFromRedux) {
    return Currency.pln;
  }
  if (Country.sk === countryFromRedux) {
    return Currency.eur;
  }
  if (Country.it === countryFromRedux) {
    return Currency.eur;
  }
  if (Country.de === countryFromRedux) {
    return Currency.eur;
  }
  if (Country.es === countryFromRedux) {
    return Currency.eur;
  }
};

export { getCurrencyCodeString };

export const getCurrencyCodeStringFromCurrencyShortcut = (shortcut) => {
  switch (shortcut) {
    case 'CZK':
      return 'Kč';
    case 'PLN':
      return 'Zł';
    case 'EUR':
      return '€';
    default:
      return 'XXX';
  }
};

export const handleCurrencyFromQueryHook = (router, settings, dispatch) => {
  useEffect(() => {
    if (!dispatch || !settings) return;
    dispatch(setCurrency(getCurrencyFromCountry(settings.country)));

    // if (settings.country && !router.query.currency) {
    // } else if (router.query.currency) {
    //   dispatch(setCurrency(Currency[String(router.query.currency)]));
    // }
  }, [settings.country, settings.language]);
};
