// eslint-disable-next-line import/prefer-default-export
export enum Currency {
  czk = 'czk',
  pln = 'pln',
  eur = 'eur',
}

export enum Country {
  cz = 'cz',
  pl = 'pl',
  sk = 'sk',
  es = 'es',
  de = 'de',
  it = 'it',
}

export enum Language {
  cs = 'cs',
  en = 'en',
  pl = 'pl',
  sk = 'sk',
  es = 'es',
  de = 'de',
  it = 'it',
}
