export enum ModalType {
  RESERVATION = 'RESERVATION',
  BOOKING_INFO = 'BOOKING_INFO',
  LOGIN_OPEN_CHECKOUT = 'LOGIN_OPEN_CHECKOUT',
  CAR_GALLERY = 'CAR_GALLERY',
  CHANGE_DATE = 'CHANGE_DATE',
  BOOKING_INQUIRY = 'BOOKING_INQUIRY',
  REGISTER = 'REGISTER',
  LOGIN = 'LOGIN',
  ADD_PHONE = 'ADD_PHONE',
  APPROVE_BOOKING = 'APPROVE_BOOKING',
  CAR_POLICIES = 'CAR_POLICIES',
  GET_HELP = 'GET_HELP',
  CAMPIRI_COVER = 'CAMPIRI_COVER',

  LOST_PASSWORD = 'LOST_PASSWORD',
  LOST_PASSWORD_INFO = 'LOST_PASSWORD_INFO',
  REGISTER_CONFIRM_INFO = 'REGISTER_CONFIRM_INFO',
  SEARCH_WIDGET_MODAL_FWC_PORT = 'SEARCH_WIDGET_MODAL_FWC_PORT',
  CHANGE_DETAILS = 'CHANGE_DETAILS',
  OPEN_BOOKING = 'OPEN_BOOKING',
  BOOKING_LOCATION = 'BOOKING_LOCATION',
  EDIT_BOOKING = 'EDIT_BOOKING',
  REJECT_BOOKING_MANAGER = 'REJECT_BOOKING_MANAGER',
  REJECT_BOOKING_MODAL = 'REJECT_BOOKING_MODAL',
  REJECT_BOOKING_INTENT = 'REJECT_BOOKING_INTENT',
  WITHDRAW_BOOKING = 'WITHDRAW_BOOKING',
  CANCEL_BOOKING_MANAGER = 'CANCEL_BOOKING_MANAGER',
  CANCEL_OFFER_CUSTOMER = 'CANCEL_OFFER',
  CANCEL_BOOKING_RESERVATION_MANAGER = 'CANCEL_BOOKING_RESERVATION_MANAGER',
  RENTER_PAYMENT_BOOKING_MANAGER = 'RENTER_PAYMENT_BOOKING_MANAGER',
  RENTER_PAYMENT_BY_ACCOUNT = 'RENTER_PAYMENT_BY_ACCOUNT',
  OFFERS_ADD_CUSTOMER = 'OFFERS_ADD_CUSTOMER',
  OFFERS_EDIT_CUSTOMER = 'OFFERS_EDIT_CUSTOMER',
  OFFERS_SEARCH_CUSTOMER = 'OFFERS_SEARCH_CUSTOMER',
  OFFERS_SEARCH_VEHICLE = 'OFFERS_SEARCH_VEHICLE',
  OFFER_CUSTOM_PRICE = 'OFFER_CUSTOM_PRICE',
  REJECT_OFFER_MANAGER = 'REJECT_OFFER_MANAGER',
  BOOKING_REQUEST_RESERVATION_ASSIGNEE = 'BOOKING_REQUEST_RESERVATION_ASSIGNEE',

  DIALOG_WINDOW = 'DIALOG_WINDOW',

  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',

  EDIT_IMAGE_CHECKIN = 'EDIT_IMAGE_CHECKIN',
  ADD_DAMAGE_CHECKIN = 'ADD_DAMAGE_CHECKIN',

  ADD_DAMAGE_CHECKOUT = 'ADD_DAMAGE_CHECKOUT',
  ADD_EDIT_FEE_CHECKOUT = 'ADD_EDIT_FEE_CHECKOUT',

  COOKIES_SETTINGS = 'COOKIES_SETTINGS',

  BOOKING_MANAGER_REQUEST_EXPIRATION = 'BOOKING_MANAGER_EXPIRATION_REQUEST',
  BOOKING_MANAGER_OFFER_EXPIRATION = 'BOOKING_MANAGER_EXPIRATION_OFFER',
  CAMPERS_CLUB_FORM = 'CAMPERS_CLUB_FORM',

  PAYMENT_BANK_TRANSFER = 'PAYMENT_BANK_TRANSFER',

  LISTING_WIZARD_AMENITIES = 'LISTING_WIZARD_AMENITIES',
  LISTING_WIZARD_NEED_HELP = 'LISTING_WIZARD_NEED_HELP',
  LISTING_WIZARD_INSURANCE_INFO = 'LISTING_WIZARD_INSURANCE_INFO',
  APPROVE_LISTING_WIZARD = 'APPROVE_LISTING_WIZARD',
  DECLINE_LISTING_WIZARD = 'DECLINE_LISTING_WIZARD',

  EARNING_CALCULATOR_LW = 'EARNING_CALCULATOR_LW',

  INSURANCE_ADD_PASSENGERS = 'INSURANCE_ADD_PASSENGERS',
  OFFER_INSURANCE_ADD_PASSENGERS = 'OFFER_INSURANCE_ADD_PASSENGERS',
  INSURANCE_MORE_INFORMATION = 'INSURANCE_MORE_INFORMATION',

  ALTERATIONS_CHANGE_DATE = 'ALTERATIONS_CHANGE_DATE',
  ALTERNATION_DETAIL = 'ALTERNATION_DETAIL',
  ALTERATION_APPROVE = 'ALTERATION_APPROVE',
  ALTERATION_DECLINE = 'ALTERATION_DECLINE',
  ALTERATION_WITHDRAWN = 'ALTERATION_WITHDRAWN',

  OPERATOR_CALENDAR_HANDLE_DATES = 'OPERATOR_CALENDAR_HANDLE_DATES',
  CONNECTED_ACCOUNT_SET = 'CONNECTED_ACCOUNT_SET',
  CONNECTED_ACCOUNT_IBAN_SET = 'CONNECTED_ACCOUNT_IBAN_SET',
}
