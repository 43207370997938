import * as t from '../types';

export interface InboxReduxType {
  messagingEnabled: boolean;
  messagesCount: number;
  visibleScreenOnMobile: ScreenNames;
  activeInboxTab: any | null;
}

export enum ScreenNames {
  'ConversationList',
  'Conversation',
  'BookingInfo',
}

const inboxState = (
  // eslint-disable-next-line default-param-last
  state: InboxReduxType = {
    activeInboxTab: null,
    messagingEnabled: true,
    messagesCount: 0,
    visibleScreenOnMobile: ScreenNames.ConversationList,
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_INBOX_MESSAGES_COUNT:
      return {
        ...state,
        messagesCount: action.payload,
      };
    case t.SET_INBOX_VISIBLE_SCREEN:
      return {
        ...state,
        visibleScreenOnMobile: action.payload,
      };
    case t.SET_INBOX_MESSAGING_ENABLED:
      return {
        ...state,
        messagingEnabled: action.payload,
      };
    case t.SET_ACTIVE_INBOX_TAB:
      return {
        ...state,
        activeInboxTab: action.payload,
      };

    default:
      return { ...state };
  }
};

export default inboxState;
