import { createContext, ReactNode } from 'react';

export type CodeBaseType = 'campiri' | 'fwc';
export const CodebaseContext = createContext<CodeBaseType>('campiri');

interface Props {
  codeBase: CodeBaseType;
  children: ReactNode;
}

export function CodebaseProvider({ codeBase, children }: Props) {
  return <CodebaseContext.Provider value={codeBase}>{children}</CodebaseContext.Provider>;
}
