/**
 *
 * @returns {'campiri'|'fwc'}
 */
export default function codebase() {
  const currentCodebase = process.env.NEXT_PUBLIC_APP_TYPE;

  if (currentCodebase === "campiri" || currentCodebase === "fwc")
    return currentCodebase;

  console.error(
    `'NEXT_PUBLIC_APP_TYPE' is not set to campiri or fwc but to ${currentCodebase}!!!! (fallbacking to campiri)`
  );

  return "campiri";
}
