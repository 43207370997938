// eslint-disable-next-line import/prefer-default-export
export const getFormattedDateAndTime = (date: Date, lang = 'cs') => {
  const dateFormatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  return dateFormatter.format(date);
};

export const getFormattedDateWithoutYear = (date: Date, lang = 'cs') => {
  if (!date) return null;
  if ('getSeconds' in date && Number.isNaN(date.getSeconds())) return null;

  const dateFormatter = new Intl.DateTimeFormat(lang, {
    month: 'numeric',
    day: 'numeric',
  });

  return dateFormatter.format(date);
};
export const getFormattedDate = (date: Date, lang = 'cs') => {
  if (!date) return null;
  if ('getSeconds' in date && Number.isNaN(date.getSeconds())) return null;

  const dateFormatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  return dateFormatter.format(date);
};
