/* eslint-disable prettier/prettier */
export default {
  "font": {
    "components": {
      "button": {
        "l": "l-e65cf28329297c8163deea7b79aaab76",
        "m": "m-f43c4f108a15ccd9df29fe7b2dc0edc6"
      },
      "input": {
        "placeholder": "placeholder-659e3d60513f85f28321cf0693a72d41",
        "placeholderActive": "placeholderActive-c5acfe93a305cb1c1fc77efe32641398",
        "value": "value-b93102ea276bd1e10e92b3dc7c8f0e37"
      },
      "navigation": {
        "main": "main-95b0dc5d2866d341db0fd4398fc189dc"
      }
    },
    "body": {
      "desktop": {
        "xl": "xl-dbbba0fbddb7e187c896cb666f7711fa",
        "xlBold": "xlBold-41e295178da7aa1094283eb0b3630a8a",
        "l": "l-79a1e16c5b5d7437da1f83b86e4e2232",
        "lBold": "lBold-87daf0979f1b0cc9716853d9c1ab395a",
        "lUnderlined": "lUnderlined-bbfe4255572bea9d9cd609364b38153f",
        "m": "m-1b5ee5553bdb775817f632e4e200e27f",
        "mBold": "mBold-81f7d798c67d8d4ee326a51565062c09",
        "mUnderlined": "mUnderlined-7afbd9470ea591d0d60ae409d70681ec",
        "s": "s-dfbbc1b131872d7b8cd1f1e647be3bb4",
        "sBold": "sBold-5f98698b8252a3cf79a0466ceeb44bf5",
        "sUnderlined": "sUnderlined-2b8577ece64e8077e6c7b993e4fe3fb9"
      },
      "mobile": {
        "xl": "xl-4d96da6a659a236ed123a5e9a95b4fb2",
        "xlBold": "xlBold-cfb9db8b9f8ccb420be8c944c9f3c1f2",
        "l": "l-78726613384c77c95bbd1c2bf9f1705c",
        "lBold": "lBold-259a66282de8027c27f301388fefbf59",
        "lUnderlined": "lUnderlined-edaaac3f04028dea0916ef2fe2e2cc8f",
        "m": "m-6a9ddb2ea9f5b2bce098ced9e405d2b2",
        "mBold": "mBold-0b871bd225b22f811dd42fa284349dad",
        "mUnderlined": "mUnderlined-8df312e33faaeece1604050268295be8",
        "s": "s-fe9bf6b0d51d05825776cda49e484eda",
        "sUnderlined": "sUnderlined-43f260398437669c971fbf0496f6e705"
      }
    },
    "headlines": {
      "desktop": {
        "h1": "h1-4e7cc65a5adc542629d638efa9ee7fa2",
        "h2": "h2-3722d26b2011f76af1beffea8f8919a3",
        "h3": "h3-3d62dc95dbdf748c06c06389a40688da",
        "h4": "h4-74f1ed31cbb406c5fb8a535ee9d5dbc5",
        "h5": "h5-eb2c8c7b23377c582f33ff16ac273e65",
        "h6": "h6-4d0a46c41d59c890a5c745ea3b63e951"
      },
      "mobile": {
        "h1": "h1-fe8f2c02d34e9d35fc445b99aca86bdd",
        "h2": "h2-961ce535bd698dfc6e5090018155875e",
        "h3": "h3-1ad17d72963fb0f179f47d7c5318cc3e",
        "h4": "h4-d7349ef1ae7825e91dbd13e080dc5cbf",
        "h5": "h5-3b8833c6c8dc4be3e6b7a89dcd4025e5",
        "h6": "h6-f6dcfcd50851c139296811124f176fca"
      }
    }
  },
  "layouts": {
    "desktop": {
      "screen": "screen-4b2453c34567171fe58aec64c1a9b40a",
      "wrapper": "wrapper-706115bc6b18f9dd64252202a67d552f"
    },
    "mobile": {
      "screen": "screen-cb3e672520214c655bd104b431100607",
      "wrapper": "wrapper-22f0abb4d01bf005da845669ed1a9073"
    }
  },
  "radius": {
    "radiusS": "radiusS-012e3f6f8672c3f3f40188f520171d10",
    "radiusM": "radiusM-b905d8ee603e11524319e33ef0fc5293",
    "radiusL": "radiusL-5d2a57a9bb92c7f1556424966bcc0539",
    "radiusXl": "radiusXl-0757efc6d621d8f8f673dc75425c174d",
    "radiusFull": "radiusFull-082076eaea88aa801b6bb577fcfd19ad"
  },
  "spacing": {
    "spacingNone": "spacingNone-05ece95e7ff1e99a0b5032e2680cade1",
    "spacingXxs": "spacingXxs-677ad420305f599daca885bc83d8769c",
    "spacingXs": "spacingXs-6b033c4a10aaccd270c2e1d4243dfaf4",
    "spacingS": "spacingS-e54b3bdd8b329fa1fc0815eaa797de6a",
    "spacingM": "spacingM-7bbf6258c65e0346d80509d6f4599502",
    "spacingL": "spacingL-78905c98b329147dd2bb4e71c1a7b684",
    "spacingXl": "spacingXl-e2e96c30f7ceb201278ef05388d0c96d",
    "spacing2Xl": "spacing2Xl-1deafc75796eb1f6fd61d4ae10b00bc6",
    "spacing3Xl": "spacing3Xl-978d6a1972b10dc404f0f67b1365bfa8",
    "spacing4Xl": "spacing4Xl-06cc3e4f6a429f383028462a960bd5d4",
    "spacing5Xl": "spacing5Xl-1e7ee00e785c1eea666cb46bba7f6c42",
    "spacingSection": "spacingSection-1b4ac712a29f7bec8b8aaf1aab17e9b3",
    "spacingSectionL": "spacingSectionL-85a444a6172a3214d4be0224f03e5a2d"
  },
  "color": {
    "text": {
      "textPrimary": "textPrimary-30a63cb6fe052c71042fe3c15d375472",
      "textSecondary": "textSecondary-0ab2fd6bd6790491fc63337d5f8392a6",
      "textContrastPrimary": "textContrastPrimary-2b405ebaf6568d403602c872119669eb",
      "textContrastSecondary": "textContrastSecondary-019b2d61dbf383723bff9853c162873e",
      "textButton": "textButton-0d0ea1aa8e01dae43f84b8abca6fd222",
      "textLink": "textLink-75c82d9e9100e5a1e66856ec6cb9c6b9",
      "textLinkHover": "textLinkHover-4a470af58812fef5f4d4b4310930481b",
      "textPlaceholder": "textPlaceholder-d58ca1ce306a048c921dfa5710f134be",
      "textDisabled": "textDisabled-2b1c697a1549c91936f52aa3459ba497",
      "textNav": "textNav-3262d948a3990e1950d45412b947356e",
      "textError": "textError-d5e98797247714b7e1b164530fb27e86",
      "textSuccess": "textSuccess-83ee0e95905c3d30d29ee48ddcb0f471"
    },
    "buttons": {
      "buttonPrimary": "buttonPrimary-6b8885402ce6d8eacc7dd1824a4ec00b",
      "buttonSecondary": "buttonSecondary-6ee68ac6d8787a5280a7c451577c1b51",
      "buttonTertiary": "buttonTertiary-82676516d64cb99672081fc962e027f8",
      "buttonDisabled": "buttonDisabled-fed6faa32d032d330490bd41edd59b00",
      "buttonHover": "buttonHover-d4e4b4c3c2bee8a05019a3e7e68cf861",
      "buttonPressed": "buttonPressed-cb61928eb26ce091ff6eeef04737e9e8"
    },
    "backgrounds": {
      "surfaceBg": "surfaceBg-cb01d9a262427c449f267dbd56fc5755",
      "surfaceContrast": "surfaceContrast-b4d1c8e1206bef63474d395c3f4eb04e",
      "surfaceSection": "surfaceSection-05f22ce30f11d24b17eb2b9a7d323df6",
      "surfaceSectionLighter": "surfaceSectionLighter-9c99d2331fad09e1501378842af38b24",
      "disabled": "disabled-a8dbc7afb00b976847fdbb4001b05915",
      "info": "info-72b783178b9defbb43b441530ac8958a",
      "warning": "warning-18ed52d405c31295baa8acb6ce590d04",
      "error": "error-f11343118b350eb4bf921cbd52519f1d",
      "imageOverlay": "imageOverlay-2d1964f4eac77a063d102c3d83954744",
      "errorBg": "errorBg-6b9d8820486586fb3cc83d8f1be26893",
      "sucess": "sucess-6eb69aeb12b280d915487797d52a8021"
    },
    "items": {
      "fillIconDark": "fillIconDark-16971a22cf64aab7edade0fb16061694",
      "fillIconLight": "fillIconLight-f9150ba23748d45defac28e62da41c28",
      "fillIconHighlight": "fillIconHighlight-eed63bc0749db196e4d8356a981c2dba",
      "fillIconSuccess": "fillIconSuccess-24d6ca6cf6a12bc85bbb4b7d630908b7",
      "fillIconWarning": "fillIconWarning-4b0d0cb40895aec7af4fd65affb603b3",
      "fillIconInfo": "fillIconInfo-2a54fa2c1785d71d1092191618fb810f",
      "fillIconError": "fillIconError-96fa9b3943bcd93f3e1127f9def50140",
      "fillIconContrast": "fillIconContrast-0de31e8474f191835af7abed2ca5ea3a",
      "itemSelected": "itemSelected-48558747dcf4dc8ba0346def558477e1",
      "itemUnselected": "itemUnselected-99a524ea572687589ecefdf21ebd78c0",
      "stepActive": "stepActive-1e8371fabffc8814c96997acc095407d",
      "stepInactive": "stepInactive-81f723ef2794e8927bb685a63436f76c",
      "separatorLight": "separatorLight-76f3328eb1d5c242b3a46f8298f2ea29",
      "separatorDark": "separatorDark-c15f3e76fd0fa18a7cda425dfef468c0",
      "filterActive": "filterActive-9edffc2270e5a8228159a95523b3606e",
      "calendarActive": "calendarActive-8e244a49688e16e2de5a864de48f5f87"
    },
    "badge": {
      "badge1": "badge1-c8a75515269f43755415d49f6ac1909b",
      "badge2": "badge2-c9ef194d065699ce00ded04c724b6489",
      "badgeWarning": "badgeWarning-8b3750b992db7c22169d8a84af2545de",
      "badgeSuccess": "badgeSuccess-cab8f4f9297d0076d95cf0ed44a935ac",
      "badgeInformative": "badgeInformative-b4ff6260da8f9375612cf13aadf0d6bc",
      "badgeError": "badgeError-2f1a33c04fa1ef555e743dd6178260f0"
    },
    "border": {
      "border": "border-1a27304f88657552bf28f7610d39c903",
      "borderHover": "borderHover-4aceba59646a68626c1a4167666cd99e",
      "borderActive": "borderActive-542f5c040d98a283939693569d434e8c",
      "borderError": "borderError-fa6a71d69f62e2d5994d599ab03bd1ca",
      "borderLight": "borderLight-41ea39fca1d4f75023227bc6c24d3d8d",
      "borderDark": "borderDark-6b7f58813e45583b86fce4b74ebded06"
    }
  }
} as const;