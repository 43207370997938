/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomerBillingInfoUpdateCommand } from '../models';
// @ts-ignore
import { CustomerInfoUpdateCommand } from '../models';
// @ts-ignore
import { CustomerProfileQueryResult } from '../models';
// @ts-ignore
import { IncompleteCustomerInfoUpdateCommand } from '../models';
// @ts-ignore
import { IncompleteCustomerInsuranceInfoUpdateCommand } from '../models';
// @ts-ignore
import { PictureModel } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * UserMarketplaceApi - axios parameter creator
 * @export
 */
export const UserMarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates the user\'s billing information.
         * @param {CustomerBillingInfoUpdateCommand} [customerBillingInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersBillingInformationPatch: async (customerBillingInfoUpdateCommand?: CustomerBillingInfoUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users/billing-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerBillingInfoUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the incomplete data required for tuito insurance.
         * @param {IncompleteCustomerInsuranceInfoUpdateCommand} [incompleteCustomerInsuranceInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersCompleteInsuranceDataPatch: async (incompleteCustomerInsuranceInfoUpdateCommand?: IncompleteCustomerInsuranceInfoUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users/complete-insurance-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(incompleteCustomerInsuranceInfoUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the incomplete user information.
         * @param {IncompleteCustomerInfoUpdateCommand} [incompleteCustomerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersCompletePatch: async (incompleteCustomerInfoUpdateCommand?: IncompleteCustomerInfoUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(incompleteCustomerInfoUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersConsentsAcceptTermsAndConditionsAndGdprContractPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users/consents/accept-terms-and-conditions-and-gdpr-contract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user culture preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersCulturePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users/culture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the currently logged in user entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user information.
         * @param {CustomerInfoUpdateCommand} [customerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersPatch: async (customerInfoUpdateCommand?: CustomerInfoUpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerInfoUpdateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the user\'s profile picture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersPictureDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users/picture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user\'s profile picture and returns the model.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersPicturePut: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users/picture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verifies the user\'s email based on token
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersVerifyPost: async (code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplace/me/users/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserMarketplaceApi - functional programming interface
 * @export
 */
export const UserMarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserMarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates the user\'s billing information.
         * @param {CustomerBillingInfoUpdateCommand} [customerBillingInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersBillingInformationPatch(customerBillingInfoUpdateCommand?: CustomerBillingInfoUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersBillingInformationPatch(customerBillingInfoUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the incomplete data required for tuito insurance.
         * @param {IncompleteCustomerInsuranceInfoUpdateCommand} [incompleteCustomerInsuranceInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersCompleteInsuranceDataPatch(incompleteCustomerInsuranceInfoUpdateCommand?: IncompleteCustomerInsuranceInfoUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersCompleteInsuranceDataPatch(incompleteCustomerInsuranceInfoUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the incomplete user information.
         * @param {IncompleteCustomerInfoUpdateCommand} [incompleteCustomerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersCompletePatch(incompleteCustomerInfoUpdateCommand?: IncompleteCustomerInfoUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersCompletePatch(incompleteCustomerInfoUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersConsentsAcceptTermsAndConditionsAndGdprContractPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersConsentsAcceptTermsAndConditionsAndGdprContractPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user culture preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersCulturePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersCulturePut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the currently logged in user entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerProfileQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user information.
         * @param {CustomerInfoUpdateCommand} [customerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersPatch(customerInfoUpdateCommand?: CustomerInfoUpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersPatch(customerInfoUpdateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the user\'s profile picture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersPictureDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersPictureDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user\'s profile picture and returns the model.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersPicturePut(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PictureModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersPicturePut(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verifies the user\'s email based on token
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MarketplaceMeUsersVerifyPost(code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MarketplaceMeUsersVerifyPost(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserMarketplaceApi - factory interface
 * @export
 */
export const UserMarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserMarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates the user\'s billing information.
         * @param {CustomerBillingInfoUpdateCommand} [customerBillingInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersBillingInformationPatch(customerBillingInfoUpdateCommand?: CustomerBillingInfoUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceMeUsersBillingInformationPatch(customerBillingInfoUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the incomplete data required for tuito insurance.
         * @param {IncompleteCustomerInsuranceInfoUpdateCommand} [incompleteCustomerInsuranceInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersCompleteInsuranceDataPatch(incompleteCustomerInsuranceInfoUpdateCommand?: IncompleteCustomerInsuranceInfoUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceMeUsersCompleteInsuranceDataPatch(incompleteCustomerInsuranceInfoUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the incomplete user information.
         * @param {IncompleteCustomerInfoUpdateCommand} [incompleteCustomerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersCompletePatch(incompleteCustomerInfoUpdateCommand?: IncompleteCustomerInfoUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceMeUsersCompletePatch(incompleteCustomerInfoUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersConsentsAcceptTermsAndConditionsAndGdprContractPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceMeUsersConsentsAcceptTermsAndConditionsAndGdprContractPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user culture preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersCulturePut(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceMeUsersCulturePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the currently logged in user entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersGet(options?: any): AxiosPromise<CustomerProfileQueryResult> {
            return localVarFp.apiV1MarketplaceMeUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user information.
         * @param {CustomerInfoUpdateCommand} [customerInfoUpdateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersPatch(customerInfoUpdateCommand?: CustomerInfoUpdateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceMeUsersPatch(customerInfoUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the user\'s profile picture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersPictureDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceMeUsersPictureDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user\'s profile picture and returns the model.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersPicturePut(file?: File, options?: any): AxiosPromise<PictureModel> {
            return localVarFp.apiV1MarketplaceMeUsersPicturePut(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verifies the user\'s email based on token
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MarketplaceMeUsersVerifyPost(code?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MarketplaceMeUsersVerifyPost(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserMarketplaceApi - interface
 * @export
 * @interface UserMarketplaceApi
 */
export interface UserMarketplaceApiInterface {
    /**
     * 
     * @summary Updates the user\'s billing information.
     * @param {CustomerBillingInfoUpdateCommand} [customerBillingInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersBillingInformationPatch(customerBillingInfoUpdateCommand?: CustomerBillingInfoUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the incomplete data required for tuito insurance.
     * @param {IncompleteCustomerInsuranceInfoUpdateCommand} [incompleteCustomerInsuranceInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersCompleteInsuranceDataPatch(incompleteCustomerInsuranceInfoUpdateCommand?: IncompleteCustomerInsuranceInfoUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the incomplete user information.
     * @param {IncompleteCustomerInfoUpdateCommand} [incompleteCustomerInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersCompletePatch(incompleteCustomerInfoUpdateCommand?: IncompleteCustomerInfoUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersConsentsAcceptTermsAndConditionsAndGdprContractPost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the user culture preference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersCulturePut(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Returns the currently logged in user entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersGet(options?: AxiosRequestConfig): AxiosPromise<CustomerProfileQueryResult>;

    /**
     * 
     * @summary Updates the user information.
     * @param {CustomerInfoUpdateCommand} [customerInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersPatch(customerInfoUpdateCommand?: CustomerInfoUpdateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deletes the user\'s profile picture.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersPictureDelete(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the user\'s profile picture and returns the model.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersPicturePut(file?: File, options?: AxiosRequestConfig): AxiosPromise<PictureModel>;

    /**
     * 
     * @summary Verifies the user\'s email based on token
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApiInterface
     */
    apiV1MarketplaceMeUsersVerifyPost(code?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserMarketplaceApi - object-oriented interface
 * @export
 * @class UserMarketplaceApi
 * @extends {BaseAPI}
 */
export class UserMarketplaceApi extends BaseAPI implements UserMarketplaceApiInterface {
    /**
     * 
     * @summary Updates the user\'s billing information.
     * @param {CustomerBillingInfoUpdateCommand} [customerBillingInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersBillingInformationPatch(customerBillingInfoUpdateCommand?: CustomerBillingInfoUpdateCommand, options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersBillingInformationPatch(customerBillingInfoUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the incomplete data required for tuito insurance.
     * @param {IncompleteCustomerInsuranceInfoUpdateCommand} [incompleteCustomerInsuranceInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersCompleteInsuranceDataPatch(incompleteCustomerInsuranceInfoUpdateCommand?: IncompleteCustomerInsuranceInfoUpdateCommand, options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersCompleteInsuranceDataPatch(incompleteCustomerInsuranceInfoUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the incomplete user information.
     * @param {IncompleteCustomerInfoUpdateCommand} [incompleteCustomerInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersCompletePatch(incompleteCustomerInfoUpdateCommand?: IncompleteCustomerInfoUpdateCommand, options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersCompletePatch(incompleteCustomerInfoUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersConsentsAcceptTermsAndConditionsAndGdprContractPost(options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersConsentsAcceptTermsAndConditionsAndGdprContractPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user culture preference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersCulturePut(options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersCulturePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the currently logged in user entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersGet(options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user information.
     * @param {CustomerInfoUpdateCommand} [customerInfoUpdateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersPatch(customerInfoUpdateCommand?: CustomerInfoUpdateCommand, options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersPatch(customerInfoUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the user\'s profile picture.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersPictureDelete(options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersPictureDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user\'s profile picture and returns the model.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersPicturePut(file?: File, options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersPicturePut(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verifies the user\'s email based on token
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMarketplaceApi
     */
    public apiV1MarketplaceMeUsersVerifyPost(code?: string, options?: AxiosRequestConfig) {
        return UserMarketplaceApiFp(this.configuration).apiV1MarketplaceMeUsersVerifyPost(code, options).then((request) => request(this.axios, this.basePath));
    }
}

