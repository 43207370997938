import { useEffect } from 'react';
import { LanguageCode } from '~/libs/api/models/language-code';
import { ModalType } from '../components/modal/modalTypes';
import { openDesktopOrPhoneModal } from '../components/modal/utils';
import { Language } from './routing';

export const getLanguageCodeFroTranslator = (lang: Language) => {
  switch (lang) {
    case Language.cs:
      return 'cs';
    case Language.en:
      return 'en';
    case Language.pl:
      return 'pl';
    case Language.sk:
      return 'sk';
    case Language.it:
      return 'it';
    case Language.de:
      return 'de';
    case Language.es:
      return 'es';
    default:
      return 'cs';
  }
};

export const getLanguageCodeForApi = (lang: Language) => {
  switch (lang) {
    case Language.cs:
      return LanguageCode.Czech;
    case Language.en:
      return LanguageCode.English;
    case Language.pl:
      return LanguageCode.Polish;
    case Language.sk:
      return LanguageCode.Slovak;
    case Language.es:
      return LanguageCode.Spanish;
    case Language.de:
      return LanguageCode.German;
    case Language.it:
      return LanguageCode.Italian;
    default:
      return LanguageCode.Czech;
  }
};
export const openLanguageModalRedirect = (router, screen, dispatch) => {
  useEffect(() => {
    if (router?.query.redirect === 'true') {
      openDesktopOrPhoneModal(ModalType.CHANGE_LANGUAGE, screen, dispatch);
    }
  }, [router?.query]);
};
