import styled from 'styled-components';

export const CorrectMessagePassword = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #119383;
  margin-top: 8px;

  &.info {
    color: #1f2244;
  }
`;

export const CheckIconPassword = styled.i`
  margin-right: 8px;

  &:before {
    color: #119383;
  }

  ${CorrectMessagePassword}.info & {
    &:before {
      color: #1f2244;
    }
  }
`;
