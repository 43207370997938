import { createGlobalStyle, css } from 'styled-components';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { resolveInCodebase, resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

const GlobalStyle = createGlobalStyle`
  /* prevent iframe to be above opened modal for example mobile navigation  */
  html body iframe#LeadboosterContainer {
    z-index: 999 !important;

    bottom: 48px !important;
    @media ${deviceMaxWidth.tablet} {
      bottom: calc(max(90px, 10%) - 16px) !important;

    }
  }

  .no-scroll {
    overflow: hidden;
  }

  html body iframe#LeadboosterContainer.openedChat {
    z-index: 10999 !important;
  }

  .rc-tooltip.rc-tooltip-placement-top {
    .rc-tooltip-inner, .rc-tooltip-arrow {
      margin-bottom: 5px;
    }
  }

  ${
    (resolveInCodebase('fwc'),
    css`
      a,
      a:visited,
      a:hover,
      a:active {
        color: inherit;
        text-decoration: none;
      }

      textarea {
        font-family: inherit;
      }
    `)
  }

  ${({ theme }) =>
    theme.codebase === 'campiri' &&
    css`
      :root {
        /* Colors: */
        --color-dark-blue: #1f2244;
        --color-orange: #ff5e55;
        --color-green: #119383;
        --color-beige: #edeae3;
        --color-grey: #dad1d1;
        --color-white: #ffffff;
        --color-dark-grey: #9c8c8c;
        --color-error-red: #fd253f;

        --font-poppins: 'Poppins', sans-serif;
        --font-baloo: 'Baloo Tamma 2', sans-serif;

        --bg-table-stripe: #f6f6f5;
        --b-table: #e3e3e2;
        --caption: #242423;
      }

      h4 {
        font-size: 1.25em;
        line-height: 1.4;
        margin-bottom: 0.4em;
      }

      a {
        transition: 200ms;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

      button {
        background: transparent;
        border: 0;
        padding: 0;
        outline: none;
        cursor: pointer;
        transition: 200ms;
      }
    `}

 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;

  }

 html, body {
    width: 100%;
    height: 100%;
  }

*{
  /* scroll-margin-top: 100px; */
}

  html {
    scroll-behavior: smooth;
    font-size: 100%;
    line-height: 1.4;
    color: #4b5765;
    height: 100%;
    height: -webkit-fill-available;

  }

  body {
    color: ${resolveThemeValue(tokens.color.text.textPrimary, 'black')};
    line-height: 1.4;
    font-size: 100%;
    height: 100%;
    overflow-x: hidden;
    min-height: -webkit-fill-available;

    &.mobile-menu-open {
      @media ${deviceMaxWidth.phone} {
        overflow: hidden;
      }
    }
  }

  :focus {
    outline: none !important;
  }




  ul, ol, dl {
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 0;
    padding: 0;

  }


input:invalid {
    box-shadow: none;
}





.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right{
  display:none;
}



//Tooltip
.rc-tooltip-inner {
   border:none;
   background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceSection, '#1f2244')} !important;
   border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%);
}
.rc-tooltip-placement-top .rc-tooltip-arrow{
  border-top-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceSection, '#1f2244')} ;
  bottom: -4px !important;
}

  .rc-tooltip-placement-right .rc-tooltip-arrow{
    border-right-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceSection, '#1f2244')}
  }

.rc-tooltip-arrow {

}



.rc-tooltip {
  opacity: 1;
  max-width: 180px;
  background-color: transparent;
  z-index: 9999999999999999 !important;

}

.m-b-16 {
  margin-bottom: 16px
}

.eshopWrapper{
  padding-bottom: 70px !important;
}

.eshopConditions{
    background-color: white;
    height: 70px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 11px;


    font-size: 13px;
    color: #4c4c4c;
    box-shadow: -5px 0 5px rgb(0 0 0 / 10%);
    position: relative;
    left: 10px;

    a{
      text-decoration: underline;
      color:#4d4db8;
    }
}
`;

export default GlobalStyle;
