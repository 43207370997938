/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OperatorAccountProfileQueryResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateOperatorIbanCommand } from '../models';
// @ts-ignore
import { UpdateOperatorPayoutInformationCommand } from '../models';
/**
 * AccountOperatorApi - axios parameter creator
 * @export
 */
export const AccountOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generates link for updating stripe information.
         * @param {string} [returnUrl] 
         * @param {string} [refreshUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeAccountsCreateConnectAccountLinkPost: async (returnUrl?: string, refreshUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/me/accounts/create-connect-account-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }

            if (refreshUrl !== undefined) {
                localVarQueryParameter['refreshUrl'] = refreshUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the currently logged in users account information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeAccountsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/me/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initializes Stripe connect account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeAccountsInitializeStripeConnectAccountPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/me/accounts/initialize-stripe-connect-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates operator payout information.
         * @param {UpdateOperatorPayoutInformationCommand} [updateOperatorPayoutInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeAccountsPayoutInformationPost: async (updateOperatorPayoutInformationCommand?: UpdateOperatorPayoutInformationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/me/accounts/payout-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOperatorPayoutInformationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates operator iban
         * @param {UpdateOperatorIbanCommand} [updateOperatorIbanCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeIbanPut: async (updateOperatorIbanCommand?: UpdateOperatorIbanCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/me/iban`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOperatorIbanCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountOperatorApi - functional programming interface
 * @export
 */
export const AccountOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generates link for updating stripe information.
         * @param {string} [returnUrl] 
         * @param {string} [refreshUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorMeAccountsCreateConnectAccountLinkPost(returnUrl?: string, refreshUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorMeAccountsCreateConnectAccountLinkPost(returnUrl, refreshUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the currently logged in users account information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorMeAccountsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorAccountProfileQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorMeAccountsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initializes Stripe connect account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorMeAccountsInitializeStripeConnectAccountPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorMeAccountsInitializeStripeConnectAccountPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates operator payout information.
         * @param {UpdateOperatorPayoutInformationCommand} [updateOperatorPayoutInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorMeAccountsPayoutInformationPost(updateOperatorPayoutInformationCommand?: UpdateOperatorPayoutInformationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorMeAccountsPayoutInformationPost(updateOperatorPayoutInformationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates operator iban
         * @param {UpdateOperatorIbanCommand} [updateOperatorIbanCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorMeIbanPut(updateOperatorIbanCommand?: UpdateOperatorIbanCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorMeIbanPut(updateOperatorIbanCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountOperatorApi - factory interface
 * @export
 */
export const AccountOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountOperatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Generates link for updating stripe information.
         * @param {string} [returnUrl] 
         * @param {string} [refreshUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeAccountsCreateConnectAccountLinkPost(returnUrl?: string, refreshUrl?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1OperatorMeAccountsCreateConnectAccountLinkPost(returnUrl, refreshUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the currently logged in users account information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeAccountsGet(options?: any): AxiosPromise<OperatorAccountProfileQueryResult> {
            return localVarFp.apiV1OperatorMeAccountsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initializes Stripe connect account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeAccountsInitializeStripeConnectAccountPost(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1OperatorMeAccountsInitializeStripeConnectAccountPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates operator payout information.
         * @param {UpdateOperatorPayoutInformationCommand} [updateOperatorPayoutInformationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeAccountsPayoutInformationPost(updateOperatorPayoutInformationCommand?: UpdateOperatorPayoutInformationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorMeAccountsPayoutInformationPost(updateOperatorPayoutInformationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates operator iban
         * @param {UpdateOperatorIbanCommand} [updateOperatorIbanCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorMeIbanPut(updateOperatorIbanCommand?: UpdateOperatorIbanCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorMeIbanPut(updateOperatorIbanCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountOperatorApi - interface
 * @export
 * @interface AccountOperatorApi
 */
export interface AccountOperatorApiInterface {
    /**
     * 
     * @summary Generates link for updating stripe information.
     * @param {string} [returnUrl] 
     * @param {string} [refreshUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApiInterface
     */
    apiV1OperatorMeAccountsCreateConnectAccountLinkPost(returnUrl?: string, refreshUrl?: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Returns the currently logged in users account information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApiInterface
     */
    apiV1OperatorMeAccountsGet(options?: AxiosRequestConfig): AxiosPromise<OperatorAccountProfileQueryResult>;

    /**
     * 
     * @summary Initializes Stripe connect account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApiInterface
     */
    apiV1OperatorMeAccountsInitializeStripeConnectAccountPost(options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Updates operator payout information.
     * @param {UpdateOperatorPayoutInformationCommand} [updateOperatorPayoutInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApiInterface
     */
    apiV1OperatorMeAccountsPayoutInformationPost(updateOperatorPayoutInformationCommand?: UpdateOperatorPayoutInformationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates operator iban
     * @param {UpdateOperatorIbanCommand} [updateOperatorIbanCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApiInterface
     */
    apiV1OperatorMeIbanPut(updateOperatorIbanCommand?: UpdateOperatorIbanCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AccountOperatorApi - object-oriented interface
 * @export
 * @class AccountOperatorApi
 * @extends {BaseAPI}
 */
export class AccountOperatorApi extends BaseAPI implements AccountOperatorApiInterface {
    /**
     * 
     * @summary Generates link for updating stripe information.
     * @param {string} [returnUrl] 
     * @param {string} [refreshUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApi
     */
    public apiV1OperatorMeAccountsCreateConnectAccountLinkPost(returnUrl?: string, refreshUrl?: string, options?: AxiosRequestConfig) {
        return AccountOperatorApiFp(this.configuration).apiV1OperatorMeAccountsCreateConnectAccountLinkPost(returnUrl, refreshUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the currently logged in users account information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApi
     */
    public apiV1OperatorMeAccountsGet(options?: AxiosRequestConfig) {
        return AccountOperatorApiFp(this.configuration).apiV1OperatorMeAccountsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initializes Stripe connect account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApi
     */
    public apiV1OperatorMeAccountsInitializeStripeConnectAccountPost(options?: AxiosRequestConfig) {
        return AccountOperatorApiFp(this.configuration).apiV1OperatorMeAccountsInitializeStripeConnectAccountPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates operator payout information.
     * @param {UpdateOperatorPayoutInformationCommand} [updateOperatorPayoutInformationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApi
     */
    public apiV1OperatorMeAccountsPayoutInformationPost(updateOperatorPayoutInformationCommand?: UpdateOperatorPayoutInformationCommand, options?: AxiosRequestConfig) {
        return AccountOperatorApiFp(this.configuration).apiV1OperatorMeAccountsPayoutInformationPost(updateOperatorPayoutInformationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates operator iban
     * @param {UpdateOperatorIbanCommand} [updateOperatorIbanCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOperatorApi
     */
    public apiV1OperatorMeIbanPut(updateOperatorIbanCommand?: UpdateOperatorIbanCommand, options?: AxiosRequestConfig) {
        return AccountOperatorApiFp(this.configuration).apiV1OperatorMeIbanPut(updateOperatorIbanCommand, options).then((request) => request(this.axios, this.basePath));
    }
}

