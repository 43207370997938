import { BicycleCarrierType } from '~/libs/api/models/bicycle-carrier-type';
import { DriversLicenseCategory } from '~/libs/api/models/drivers-license-category';
import { FuelType } from '~/libs/api/models/fuel-type';
import { PetPolicy } from '~/libs/api/models/pet-policy';
import { ShowerOption } from '~/libs/api/models/shower-option';
import { ToiletOption } from '~/libs/api/models/toilet-option';
import { TransmissionType } from '~/libs/api/models/transmission-type';
import { VehicleWeightOption } from '~/libs/api/models/vehicle-weight-option';
import { WinterOperationOption } from '~/libs/api/models/winter-operation-option';
import {
  setSearchAdultsCount,
  setSearchChildrenCount,
  setSearchDates,
  setSearchPlace,
} from 'features/redux/actions/search';
import * as t from '../types';

export const setSearchInfo = (searchInfo: Object) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_INFO,
    payload: searchInfo,
  });
};

export const setShownDateCalendar = (date: any) => (dispatch) => {
  dispatch({
    type: t.SET_SHOWN_DATE_CALENDAR,
    payload: date,
  });
};

export { setSearchAdultsCount, setSearchChildrenCount, setSearchDates, setSearchPlace };

export const setSearchMaxPrice = (maxPrice: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_MAX_PRICE,
    payload: maxPrice,
  });
};

export const setSearchMinPrice = (minPrice: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_MIN_PRICE,
    payload: minPrice,
  });
};

export const setIncludedKmTo = (toKm: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_INCLUDED_KM_TO,
    payload: toKm,
  });
};

export const setIncudedKmFrom = (fromKm: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_INCLUDED_KM_FROM,
    payload: fromKm,
  });
};

export const setSearchInstantBookable = (isInstantBookable: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_INSTANT_BOOKABLE,
    payload: isInstantBookable,
  });
};

export const setInsuranceIncludedInPrice = (isIncluded: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_INSURANCE_INCLUDED_IN_PRICE,
    payload: isIncluded,
  });
};

export const setSearchLicenseCategory = (category: DriversLicenseCategory) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_LICENSE_CATEGORY,
    payload: category,
  });
};
export const setSearchSleepCountFrom = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_SLEEP_COUNT_FROM,
    payload: count,
  });
};
export const setSearchSleepCountTo = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_SLEEP_COUNT_TO,
    payload: count,
  });
};
export const setPassengersCapacity = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_PASSENGERS_CAPACITY,
    payload: count,
  });
};
export const setSearchSeatCapacityTo = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_SEAT_CAPACITY_TO,
    payload: count,
  });
};
export const setSearchBedCountFrom = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_BED_COUNT_FROM,
    payload: count,
  });
};
export const setSearchBedCountTo = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_BED_COUNT_TO,
    payload: count,
  });
};
export const setSearchBerthCountFrom = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_BERTH_COUNT_FROM,
    payload: count,
  });
};
export const setSearchBerthCountTo = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_BERTH_COUNT_TO,
    payload: count,
  });
};
export const setSearchTransmissionType = (arr: Array<TransmissionType>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_TRANSMISSION_TYPE,
    payload: arr,
  });
};
export const setSearchShower = (arr: Array<ShowerOption>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_SHOWER,
    payload: arr,
  });
};
export const setSearchToilet = (arr: Array<ToiletOption>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_TOILET,
    payload: arr,
  });
};
export const setSearchWinterOperation = (arr: Array<WinterOperationOption>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_WINTER_OPERATION,
    payload: arr,
  });
};

export const setSearchVehicleType = (arr: Array<string>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_VEHICLE_TYPE,
    payload: arr,
  });
};

export const setSearchKitchenSink = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_KITCHEN_SINK,
    payload: has,
  });
};

export const setSearchTelevision = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_TELEVISION,
    payload: has,
  });
};

export const setSearchParkingSensors = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_PARKING_SENSORS,
    payload: has,
  });
};
export const setSearchFridge = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_FRIDGE,
    payload: has,
  });
};

export const setSearchYearBuildFrom = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_YEAR_BUILD_FROM,
    payload: count,
  });
};
export const setSearchYearBuildTo = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_YEAR_BUILD_TO,
    payload: count,
  });
};
export const setSearchWeightFrom = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_WEIGHT_KG_FROM,
    payload: count,
  });
};
export const setSearchWeightTo = (arr: VehicleWeightOption) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_WEIGHT_KG_TO,
    payload: arr,
  });
};
export const setSearchPayloadLimitFrom = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_PAYLOAD_LIMIT_KG_FROM,
    payload: count,
  });
};
export const setSearchPayloadLimitTo = (count: number) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_PAYLOAD_LIMIT_KG_TO,
    payload: count,
  });
};

export const setSearchFuel = (arr: Array<FuelType>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_FUEL,
    payload: arr,
  });
};
export const setSearchPets = (arr: Array<PetPolicy>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_PETS,
    payload: arr,
  });
};
export const setSearchSmoking = (arr: Array<string>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_SMOKING,
    payload: arr,
  });
};

export const setSearchUnlimitedIncludedKilometers = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_UNLIMITED_INCLUDED_KILOMETERS,
    payload: has,
  });
};

export const setSearchOffRoad = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_OFF_ROAD,
    payload: has,
  });
};

export const setSearchKitchenWear = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_KITCHEN_WEAR,
    payload: has,
  });
};

export const setSearchGPSNavigation = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_GPS_NAVIGATION,
    payload: has,
  });
};

export const setSearchAwning = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_AWNING,
    payload: has,
  });
};

export const setSearchAirConditioning = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_AIR_CONDITIONING,
    payload: has,
  });
};

export const setSearchSolarPanels = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_SOLAR_PANELS,
    payload: has,
  });
};

export const setSearchTowingDevice = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_TOWING_DEVICE,
    payload: has,
  });
};

export const setSearchIsofix = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_ISOFIX,
    payload: has,
  });
};

export const setSearchHeating = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_HEATING,
    payload: has,
  });
};

export const setSearchBicycleCarier = (arr: Array<BicycleCarrierType>) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_BICYCLE_CARRIER,
    payload: arr,
  });
};

export const setSearchFestival = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_FESTIVAL,
    payload: has,
  });
};

export const setSearchManufacturer = (guid: string) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_MANUFACTURER,
    payload: guid,
  });
};

export const setSearchHeatedWasteTank = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_HEATED_WASTE_TANK,
    payload: has,
  });
};

export const setSearchWinterTires = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_WINTER_TIRES,
    payload: has,
  });
};

export const setSearchShowChains = (has: boolean) => (dispatch) => {
  dispatch({
    type: t.SET_SEARCH_SNOW_CHAINS,
    payload: has,
  });
};
