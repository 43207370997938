/* eslint-disable */
import * as t from '../types';

export interface UploadedImageInterface {
  id: any;
  description?: string;
  file: any;
  preview?: any;
}

export interface DamagePictureModel {
  id: any;
  preview: any;
  file: any;
}

export interface ValidationI {
  campervan: boolean;
  damages: boolean;
  handoff: boolean;
}

export interface CheckoutFeeI {
  id: any;
  type: string;
  amount: number;
  description?: string;
}

export interface UploadedVehicleDamage {
  id: any;
  name?: string;
  description?: string;
  location?: string;
  isTerminal?: boolean;
  repairCost?: any;
  pictures: Array<DamagePictureModel>;
}

export interface CheckOutReduxInterface {
  checkOutGuid?: string | null;
  checkOutError?: string | null;
  step: any | null;
  fetchedCheckOutData: any;
  fetchedReservation: any;
  checkInLoading: boolean;
  uploadedImage?: Array<UploadedImageInterface>;
  uploadedVehicleDamages: Array<UploadedVehicleDamage>;
  validation: ValidationI;
  fees: Array<CheckoutFeeI>;
}

const checkout = (
  // eslint-disable-next-line default-param-last
  state: CheckOutReduxInterface = {
    checkOutGuid: null,
    checkOutError: null,
    fetchedCheckOutData: null,
    fetchedReservation: null,
    checkInLoading: true,
    step: null,
    uploadedImage: [],
    uploadedVehicleDamages: [],
    fees: [],
    validation: {
      damages: false,
      campervan: false,
      handoff: false,
    },
  },
  action: any
) => {
  switch (action.type) {
    case t.SET_FETCHED_RESERVATION_DATA_CHECKOUT:
      return {
        ...state,
        fetchedReservation: action.payload,
      };

    case t.SET_FETCHED_CHECKOUT_DATA:
      return {
        ...state,
        fetchedCheckOutData: action.payload,
      };

    case t.SET_CHECKOUT_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case t.SET_CHECKOUT_ERROR:
      return {
        ...state,
        checkOutError: action.payload,
      };

    case t.SET_CHECKOUT_GUID:
      return {
        ...state,
        checkOutGuid: action.payload,
      };

    case t.SET_CHECKOUT_LOADING_DATA:
      return {
        ...state,
        checkInLoading: action.payload,
      };

    case t.SET_VALIDATION_CAMPERVAN_CHECKOUT:
      return {
        ...state,
        validation: { ...state.validation, campervan: action.payload },
      };

    case t.SET_VALIDATION_DAMAGES_CHECKOUT:
      return {
        ...state,
        validation: { ...state.validation, damages: action.payload },
      };

    case t.SET_VALIDATION_HANDOVER_CHECKOUT:
      return {
        ...state,
        validation: { ...state.validation, handoff: action.payload },
      };

    case t.UPDATE_FEE_CHECKOUT:
      const uploadedFeesWithoutChanged = state.fees.filter((item) => action.payload.id !== item.id);
      const resFees = [...uploadedFeesWithoutChanged, action.payload];
      return {
        ...state,
        fees: resFees,
      };

    case t.DELETE_FEE_CHECKOUT:
      const uploadedFeesWithoutDeleted = state.fees.filter((item) => action.payload.id !== item.id);
      return {
        ...state,
        fees: uploadedFeesWithoutDeleted,
      };

    case t.UPDATE_UPLOADED_IMAGES_CHECKOUT:
      const uploadedImagesWithoutChanged = state.uploadedImage!.filter((item) => action.payload.id !== item.id);
      const res = [...uploadedImagesWithoutChanged, action.payload];
      return {
        ...state,
        uploadedImage: res,
      };

    case t.DELETE_UPLOADED_IMAGES_CHECKOUT:
      const uploadedImagesWithoutDeleted = state.uploadedImage!.filter((item) => action.payload !== item.id);
      return {
        ...state,
        uploadedImage: uploadedImagesWithoutDeleted,
      };

    case t.UPDATE_UPLOADED_VEHICLE_DAMAGES_CHECKOUT:
      const uploadedDamagesWithoutChanged = state.uploadedVehicleDamages.filter(
        (item) => action.payload.id !== item.id
      );
      const uploadedVehicleDamagesRes = [...uploadedDamagesWithoutChanged, action.payload];
      return {
        ...state,
        uploadedVehicleDamages: uploadedVehicleDamagesRes,
      };

    case t.UPDATE_UPLOADED_VEHICLE_DAMAGE_PICTURES_CHECKOUT:
      const currentDamage = state.uploadedVehicleDamages.filter((item) => action.payload.damageID === item.id);
      return {
        ...state,
        uploadedVehicleDamages: { ...currentDamage, pictures: action.payload },
      };

    case t.DELETE_UPLOADED_VEHICLE_DAMAGES_CHECKOUT:
      const uploadedDamagesWithoutDeleted = state.uploadedVehicleDamages.filter(
        (item) => action.payload.id !== item.id
      );
      return {
        ...state,
        uploadedVehicleDamages: uploadedDamagesWithoutDeleted,
      };

    case t.DELETE_UPLOADED_VEHICLE_DAMAGE_PICTURE_CHECKOUT:
      const uploadedDamagesWithoutChangedDamage = state.uploadedVehicleDamages.filter(
        (item) => action.payload !== item.id
      );
      const changedDamage = state.uploadedVehicleDamages.find((item) => action.payload === item.id);
      const picturesWithoutDeleted = changedDamage!.pictures.filter((pic) => pic.id !== action.payload.pictureId);

      return {
        ...state,
        uploadedVehicleDamages: [
          ...uploadedDamagesWithoutChangedDamage,
          { ...changedDamage, pictures: picturesWithoutDeleted },
        ],
      };

    default:
      return { ...state };
  }
};

export default checkout;
