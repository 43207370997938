import { createWrapper } from 'next-redux-wrapper';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from './reducers/rootReducer';
import makeStore from 'features/redux/makeStore';

export { makeStore };

export const wrapper = createWrapper(makeStore, { debug: false });

export type TypedDispatch = ReturnType<typeof makeStore>['dispatch'];
export const useTypedDispatch: () => TypedDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
