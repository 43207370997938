import { useContext } from "react";
import { CodebaseContext, CodeBaseType } from "../providers/CodebaseContext";

export function useCodebase(): CodeBaseType;
export function useCodebase(codebaseType: CodeBaseType): boolean;

export function useCodebase(codebaseType?: CodeBaseType) {
  const context = useContext(CodebaseContext);

  if (codebaseType !== undefined) {
    return context === codebaseType;
  }

  return context;
}

export function useFreewayApp() {
  return useCodebase("fwc");
}
