var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"22820438a8e70fd8be341bb518e943b1132de48f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import packageInfo from './package.json';

const { version } = packageInfo;
const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig.SENTRY_DSN) {
  Sentry.init({
    // Uncomment when ready for production
    enabled: publicRuntimeConfig.APP_ENV === 'production' || publicRuntimeConfig.APP_ENV === 'staging',
    dsn: publicRuntimeConfig.SENTRY_DSN,
    environment: publicRuntimeConfig.APP_ENV,
    tracesSampleRate: 1.0,
    release: version,
    ignoreErrors: [
      'Network Error',
      "Can't find variable: DOT",
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });

  // Add more tags if necessary
  // Sentry.configureScope((scope) => {
  // });
}
