export const SET_LOADING = 'SET_LOADING';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_GOOGLE_REVIEWS = 'SET_GOOGLE_REVIEWS';
export const SET_SPECIAL_OFFER_BAR_VISIBLE = 'SET_SPECIAL_OFFER_BAR_VISIBLE';
export const SET_SPECIAL_OFFER_BAR_REF = 'SET_SPECIAL_OFFER_BAR_REF';

export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_COOKIE_BAR_VARIANT = 'SET_COOKIE_BAR_VARIANT';
export const SET_COOKIE_BAR_VISIBLE = 'SET_COOKIE_BAR_VISIBLE';
export const SET_MARKETING_COOKIES = 'SET_MARKETING_COOKIES';
export const SET_ANALYTIC_COOKIES = 'SET_ANALYTIC_COOKIES';
export const SET_CAR_DETAIL_DID_MOUNT = 'SET_CAR_DETAIL_DID_MOUNT';
