import { Currency } from './routing';

// eslint-disable-next-line import/prefer-default-export
export const getCurrencyCodeString = (settings: { currency?: string }) => {
  switch (settings?.currency) {
    case Currency.czk:
      return 'Kč';
    case Currency.pln:
      return 'Zł';
    case Currency.eur:
      return '€';
    default:
      return 'XXX';
  }
};
