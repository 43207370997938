/* eslint-disable no-restricted-syntax */
import { useEffect } from 'react';
import { BookingVehicleResult } from '~/libs/api/models/booking-vehicle-result';
import { ListingDetailQueryResult } from '~/libs/api/models/listing-detail-query-result';
import { ListingSearchQueryResult } from '~/libs/api/models/listing-search-query-result';
import { getDaysDifference, getPogramTypeData, getProducerData } from './dataLayer';
import { InsuranceType } from '~/libs/api/models/insurance-type';

declare let ga: any;
declare let gtag: any;
declare let fbq: any;
declare let dataLayer: any;

declare global {
  interface Window {
    _adftrack: any;
    diffAnalytics: any;
    dataLayer: any;
  }
}

const dv360Events = {
  allWeb: 'all_w0',
  thirtySeconds: '30s_t0',
  homepageSearch: 'hp_se0',
  formClick: 'formu0',
  formSent: 'formu00',
  bookingDataFilled: 'krok_0',
  carPaid: 'krok_00',
  newAccount: 'novy_0',
  registrationCompleted: 'dokon0',
  beforeLogin: 'prihl0',
  userLoggedIn: 'prihl00',
  openInstantBookableCar: 'book_0',
  openRequestCar: 'book_00',
};

const tryCall = (trackingFunction) => {
  // if (publicRuntimeConfig.NODE_ENV !== 'production') return;

  try {
    trackingFunction();
  } catch (err) {
    console.log(err);
  }
};

// --------------------- SEARCH EVENT ---------------------
const trackHomePageSearch = (searchData) => {
  // const trackerName = ga.getAll()[0].get('name');
  // ga(`${trackerName}.send`, 'event', {
  //   eventCategory: 'search-bar',
  //   eventAction: 'search-button',
  //   eventLabel: searchData,
  // });

  window.dataLayer.push({
    event: 'searchBar',
    searchBarLocation: 'homepage',
    eventCategory: 'search-button',
    eventAction: 'click',
    eventLabel: searchData,
  });
};

// --------------------- OPEN BOOKING FORM EVENT---------------------
const trackOpenBookingFrom = (instantBookable: boolean, carItem: ListingDetailQueryResult) => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'sign-up form',
    eventAction: 'form-opened',
    eventLabel: `${instantBookable ? 'ib' : 'request'} | ${carItem.title}`,
  });
};

const trackOpenBookingFormOpenInquiry = () => {
  const trackerName = ga.getAll()[0].get('name');

  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'booking',
    eventAction: 'inq-form-opened',
  });
};
// ---------------------  BOOKING FORM SENT EVENT---------------------
const trackSuccessfullySendBookingForm = (
  instantBookable: boolean,
  carItem: ListingDetailQueryResult,
  calculation,
  referenceCode: string,
  customerData = null
) => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'booking',
    eventAction: 'intent',
    eventLabel: `${instantBookable ? 'ib' : 'request'} | ${carItem.title}`,
    eventValue: calculation?.data?.total?.total,
  });

  // triggerSklikScript();

  trackEEGACheckoutStep(carItem, 1, instantBookable, calculation, referenceCode, customerData);
};

// ---------------------  LEAD FORM SENT EVENT---------------------

const trackSuccessfullySendLeadForm = (campervanType) => {
  tryCall(() => trackLeadFormDataLayer(campervanType));

  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'form-sent',
    eventAction: 'p2p-lead',
    eventLabel: 'p2p-form',
  });
};

const trackLeadFormDataLayer = (campervanType) => {
  if (window?.dataLayer) {
    window?.dataLayer.push({
      event: 'form',
      eventCategory: 'form-partners',
      eventAction: 'form-sent',
      eventLabel: campervanType,
    });
  }
};

// ---------------------  BOOKING PAID  EVENT---------------------

const trackSuccessfullyPaidInstantBookableCar = (carData, price, instantBookable) => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'booking',
    eventAction: 'paid',
    eventLabel: `${instantBookable ? 'ib' : 'request'} | ${carData.title}`,
    eventValue: price,
  });

  // trackEEGACheckoutStep(carData, 3, instantBookable, stripIntentObj);
  trackSambaOrder(carData.id, price);
};

// ---------------------  BOOKING FORM UPDATED (BOOKING PAGE)   EVENT---------------------

const trackSuccessfullyUpdatedBookingForm = (carDataObj: BookingVehicleResult, price, instantBookable: boolean) => {
  if (instantBookable) {
    trackSuccessfullyUpdatedBookingFormBeforePaymentInstantBookableCar(carDataObj.title, price);
    trackEEGACheckoutStep(carDataObj, 2, true);
  } else {
    trackSuccessfullyUpdatedBookingFormRequestableCar(carDataObj.title, price);
    trackEEGACheckoutStep(carDataObj, 2, false);
    trackSambaOrder(carDataObj.id, price);
  }
};

const trackSuccessfullyUpdatedBookingFormBeforePaymentInstantBookableCar = (carName, price) => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'booking',
    eventAction: 'payment',
    eventLabel: `ib | ${carName}`,
    eventValue: price,
  });
};

const trackSuccessfullyUpdatedBookingFormRequestableCar = (carName, price) => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'booking',
    eventAction: 'inquiry-sent',
    eventLabel: `request | ${carName}`,
    eventValue: price,
  });
};

// ---------------------  PRODUCT CLICK EVENT---------------------

const trackBookingWidgetCTAClick = (carItem: ListingDetailQueryResult) => {
  console.log('trackBookingWidgetCTAClick', carItem);

  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'booking',
    eventAction: 'car-detail-cta',
    eventLabel: `${carItem.instantBookable ? 'ib' : 'request'} | ${carItem.title}`,
  });
  trackFBAddToCart(carItem);

  if (typeof window?.diffAnalytics !== 'undefined') {
    window?.diffAnalytics.cartInteraction({
      cartContent: {
        cartItems: [{ productId: carItem.id, amount: 1 }],
      },
    });
  }
};

const trackEEGAProductClick = (carItem: ListingSearchQueryResult) => {
  console.log('trackEEGAProductClick', carItem);

  gtag('event', 'select_content', {
    content_type: 'product',
    items: [
      {
        id: carItem.id,
        name: carItem.title,
        list_name: 'Search Results',
        brand: carItem.manufacturer,
        category: carItem.vehicleType,
        variant: carItem.model,
      },
    ],
  });

  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'booking',
    eventAction: 'car-detail-click',
    eventLabel: `${carItem.instantBookable ? 'ib' : 'request'} | ${carItem.title}`,
  });
};

// ---------------------  CHECKOUT STEP  EVENT---------------------

export const trackEEGACheckoutStep = (
  carItem,
  stepNumber,
  instantBookable,
  calculation?: any,
  referenceCode?: any,
  customerData = null
) => {
  if (stepNumber === 1) {
    console.log('calling begin_checkout');

    window.dataLayer.push({
      event: 'begin_checkout', // or add_payment_info or add_insurance_info
      ecommerce: {
        id: referenceCode,
        payment: calculation?.data?.total?.total,
        coupon: calculation?.data?.rentalPromoCode,
        tax: calculation?.data?.total?.vat,
        days: calculation?.data?.days?.length || getDaysDifference(calculation?.data?.end, calculation?.data?.start),
        products: [
          {
            name: carItem.title,
            availability: instantBookable ? 'instant booking' : 'on demand',
            id: carItem.id,
            brand: carItem.manufacturer,
            categories: [carItem.type],
            variant: carItem.model,
            list_name: instantBookable ? 'INSTANT_BOOK' : 'REQUEST',
            producer: getProducerData(carItem?.insurance === InsuranceType.P2P, carItem.operator?.guid),
            size: carItem.passengerCapacity,
            price: calculation?.data?.total?.total,
            virtual_price: calculation?.data?.total?.total * 0.25,
            'base.price': carItem.fromPrice,
            quantity: 1,
            program: getPogramTypeData(carItem.programType, carItem.operator?.guid),
          },
        ],
        ...(customerData && {
          user: [
            {
              name: customerData.name ?? `${customerData.firstName} ${customerData.lastName}`,
              email: customerData.email,
              phone: customerData.phone,
            },
          ],
        }),
      },
      _clear: true,
    });
  }
  if (stepNumber === 2) {
    gtag('event', 'checkout_progress', {
      checkout_step: 2,
      items: [
        {
          name: carItem.title,
          id: carItem.id,
          brand: carItem.manufacturer,
          category: carItem.type,
          variant: carItem.model,
          list_name: instantBookable ? 'INSTANT_BOOK' : 'REQUEST',
        },
      ],
    });
  }
};

// ---------------------  AUTH ---------------------

const trackUserLogin = (fromMenu: boolean, loginType: string) => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'sign-up form',
    eventAction: 'logged-in',
    eventLabel: `${loginType} | ${fromMenu ? 'menu' : 'car-detail'}`,
  });
};

const trackUserRegister = (fromMenu: boolean, loginType = '') => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'sign-up form',
    eventAction: 'registration',
    eventLabel: `${loginType} | ${fromMenu ? 'menu' : 'car-detail'}`,
  });
};

// ---------------------  TEMPLATES ---------------------

const trackFBAddToCart = (carItem) => {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'AddToCart', {
      content_ids: [String(carItem.id)],
      content_type: 'product',
    });
  }
};

const triggerSklikScript = () => {
  new Promise((resolve, reject) => {
    const sklik = document.createElement('script');
    sklik.setAttribute('src', 'https://www.seznam.cz/rs/static/rc.js');
    sklik.setAttribute('async', 'true');
    document.head.appendChild(sklik);
  }).then(() => {});
};

const trackUserAfter30SecondsOnPageHook = () => {
  useEffect(() => {
    let sessionInterval = null;
    sessionInterval = setInterval(() => {
      handleTimeOnPage(sessionInterval);
    }, 1000);

    return () => {
      clearInterval(sessionInterval);
    };
  }, []);

  const handleTimeOnPage = (sessionInterval) => {
    try {
      if (!window.sessionStorage.useCameToPageTime) {
        sessionStorage.setItem('useCameToPageTime', String(new Date().getTime()));
      } else {
        const startDate = window.sessionStorage.useCameToPageTime;
        const endDate = new Date().getTime();
        const seconds = (endDate - startDate) / 1000;

        if (Number.isNaN(seconds)) {
          sessionStorage.setItem('useCameToPageTime', 'eventSent');
          clearInterval(sessionInterval);
        } else if (seconds >= 30) {
          sessionStorage.setItem('useCameToPageTime', 'eventSent');
          clearInterval(sessionInterval);
        }
      }
    } catch (e) {
      console.error('Session Storage is disabled');
    }
  };
};

const trackOpenBookingSent = () => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, 'event', {
    eventCategory: 'booking',
    eventAction: 'intent',
    eventLabel: 'request | open-inquiry',
  });
};

const trackSambaOrder = (carId, price) => {
  if (typeof window?.diffAnalytics !== 'undefined') {
    window?.diffAnalytics.order({
      cartContent: {
        cartItems: [{ productId: carId, amount: 1, price }],
      },
    });
  }
};
export {
  trackHomePageSearch,
  trackOpenBookingFrom,
  trackSuccessfullySendBookingForm,
  trackSuccessfullySendLeadForm,
  trackSuccessfullyPaidInstantBookableCar,
  trackSuccessfullyUpdatedBookingForm,
  trackEEGAProductClick,
  tryCall,
  trackUserAfter30SecondsOnPageHook,
  trackUserLogin,
  trackUserRegister,
  trackOpenBookingSent,
  trackOpenBookingFormOpenInquiry,
  trackBookingWidgetCTAClick,
  trackFBAddToCart,
};
