import { useEffect, useRef } from 'react';
import { NextRouter } from 'next/router';
import { i18n } from '@lingui/core';
import { Country, cultureTokenMap, cultureTokens, Language } from '~/src/utils/routing';
import { setCountry, setCurrency, setLanguage } from '~/src/redux/actions/settings';
import { getCurrencyFromCountry } from '~/src/utils/currency';

const isFWC = process.env.NEXT_PUBLIC_APP_TYPE === 'fwc';

const defaultLanguage = isFWC ? Language.de : Language.cs;
const defaultCountry = isFWC ? Country.de : Country.cz;

const getLanguage = (router: NextRouter, dispatch) => {
  const result = ((router?.query?.cultureToken as string) || router.asPath).match(
    /(?<lang>[a-z]{2})-(?<country>[a-z]{2})/
  );

  if (result?.groups) {
    const validCulture = Object.values(cultureTokens).some((e) => e === result[0]);

    if (validCulture) {
      dispatch(setLanguage(result.groups.lang as Language));
      return result.groups.lang as Language;
    }

    if (result.groups.lang && Language[result.groups.lang]) {
      const defaultToken = cultureTokenMap[result.groups.lang]?.split('-');
      if (defaultToken) {
        dispatch(setLanguage(Language[defaultToken[0]]));
        return Language[defaultToken[0]];
      }
    }
    dispatch(setLanguage(defaultLanguage));
    return defaultLanguage;
  }

  dispatch(setLanguage(defaultLanguage));
  return defaultLanguage;
};

const getCountry = (router: NextRouter, dispatch) => {
  const result = ((router?.query.cultureToken as string) || router.asPath).match(
    /(?<lang>[a-z]{2})-(?<country>[a-z]{2})/
  );

  if (result?.groups) {
    const validCulture = Object.values(cultureTokens).some((e) => e === result[0]);
    if (validCulture) {
      setLangAndCurrency(result.groups.country, dispatch);
      return result.groups.country as Country;
    }

    if (result.groups.lang && Country[result.groups.lang]) {
      const defaultToken = cultureTokenMap[result.groups.lang]?.split('-');
      setLangAndCurrency(Country[defaultToken[1]], dispatch);
      return Country[defaultToken[1]];
    }

    setLangAndCurrency(defaultCountry, dispatch);
    return defaultCountry;
  }

  setLangAndCurrency(defaultCountry, dispatch);
  return defaultCountry;
};

const setLangAndCurrency = (country, dispatch) => {
  dispatch(setCountry(country));
  dispatch(setCurrency(getCurrencyFromCountry(country)));
};

export const useActivateLocale = (router: NextRouter, dispatch: any) => {
  const firstRender = useRef(true);

  if (firstRender.current) {
    i18n.activate(getLanguage(router, dispatch), getCountry(router, dispatch));
    // render only once
    firstRender.current = false;
  }

  useEffect(() => {
    const matches = ((router?.query.cultureToken as string) || router.asPath).match(
      /(?<lang>[a-z]{2})-(?<country>[a-z]{2})/
    );
    const language = matches?.groups?.lang;
    if (i18n.locale !== language) {
      if (Language[language]) {
        i18n.activate(language);
      } else {
        i18n.activate(defaultLanguage);
      }
    }
  }, [router.query.cultureToken]);
};
