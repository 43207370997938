import { useEffect } from 'react';
import { NextRouter } from 'next/router';
import { Country, cultureToken, cultureTokenMap, cultureTokens, Language } from '~/src/utils/routing';
import { setCountry, setLanguage } from '~/src/redux/actions/settings';

export const useLanguageAndCountrySetupOnFirstLoad = (router: NextRouter, dispatch?: any) => {
  useEffect(() => {
    if (!dispatch) return;

    const result = (router?.asPath || '').match(/(?<lang>[a-z]{2})-(?<country>[a-z]{2})/);

    if (result?.groups) {
      const validCulture = Object.values(cultureTokens).some(e => e === result[0]);
      if (validCulture) {
        if (result.groups.country && Country[result.groups.country]) {
          dispatch(setCountry(result.groups.country as Country));
        }
        if (result.groups.lang && Language[result.groups.lang]) {
          dispatch(setLanguage(result.groups.lang as Language));
        }
      } else if (result.groups.lang && Language[result.groups.lang] && cultureTokenMap[result.groups.lang]) {
        const defaultToken = cultureTokenMap[result.groups.lang]?.split('-');
        dispatch(setCountry(Country[defaultToken[1]]));
        dispatch(setLanguage(Language[defaultToken[0]]));
      } else {
        const defaultToken = cultureToken?.split('-');
        dispatch(setCountry(Country[defaultToken[1]]));
        dispatch(setLanguage(Language[defaultToken[0]]));
      }
    }
  }, []);
};
